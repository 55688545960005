import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import { IoIosCard } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import InputDetailsPage from "./InputDetailsPage";
import UserDetails from "./UserDetails";
import PaymentDetails from "./PaymentDetails";
import Successful from "./Successful";
import { useDispatch, useSelector } from "react-redux";
import { setUploadPosterId } from "../../redux/action";
import {
  getBankDetailsByTourId,
  bookingUser,
  uploadImage,
} from "../../api-folder/apiMethods";
import { uploadFilesToS3 } from "../../api-folder/s3Service";

function BookNow({
  selectedUser,
  selectedTourData,
  showBookNow,
  setShowBookNow,
  tourCategory,
}) {
  console.log(selectedUser, "selectedUser");
  console.log(
    selectedTourData,
    "==================>selectedTourData<========================="
  );
  const userDetails = useSelector((state) => state.userDetailsData);
  const [formData, setFormData] = useState([]);
  const [activeButton, setActiveButton] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPayments, setSelectedPayments] = useState("");
  const [selectedPaymentsId, setSelectedPaymentsId] = useState("");
  const [showPayments, setShowPayments] = useState(false);
  const [showPackage, setShowPackage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  console.log(selectedPackage, "Only");
  const buttons = [
    { icon: <FaUser />, index: 0 },
    { icon: <MdCloudUpload />, index: 1 },
    { icon: <IoIosCard />, index: 2 },
    { icon: <BsCheckCircleFill />, index: 3 },
  ];

  const handleStepChange = (index) => {
    setActiveStep(index);
    setActiveButton(index);
  };
  const [isFormVisible, setIsFormVisible] = useState(true);

  const resetData = () => {
    setFormData({});
    setSelectedPackage([]);
    setActiveStep(0);
    console.log("Form data and step reset");
  };
  const handlePaymentsSelection = (paymentType, paymentId) => {
    setSelectedPayments(paymentType);
    setSelectedPaymentsId(paymentId);
    setShowPayments(false);
  };

  const handleClose = () => setShowBookNow(false);
  const tourId = selectedTourData?.tour_id;
  console.log(tourId);

  const [bankData, setBankData] = useState([]);
  const [id, setId] = useState(0);

  console.log(bankData, "bankData===>>");
  console.log(id, "id");
  const getBankdetails = () => {
    getBankDetailsByTourId(tourId)
      .then((response) => {
        if (response.status === true) {
          const bankDetails = response?.data;
          setBankData(bankDetails);
          setId(bankDetails);
          console.log(response, "response");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getBankdetails();
  }, [tourId]);

  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [filledMemberNames, setFilledMemberNames] = useState([]);

  const handleFormSubmit = () => {
    console.log("Handling form submit for currentFormIndex:", currentFormIndex);
    const currentMember = `Adult ${currentFormIndex + 1}`;
    const currentFormData = formData[currentMember];
    console.log("Form Data at Submission:", formData);

    if (!currentFormData) {
      console.log("Error: No form data found for", currentMember);
      setError(`Error: No form data found for ${currentMember}`);
      return;
    }

    if (!currentFormData.name) {
      setError(`Error: Please enter a name for ${currentMember}`);
      return;
    }
    if (!currentFormData.dob) {
      setError(`Error: Please enter a date of birth for ${currentMember}`);
      return;
    }
    if (!currentFormData.selectedGender) {
      setError(`Error: Please select a gender for ${currentMember}`);
      return;
    }
    if (!currentFormData.selectedIDProof) {
      setError(`Error: Please select an ID proof for ${currentMember}`);
      return;
    }

    setFilledMemberNames((prevNames) => ({
      ...prevNames,
      [currentMember]: currentFormData.name,
    }));

    if (currentFormIndex < totalMembers - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    } else {
      setActiveStep(1);
    }
  };

  const [showCountryName, setShowCountryName] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredGateways, setFilteredGateways] = useState([]);
  const handleCountry = () => {
    setShowCountryName(!showCountryName);
  };

  console.log(filteredGateways, "===>filteredGatewaysfilteredGateways");

  const handleCountrySelection = (country) => {
    setSelectedCountry(country);
    setShowCountryName(false);

    const filteredPaymentMethod = bankData?.filter(
      (item) => item.country_name === country
    );
    setFilteredGateways(filteredPaymentMethod);
  };

  const countryOptions = Array.from(
    new Set(bankData?.map((item) => item.country_name))
  );
  console.log(countryOptions, "countryOptions");

  const handleInputChange = (key, field) => (e) => {
    const value = e.target.value;
    console.log(`Updating ${key} ${field} to ${value}`);

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [key]: {
          ...prevData[key],
          [field]: value,
        },
      };

      const currentMemberData = updatedData[key];
      if (
        !currentMemberData.name ||
        !currentMemberData.dob ||
        !currentMemberData.selectedGender ||
        !currentMemberData.selectedIDProof
      ) {
        console.log(
          `Error: Please fill out all fields for:  ${key} ${field} to ${value}`,
          key
        );
        return updatedData;
      }
      return updatedData;
    });
  };
  const [showGender, setShowGender] = useState(false);

  const handleGenderSelection = (gender, member) => {
    setFormData((prevData) => ({
      ...prevData,
      [member]: {
        ...prevData[member],
        selectedGender: gender,
      },
    }));
    setShowGender(false);
  };
  const [showIDProof, setShowIdProof] = useState(false);

  const selectIDProof = (idProof, member) => {
    setFormData((prevData) => ({
      ...prevData,
      [member]: {
        ...prevData[member],
        selectedIDProof: idProof,
      },
    }));
    setShowIdProof(false);
  };
  const [selectedTourPackages, setSelectedTourPackages] = useState([]);
  const totalMembers = selectedTourPackages.reduce((total, pkg) => {
    const totalAdults = pkg.member_adult_count;
    const totalChildren = pkg.member_child_count;
    return total + totalAdults + totalChildren;
  }, 0);
  const [showOptions, setShowOptions] = useState(false);

  const [packageQuantities, setPackageQuantities] = useState({});

  const handlePackageSelect = (packageType) => {
    if (
      selectedTourPackages.some(
        (pkg) => pkg.package_name === packageType.package_name
      )
    ) {
      setSelectedTourPackages((prev) =>
        prev.filter((pkg) => pkg.package_name !== packageType.package_name)
      );
      setPackageQuantities((prev) => {
        const updatedQuantities = { ...prev };
        delete updatedQuantities[packageType.package_id];
        return updatedQuantities;
      });
    } else {
      setSelectedTourPackages((prev) => [...prev, packageType]);
      setPackageQuantities((prev) => ({
        ...prev,
        [packageType.package_id]: 1,
      }));
      setSelectedPackage(packageType);
    }
    setShowOptions(false);
  };
  const selectedPackagePrice = selectedPackage.price;
  console.log(`Price of selected package: ${selectedPackagePrice}`);

  const [imageId, setImageId] = useState(null);
  console.log(imageId, "=======================>imageId<================");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      handlePosterSubmit(url);
    } catch (error) {
      console.log(error);
      setError("Error uploading file");
    }
  };
  const handlePosterSubmit = (url) => {
    uploadImage({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const posterId = response.data.map((item) => item.id);
          setImageId(posterId);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  console.log(userDetails.id, "id of user");
  const imagePaymentId = useSelector((state) => state.imagePaymentId);

  const handleBookingUser = () => {
    const memberData = Object.keys(formData).map((key) => formData[key]);
    console.log("Member Data:", memberData);

    const packagesPayload = selectedTourPackages.map((pkg) => ({
      package_quantity: packageQuantities[pkg.package_id] || 1,
      tour_packages_id: pkg.package_id,
    }));

    const memberPayload = memberData.map((data) => ({
      name: data.name || "",
      DOB: data.dob || "",
      gender: data.selectedGender || "",
      ID_proof: data.selectedIDProof || "",
      upload_id: Number(imageId),
    }));

    const payload = {
      upload_id: Number(imagePaymentId),
      bookedFor_id: selectedUser,
      member: memberPayload,
      total_price: totalPrice,
      banking_gateway_id: selectedPaymentsId,
      package: packagesPayload,
    };

    console.log("Payload:", payload);

    bookingUser(payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response);
          setActiveStep(3);
        } else {
          console.log("Something Went Wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (currentFormIndex === totalMembers - 1) {
      handleBookingUser();
    }
  }, [formData]);

  useEffect(() => {
    const initializeFormData = () => {
      const newFormData = {};
      selectedTourPackages.forEach((pkg) => {
        const totalAdults = pkg.member_adult_count;
        const totalChildren = pkg.member_child_count;

        const adults = Array.from(
          { length: totalAdults },
          (_, index) => `Adult ${index + 1}`
        );
        const children = Array.from(
          { length: totalChildren },
          (_, index) => `Child ${index + 1}`
        );
        const allMembers = [...adults, ...children];

        allMembers.forEach((member) => {
          newFormData[member] = {
            name: "",
            dob: "",
            selectedGender: "",
            selectedIDProof: "",
            photo: null,
          };
        });
      });

      setFormData(newFormData);
    };

    initializeFormData();
  }, [selectedTourPackages]);

  const packageCount = selectedTourPackages.length;

  const initialPackageNames = selectedTourPackages.map(
    (pkg) => pkg.package_name
  );
  console.log(initialPackageNames, "initialPackageNames");

  const [selectedPackageNames, setSelectedPackageNames] = useState([]);
  console.log(selectedPackageNames, "selectedPackageNames");

  const addNewPackage = (newPackage) => {
    setSelectedPackageNames((prevState) => [...prevState, newPackage]);
  };
  useEffect(() => {
    const initialPackageNames = selectedTourPackages.map(
      (pkg) => pkg.package_name
    );
    console.log("Mapped Package Names:", initialPackageNames);

    setSelectedPackageNames((prevState) => {
      const newPackages = initialPackageNames.filter(
        (name) => !prevState.includes(name)
      );
      return [...prevState, ...newPackages];
    });
  }, [selectedTourPackages]);

  const filledMemberForms = Object.values(formData).filter(
    (member) => member.name && member.dob
  ).length;
  const calculateTotalPrice = () => {
    return selectedTourPackages.reduce((total, pkg) => {
      const quantity = packageQuantities[pkg.package_id] || 1;
      return total + pkg.price * quantity;
    }, 0);
  };
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [selectedTourPackages, packageQuantities]);
  return (
    <Modal
      show={showBookNow}
      onHide={handleClose}
      size="md"
      className="signup-popup pointer"
      centered
    >
      <Modal.Body>
        <div className="w-100 d-flex justify-content-end">
          <RxCross2 className="large-font" onClick={handleClose} />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center px-1 border-bottom-grey py-1">
          {buttons.map((button) => (
            <React.Fragment key={button.index}>
              <div
                className={`input-bg p-3 rounded-circle ${
                  button.index === activeStep ? "grey-green-border" : ""
                }`}
                onClick={() => handleStepChange(button.index)}
              >
                {React.cloneElement(button.icon, {
                  className: `font-24 ${
                    activeStep >= button.index ? "clr-green" : "book-now-icon"
                  }`,
                })}
              </div>
              {button.index < buttons.length - 1 && <div className="hr"></div>}
            </React.Fragment>
          ))}
        </div>
        {activeStep === 0 && (
          <InputDetailsPage
            showGender={showGender}
            setShowGender={setShowGender}
            isFormVisible={isFormVisible}
            selectedTourData={selectedTourData}
            tourCategory={tourCategory}
            selectedPackage={selectedPackage}
            errorMessage={errorMessage}
            showPackage={showPackage}
            setShowPackage={setShowPackage}
            setSelectedPackage={setSelectedPackage}
            setErrorMessage={setErrorMessage}
            handlePackageBtn={() => setShowPackage(!showPackage)}
            setActiveStep={setActiveStep}
            setActiveButton={setActiveButton}
            selectedTourPackages={selectedTourPackages}
            setSelectedTourPackages={setSelectedTourPackages}
            totalMembers={totalMembers}
            formData={formData}
            handleInputChange={handleInputChange}
            handleGenderSelection={handleGenderSelection}
            selectIDProof={selectIDProof}
            setShowOptions={setShowOptions}
            showOptions={showOptions}
            handlePackageSelect={handlePackageSelect}
            handleFileChange={handleFileChange}
            currentFormIndex={currentFormIndex}
            handleFormSubmit={handleFormSubmit}
            showIDProof={showIDProof}
            error={error}
            setError={setError}
            setShowIdProof={setShowIdProof}
          />
        )}
        {activeStep === 1 && (
          <UserDetails
            resetData={resetData}
            selectedTourData={selectedTourData}
            totalMembers={totalMembers}
            filledMemberForms={filledMemberForms}
            selectedPackageNames={selectedPackageNames}
            tourCategory={tourCategory}
            selectedPackage={selectedPackage}
            setActiveStep={setActiveStep}
            packageCount={packageCount}
            totalPrice={totalPrice}
            addNewPackage={addNewPackage}
          />
        )}
        {activeStep === 2 && (
          <PaymentDetails
            bankData={bankData}
            selectedTourData={selectedTourData}
            totalMembers={totalMembers}
            totalPrice={totalPrice}
            filledMemberNames={filledMemberNames}
            tourId={tourId}
            tourCategory={tourCategory}
            selectedPackage={selectedPackage}
            setActiveStep={setActiveStep}
            selectedPayments={selectedPayments}
            handlePaymentsBtn={() => setShowPayments(!showPayments)}
            showPayments={showPayments}
            handlePaymentsSelection={handlePaymentsSelection}
            countryOptions={countryOptions}
            handleCountry={handleCountry}
            showCountryName={showCountryName}
            selectedCountry={selectedCountry}
            handleCountrySelection={handleCountrySelection}
            filteredGateways={filteredGateways}
            handleBookingUser={handleBookingUser}
          />
        )}
        {activeStep === 3 && <Successful />}
      </Modal.Body>
    </Modal>
  );
}

export default BookNow;
