export const setUserDetailsData = (data) => ({
  type: "SET_USER_DETAILS_DATA",
  payload: data,
});

export const setUploadPosterId = (id) => ({
  type: "SET_UPLOAD_POSTER_ID",
  payload: id,
});

export const setTourCategoryData = (data) => ({
  type: "SET_TOUR_CATEGORY_DATA",
  payload: data,
});

export const setSelectedOption = (option) => ({
  type: "SET_SELECTED_OPTION",
  payload: option,
});

export const setSlectedPackageName = (data) => ({
  type: "SET_SELECTED_PACKAGE_NAME",
  payload: data,
});

export const setTotalGroupCost = (cost) => ({
  type: "SET_TOTAL_GROUP_COST",
  payload: cost,
});

export const setPackagesData = (data) => ({
  type: "SET_PACKAGES_DATA",
  payload: data,
});

export const setMembersData = (members) => ({
  type: "SET_MEMBERS_DATA",
  payload: members,
});

export const setTotalSelectedMembers = (count) => ({
  type: "SET_TOTAL_SELECTED_MEMBERS",
  payload: count,
});

export const setTotalGroupMembers = (count) => ({
  type: "SET_TOTAL_GROUP_MEMBERS",
  payload: count,
});

export const setTotalPackageCost = (cost) => ({
  type: "SET_TOTAL_PACKAGE_COST",
  payload: cost,
});

export const setTravelReportsData = (data) => ({
  type: "SET_TRAVEL_REPORTS_DATA",
  payload: data,
});

export const setDownLineData = (data) => ({
  type: "SET_DOWNLINE_DATA",
  payload: data,
});
export const setUserDetails = (data) => ({
  type: "SET_USER_DETAILS",
  payload: data,
});
export const setEditAnimation = (data) => ({
  type: "SET_EDIT_ANIMATION",
  payload: data,
});
export const setImagePaymentId = (id) => ({
  type: "SET_IMAGE_PAYMENT_ID",
  payload: id,
});
