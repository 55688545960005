import React from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";

const CancelTrip = ({ showCancel, setShowCancel, setShowExpenceModal,cancelId }) => {
  const handleCancelTrip = () => {
    setShowCancel(false);
  };
  const handleOpenShowExpenceModal = () => {
    setShowExpenceModal(true);
  };
  return (
    <Modal show={showCancel} centered>
      <Modal.Body className="px-3">
        <div className="d-flex justify-content-end pointer">
          <RxCross2 className="icon-font" onClick={() => handleCancelTrip()} />
        </div>
        <h5 className="fw-600 text-black d-flex justify-content-center">
          Are you sure to cancel this trip?
        </h5>
        <div className="medium-font text-hash fw-500 bg-grey p-3 rounded mt-3 text-justify">
          Note: After the deduction of taxes and expenses, the remaining balance
          will be refunded by the company as soon as possible.
        </div>
        <div className="flex-between w-100 mt-3 medium-font fw-600">
          <div
            className="btn-1 p-2 rounded-pill"
            onClick={handleOpenShowExpenceModal}
          >
            Cancel Trip
          </div>
          <div className="btn-2 p-2 rounded-pill" onClick={handleCancelTrip}>
            No
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelTrip;
