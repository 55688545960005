import React, { useEffect, useState } from "react";
import Withdraw from "../popups/Withdraw";
import AddAmountPopup from "./AddAmountPopup";
import CommonTable from "../../components/CommonTable";
import { HiOutlineEye } from "react-icons/hi2";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { PiHandWithdrawBold } from "react-icons/pi";
import { getWalletStatus, getWalletTable } from "../../api-folder/apiMethods";
import moment from "moment";

import {
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
  getUserDeposit,
  getUserDepositDetailsByID,
  getUserWithdrawDetailsByID,
  updateUserWithdrawDetailsByID,
} from "../../api-folder/apiMethods";
import ViewWithdrawlDetailsPopup from "./ViewWithdrawlDetailsPopup";
import DeletePopup from "../popups/DeletePopup";
import ViewDepositViewPopup from "./ViewDepositViewPopup";
import EditDepositDetails from "./EditDepositDetails";
import EditWithdrawDetailsPopup from "./EditWithdrawDetailsPopup";



const Wallet = () => {
  const [activeBtn, setActiveBtn] = useState(0);
  const [withdraw, setWithdraw] = useState(false);
  const handleActiveBtn = (index) => {
    setActiveBtn(index);
  };


  const [viewWithdrawMode, setViewWithdrawMode] = useState(true);
  const [viewDepositMode, setViewDepositMode] = useState(true);
  const [editDepositDetail, setEditDepositDetail] = useState(false);

  const [addAmountPopup, setShowAddAmountPopup] = useState(false);
  const handleAddAmountPopup = () => {
    setShowAddAmountPopup(true);
  };

  const handleWithdrawPopupOpen = (id) => {
    getUserWithdrawData(id);
    setWithdraw(true);
  };
  const buttons = ["Deposit", "Withdraw"];

  const WITHDRAW_COLUMNS = [
    {
      header: <div className="text-start">Date & Time</div>,
      field: "date_time",
    },
    { header: "Tnx", field: "type" },
    { header: "Tnx ID", field: "tnx_id" },
    { header: " Tnx Amount", field: "amount" },
    { header: "Status", field: "status" },
    { header: "View", field: "icon" },
  ];

  const TICKETS_COLUMNS = [
    { header: <div className="text-start">Date & Time</div>, field: "date" },
    { header: "Tnx", field: "type" },
    { header: "Tnx ID", field: "tnx_id" },
    { header: "Amount", field: "amount" },
    { header: "Status", field: "status" },
    { header: "View", field: "icon" },
  ];

  const [walletStatusDisplay, setWalletStatusDisplay] = useState([]);
  const [userWalletWithdrawTable, setUserWalletWithdrawTable] = useState([]);
  console.log(walletStatusDisplay, "45678dfgvbnvbnb n nb bnhjfgh");

  const walletStatus = () => {
    getWalletStatus()
      .then((response) => {
        if (response.status === true) {
          const data = response?.data || [];
          const formattedData = [
            {
              name: "Total Deposit",
              totalEarningsAgent: data.totalEarningsAgent,
            },
            {
              name: "Total Withdraw",
              totalEarningsAgent: data.totalWithdrawal,
            },
            {
              name: "Balance",
              totalEarningsAgent: data.balance,
              icon: "💰",
              withdraw: "Withdraw",
            },
          ];
          setWalletStatusDisplay(formattedData);

          console.log("Statusss", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log("error"));
  };
  useEffect(() => {
    walletStatus();
  }, []);

  //combine arrays
  const [walletData, setWalletData] = useState([]);
  const walletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status === true) {
          setUserWalletWithdrawTable(response.data.withdraw);
          const combinedData = [
          
            ...response.data.deposit.map((item) => ({
              date_time: (
                <div className="text-start">
                  {moment(item.time_date).format("Do MMMM, YYYY")}
                  <br />
                  {moment(item.time_date).format("hh:mm A")}
                </div>
              ),
              type: item.type,
              tnx_id: item.tnx_id,
              amount: (
              <div className={`${item?.type === "Deposit" ? "clr-green" : "clr-red"}`}>
              {item.deposit_amount}
            </div>),
              status: (
                <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                  <div
                    className={` py-1 px-2 br-10  ${
                      item.status === "Approved"
                        ? "green-bg clr-green"
                        : item.status === "Pending"
                        ? "clr-rejected pink-bg-reject"
                        : "bg-pending clr-pending" 
                    }`}
                  >
                    {item.status}
                  </div>
                </div>
              ),
              icon: (
              
                <div className="w-100 flex-center">
                  <div className="d-flex w-75 justify-content-around align-items-center">
                    <div className="icon-container cursor-pointer" title="View">
                      <HiOutlineEye
                        className="large-font"
                        onClick={() => {
                          handleDepositViewClick(item.deposit_id);
                        }}
                       
                      />
                    </div>
                    <div className="icon-container cursor-pointer" title="Edit">
                      <CiEdit
                        className="large-font"
                        onClick={() => {
                          handleEditDepositDetailPopup(item.deposit_id);
                        }}
                      />
                    </div>
                    <div
                      className="icon-container cursor-pointer"
                      title="Delete"
                    >
                      <RiDeleteBin6Line
                        className="large-font"
                        onClick={() =>
                          handleShowDeletePopup("deposit", item.deposit_id)
                        }
                      />
                    </div>
                  </div>
                </div>
              ),
            })),
          ];

          setWalletData(combinedData);
          console.log("tableee", response);
        } else {
          console.error("Failed to fetch wallet data.");
        }
      })
      .catch((error) =>
        console.error("Error fetching wallet data:", error.message)
      );
  };
  useEffect(() => {
    walletTable();
  }, []);

  const [ticketsWalletData, setTicketsWalletData] = useState([]);
  const ticketsWalletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status) {
          const ticketsData = [
            ...response.data.withdraw.map((item) => ({
              date: (
                <div className="text-start">
                  {moment(item.time_date).format("Do MMMM, YYYY")}
                  <br />
                  {moment(item.time_date).format("hh:mm A")}
                </div>
              ),
              type: item.type,
              tnx_id: item.tnx_id,
              amount: item.amount,
           
              status: item.status,
              icon:
              (
        <div className="w-100 flex-center">
          <div className="d-flex w-75 justify-content-around align-items-center">
            <div className="icon-container cursor-pointer" title="View">
              <HiOutlineEye
                className="large-font"
                onClick={() => {
                  handleViewClick(item.withdraw_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Edit">
              <CiEdit
                className="large-font"
              
                onClick={() => {
                  handleEditWithdrawPopupOpen(item.withdraw_id);
                }}
              />
            </div>
            <div className="icon-container cursor-pointer" title="Delete">
              <RiDeleteBin6Line
                className="large-font"
                
                onClick={() =>
                  handleShowDeletePopup("withdraw", item.withdraw_id)
                }
              />
            </div>
          </div>
        </div>
      ),
            })),
          ];
          setTicketsWalletData(ticketsData);
          console.log("ticketsWalletData", ticketsWalletData);
        } else {
          console.error("Failed to fetch wallet data.");
        }
      })
      .catch((error) =>
        console.error("Error fetching wallet data:", error.message)
      );
  };
  useEffect(() => {
    ticketsWalletTable();
  }, []);


  const [withdrawalDetails, setWithdrawalDetails] = useState(null);
  const [depositDetails, setDepositDetails] = useState(null);
  const [viewWithdrawDetails, setViewWIthdrawDetails] = useState(false);
  const [viewDepositDetails, setViewDepositDetails] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [selectWithdrawId, setSelectedWithdrawId] = useState();

  const [selectDepositId, setSelectedDepositId] = useState();

  const [editWithdrawDetailPopup, setEditWithdrawDetailPopup] = useState(false);

  const handleEditWithdrawPopupOpen = (id) => {
    getUserWithdrawData(id);
    setEditWithdrawDetailPopup(true);
    setSelectedWithdrawId(id);
  };
  console.log(selectDepositId, "selectDepositId");

  const handleEditDepositDetailPopup = (id) => {
    getUserDepositData(id);
    setEditDepositDetail(true);
    setSelectedDepositId(id);
  };

  const handleViewClick = (id) => {
    getUserWithdrawData(id);
    setSelectedWithdrawId(id);
    setViewWithdrawMode(true);
    setViewWIthdrawDetails(true);
  };

  const handleShowDeletePopup = (type, id) => {
    setShowDeletePopup(true);
    if (type === "withdraw") {
      setSelectedWithdrawId(id);
      setSelectedDepositId(null);
    } else if (type === "deposit") {
      setSelectedDepositId(id);
      setSelectedWithdrawId(null);
    }
  };

  const handleDepositViewClick = (id) => {

    getUserDepositData(id);
    setSelectedDepositId(id);
    setViewDepositDetails(true);
  };

  const getUserWithdrawData = (id) => {
    getUserWithdrawDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setWithdrawalDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserWithdrawData();
  }, []);

  const getUserDepositData = (id) => {
    getUserDepositDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setDepositDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserDepositData();
  }, []);
  
  const deleteUserWithdrawData = () => {
    deleteUserWithdrawDetailsByID(selectWithdrawId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserWithdrawData();
  }, []);
  const deleteUserdepositData = () => {
    deleteUserDepositDetailsByID(selectDepositId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserdepositData();
  }, []);

  const handleConfirmDelete = () => {
    if (selectWithdrawId !== null) {
      deleteUserWithdrawData(selectWithdrawId);
    } else if (selectDepositId !== null) {
      deleteUserdepositData(selectDepositId);
    }
    setShowDeletePopup(false);
  };

  const formatAmount = (amount) => {
    if (amount !== undefined && amount !== null) {
      return amount.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "0.00";
  };

  return (
    <div className="middle-bg p-3 br-20">
      <div className="flex-between">
        <h5 className="text-black fw-bold max-content flex-center">
          {activeBtn === 0 ? "Settlement" : "Wallet"}
        </h5>
        <div className="d-flex">
          {buttons.map((btn, index) => (
            <div
              className={`px-3 py-2 fw-600 medium-font me-2 rounded-pill
            ${
              activeBtn === index
                ? "bg-pink text-white "
                : "bg-white text-black pointer"
            }`}
              onClick={() => handleActiveBtn(index)}
            >
              {btn}
            </div>
          ))}
        </div>
      </div>
      <div className="row my-3">
        {walletStatusDisplay.length &&
          walletStatusDisplay.map((item, index) => (
            <div className="col-4 px-2">
              <div className="p-3 rounded-4 bg-white flex-between" key={index}>
                <div>
                  <div className="large-font fw-600 text-black">
                    {item.totalEarningsAgent || 0}
                  </div>
                  <div className="small-font fw-500 text-hash">{item.name}</div>
                </div>
                {/* {index === 2 && (
                  <div className="flex-column flex-center">
                    <div
                      className="page-btn rounded-circle pink-bg text-white large-font fw-600 pointer"
                      onClick={handleWithdrawPopupOpen}
                    >
                      {item.icon}
                    </div>
                    <div className="small-font fw-500 text-hash">
                      {item.withdraw}
                    </div>
                  </div>
                )} */}
                {index === 2 && (
                  <div className="flex-column flex-center">
                    {activeBtn === 0 ? (
                      <div
                        className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                        onClick={handleAddAmountPopup}
                      >
                        Add Amount
                      </div>
                    ) : (
                      <>
                        <div
                          className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                          onClick={handleWithdrawPopupOpen}
                        >
                          Withdraw
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      {activeBtn === 0 && (
        <CommonTable
          data={walletData}
          columns={WITHDRAW_COLUMNS}
          itemsPerPage={10}
        />
      )}
      {activeBtn === 1 && (
        <CommonTable
          data={ticketsWalletData}
          columns={TICKETS_COLUMNS}
          itemsPerPage={10}
        />
      )}
      <Withdraw
        withdraw={withdraw}
        setWithdraw={setWithdraw}
        balanceAmt={walletStatusDisplay}
      />
      <AddAmountPopup
        addAmountPopup={addAmountPopup}
        setShowAddAmountPopup={setShowAddAmountPopup}
        userWalletWithdrawTable={userWalletWithdrawTable}
      />
      <ViewDepositViewPopup 
      selectDepositId={selectDepositId}
      viewDepositDetails={viewDepositDetails}
      setViewDepositDetails={setViewDepositDetails}
      viewDepositMode={viewDepositMode}
      setViewDepositMode={setViewDepositMode}
      depositDetails={depositDetails}
      />
      <ViewWithdrawlDetailsPopup
        selectWithdrawId={selectWithdrawId}
        viewWithdrawDetails={viewWithdrawDetails}
        setViewWIthdrawDetails={setViewWIthdrawDetails}
        withdrawalDetails={withdrawalDetails}
        viewWithdrawMode={viewWithdrawMode}
        setViewWithdrawMode={setViewWithdrawMode}
      />
      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={handleConfirmDelete}
        description="Are You Want To Delete data from Here?"
      />
      <EditDepositDetails
        editDepositDetail={editDepositDetail}
        setEditDepositDetail={setEditDepositDetail}
        depositDetails={depositDetails}
        userWalletStatsData={walletStatusDisplay}
        selectDepositId={selectDepositId}
      />
      <EditWithdrawDetailsPopup
        editWithdrawDetailPopup={editWithdrawDetailPopup}
        setEditWithdrawDetailPopup={setEditWithdrawDetailPopup}
        withdrawalDetails={withdrawalDetails}
        userWalletStatsData={walletStatusDisplay}
        selectWithdrawId={selectWithdrawId}
      />
    </div>
  );
};
export default Wallet;