import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../images";
import { FaArrowLeft } from "react-icons/fa6";
import { FaHeart, FaCommentDots } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import { HiDotsVertical, HiReply } from "react-icons/hi";
import { FaEdit, FaFlag, FaTrashAlt } from "react-icons/fa";
import {
  AddCommentsById,
  DeletecommentById,
  EditCommentsById,
  getMemoryDetailsById,
  getCommentuser,
  getAllMemories,
  replyComment,
} from "../../api-folder/apiMethods";
import { IoIosArrowDown, IoMdSend } from "react-icons/io";
import DeletePopup from "./DeletePopup";
import { IoIosArrowUp } from "react-icons/io";
import { LuReply } from "react-icons/lu";
import moment from "moment/moment";
import { IoClose } from "react-icons/io5";

function LikesPage() {
  const { id } = useParams();
  console.log(id, "id-===");
  const navigate = useNavigate();

  const userId = localStorage.getItem("myid");
  console.log("login user id", typeof userId);
  const login_userId = Number(userId);
  console.log(typeof login_userId);

  // const handleDeletePopup = () => {
  //   setShowDeletePopup(true);
  // };

  const [openedCommentId, setOpenedCommentId] = useState(null);
  const [comment, setComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [memoryData, setMemoryData] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [commentid, setCommentid] = useState(null);

  const buttonMappings = [
    {
      id: "like",
      icon: <FaHeart className="x-large-font clr-canceled me-1" />,
      text: memoryData?.likes_count,
      className: "clr-canceled",
    },
    {
      id: "comment",
      icon: <FaCommentDots className="x-large-font clr-blue me-1" />,
      text: memoryData?.comments_count,
      className: "clr-blue",
    },
  ];
  const [activeButton, setActiveButton] = useState("like");

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const [optionVisible, setoptionVisible] = useState(false);
  // const handleOptions = () => {
  //   setoptionVisible(!optionVisible);
  // };

  const [optionVisibleReply, setoptionVisibleReply] = useState(false);
  const handleOptionsReply = () => {
    setoptionVisibleReply(!optionVisibleReply);
  };

  const loginUserId = Number(localStorage.getItem("user_id"));
  const handleDeletePopup = (commentId) => {
    setOpenedCommentId(null);
    setShowDeletePopup(true);
    setCommentid(commentId);
  };

  const fetchMemorydetails = () => {
    getMemoryDetailsById(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "memory details");
          setMemoryData(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchMemorydetails();
  }, [id]);

  const handleOptions = (commentId) => {
    setOpenedCommentId(openedCommentId === commentId ? null : commentId);
  };

  const [showReplies, setShowReplies] = useState();
  const openReplies = (commentId) => {
    setShowReplies(showReplies === commentId ? null : commentId);

    console.log("show replies", commentId);
  };

  const handleEdit = (commentId, commentText) => {
    setOpenedCommentId(null);
    setEditingCommentId(commentId);
    setComment(commentText);
    console.log("edit");
  };

  const [usercomment, setUserComment] = useState(null);
  console.log(usercomment, "usercomment");
  console.log(editingCommentId, "=====editedcommentid");
  const postComment = () => {
    if (editingCommentId) {
      EditCommentsById(editingCommentId, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "Edited response");
            setEditingCommentId(null);
            setComment("");
            fetchMemorydetails();
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      AddCommentsById(id, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "responseee comment");
            setUserComment(response?.data);
            setComment("");
            fetchMemorydetails();
          }
        })
        .catch((error) => console.log(error?.message));
    }
  };

  // get all more post
  const [allMemories, setAllMemories] = useState([]);
  console.log(allMemories, "get all memories");
  const fetchAlMemories = () => {
    getAllMemories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "resposne");
          setAllMemories(response?.data?.records);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAlMemories();
  }, []);

  // delete comment
  const handleDeleteComment = () => {
    console.log(commentid, "DELETE API");
    if (commentid) {
      DeletecommentById(commentid)
        .then((response) => {
          if (response.status === true) {
            console.log(response, "del comment");
            fetchMemorydetails();
          } else {
            console.log("error");
          }
        })

        .catch((error) => console.log(error?.message));
    }
    setShowDeletePopup(false);
  };

  const submitReply = (commentId) => {
    const replyData = {
      parent_comment_id: commentId,
      comment: replyCommentText,
    };

    replyComment(id, replyData)
      .then((response) => {
        if (response.status === true) {
          console.log(response?.data, "rpely");
          setReplyCommentText("");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  const [replyCommentText, setReplyCommentText] = useState("");

  const [visibleReply, setVisibleReply] = useState(false);
  const handleReply = (commentId) => {
    setVisibleReply(visibleReply === commentId ? null : commentId);
    console.log(commentId, "commentiddd");
  };

  const morePosts = allMemories.filter(
    (item) => item.memory_id !== Number(id) && item?.type !== "video"
  );
  console.log(morePosts, "morePosts");

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".pos-delete-option")
      ) {
        setOpenedCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div className="p-3">
        <div className="large-font password-clr d-flex flex-row align-items-center fw-600">
          <FaArrowLeft
            className="large-font password-clr me-2"
            onClick={() => navigate("/memories")}
          />
          Tour Photos
        </div>
        <div className="w-100 p-2">
          {console.log(Images, "images")}
          <img
            src={Images.upload_img_02 || memoryData?.memory_image}
            alt="memory id image"
            style={{ width: "100%", maxHeight: "30vh" }}
          />
        </div>
        <div className="p-2">
          <div className="row ">
            <div className="col-8 white-bg br-10  py-2 box-shadow">
              <div className="row medium-font border-bottom-like">
                {buttonMappings.map((button) => (
                  <div
                    key={button.id}
                    className={`col-6 fw-600 d-flex justify-content-center align-items-center py-2 cursor-pointer large-font fw-bold ${
                      activeButton === button.id ? "border-bottom-pink" : ""
                    }`}
                    onClick={() => setActiveButton(button.id)}
                  >
                    {button.icon}
                    {button.text}
                  </div>
                ))}
              </div>
              {activeButton === "comment" && (
                <>
                  <div className="scrollable-comments">
                    {memoryData?.comments?.map((comment, index) => (
                      <div className="d-flex flex-column" key={index}>
                        <div className="d-flex flex-row  align-items-start my-2 mx-3">
                          <div className="w-10 d-flex justify-content-center">
                            <img
                              className="h-9vh"
                              src={
                                Images.profile ||
                                comment?.comment_user_profile_image
                              }
                              alt=""
                            />
                          </div>
                          <div className="d-flex flex-column w-90 ms-3">
                            <div className="d-flex flex-column small-font input-bg br-10 p-3 position-relative">
                              <div
                                className="d-flex flex-between"
                                ref={dropdownRef}
                              >
                                <div>{comment?.comment_user_name}</div>
                                <div>
                                  {comment?.comment_user_id ===
                                    login_userId && (
                                    <HiDotsVertical
                                      className="cursor clr-black pos-relative cursor-pointer"
                                      onClick={() =>
                                        handleOptions(comment?.comment_id)
                                      }
                                    />
                                  )}
                                </div>
                              </div>

                              {openedCommentId === comment?.comment_id && (
                                <div className="d-flex flex-column pos-delete-option p-2 br-10 pink-bg ">
                                  <div className="clr-black py-1 border-bottom-grey small-font ">
                                    <FaTrashAlt className="me-1" />

                                    <span
                                      className=" cursor-pointer"
                                      onClick={() =>
                                        handleDeletePopup(comment?.comment_id)
                                      }
                                    >
                                      Delete
                                    </span>
                                  </div>
                                  <div
                                    className="clr-black py-1  small-font cursor-pointer "
                                    onClick={() =>
                                      handleEdit(
                                        comment?.comment_id,
                                        comment?.comment
                                      )
                                    }
                                  >
                                    <FaEdit className="me-1" />
                                    Edit
                                  </div>
                                </div>
                              )}

                              <div className="grey-text mt-1">
                                {comment?.comment}
                                {comment?.edit === 1 && (
                                  <span className="small-font clr-grey mx-1">
                                    (edited)
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="small-font px-2 flex-between grey-text mt-2">
                                <div>{formatDateTime(comment?.created_at)}</div>

                                <div className=" small-font pointer">
                                  {comment?.comment_user_id !==
                                    login_userId && (
                                    <div className="d-felx flex-end">
                                      <span
                                        onClick={() =>
                                          handleReply(comment?.comment_id)
                                        }
                                      >
                                        <LuReply className="clr-blue me-1  " />
                                        Reply
                                      </span>
                                    </div>
                                  )}

                                  <div>
                                    {visibleReply === comment?.comment_id && (
                                      <>
                                        <div className="d-flex w-100 px-3 py-3  flex-row align-items-center mt-2">
                                          <div className="bottom-pink">
                                            <input
                                              type="text"
                                              className="input-none "
                                              placeholder="Write a reply..."
                                              value={replyCommentText}
                                              onChange={(e) =>
                                                setReplyCommentText(
                                                  e.target.value
                                                )
                                              }
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  submitReply(
                                                    comment?.comment_id
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex flex-end mt-2 ">
                                          <div
                                            className="me-2 px-3 py-1 small-font cursor-pointer input-bg br-20"
                                            onClick={() =>
                                              submitReply(comment?.comment_id)
                                            }
                                          >
                                            Reply
                                          </div>
                                          <div
                                            className="small-font mt-1 cursor-pointer"
                                            onClick={() =>
                                              setVisibleReply(false)
                                            }
                                          >
                                            Cancel
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2 mb-2 d-flex flex-start flex-column">
                                <span
                                  className={`clr-blue cursor-pointer small-font white-space ${
                                    comment?.replies?.length > 0
                                      ? "input-reply-bg "
                                      : ""
                                  } px-1 py-1 br-10 w-2`}
                                  onClick={() =>
                                    openReplies(comment?.comment_id)
                                  }
                                >
                                  {comment?.replies?.length > 0 && (
                                    <span>
                                      {showReplies ? (
                                        <span>
                                          <IoIosArrowUp className="small-font mx-1" />
                                          {comment.replies.length === 1
                                            ? `${comment.replies.length} reply`
                                            : `${comment.replies.length} replies`}
                                        </span>
                                      ) : (
                                        <span>
                                          <IoIosArrowDown className="small-font mx-1" />
                                          {comment.replies.length === 1
                                            ? `${comment.replies.length} reply`
                                            : `${comment.replies.length} replies`}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </span>

                                {showReplies === comment?.comment_id &&
                                  comment?.replies?.map((reply, index) => (
                                    <div className="d-flex input-bg p-1 mt-2 br-5">
                                      <img
                                        className="h-5h"
                                        src={
                                          Images.profile ||
                                          reply?.comment_user_profile_image
                                        }
                                        alt="profile"
                                      />
                                      <div className="d-flex flex-column">
                                        <div className="d-flex flex-row align-items-end">
                                          <div className="mx-1 small-font">
                                            {reply?.comment_user_name}
                                          </div>
                                          <div className="mx-1 small-font mt-1">
                                            {moment(
                                              reply?.created_at
                                            ).fromNow()}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="ms-2 small-font">
                                            {reply?.comment}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="input-bg br-5 footer">
                    <div className="w-100 cross-bg d-flex flex-row align-items-center py-2 px-2 border-top-grey br-20 ">
                      <input
                        type="text"
                        className="py-2 cross-bg border-none px-2 br-10 w-90"
                        placeholder="Comment Here ........."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            postComment();
                          }
                        }}
                      ></input>
                      <div>
                        <IoClose
                          className="font-35 p-1 white-bg mx-2 br-10 boxShadow pointer"
                          onClick={() => {
                            setComment("");
                            setReplyCommentText("");
                          }}
                        />
                      </div>
                      <div className="d-flex flex-row justify-content-center w-10 pointer">
                        <IoMdSend
                          className="font-35 p-1 white-bg br-10 boxShadow"
                          onClick={postComment}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeButton === "like" && (
                <div className="d-flex flex-column like-section">
                  {memoryData?.likess?.map((like, index) => (
                    <div className="d-flex flex-row  align-items-center">
                      <div className="w-15 d-flex justify-content-center pos-relative">
                        <img
                          src={Images.profile || like?.like_user_profile_image}
                          alt="profile"
                        />
                        <FaHeart className="x-large-font clr-red me-1 pos-like-icons" />
                      </div>
                      <div className="d-flex flex-column small-font ms-2">
                        <div className="fw-bold small-font">
                          {like?.like_user_name}
                        </div>
                        <div className="grey-text mt-1 small-font">
                          {formatDateTime(like?.like_createdAt)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="col-4">
              <div className="w-100">
                <div className="large-font password-clr medium-font fw-bold">
                  More Posts
                </div>
                <div
                  className="white-bg p-2 br-10  w-100 box-shadow pointer"
                  onClick={() => navigate("/memories")}
                >
                  {morePosts.map((item) => (
                    <div
                      className="w-100 pos-relative my-1"
                      title="Do you want to see this memory..."
                    >
                      <img
                        src={Images.upload_img_01 || item?.path}
                        className="w-100"
                        title={
                          "Do you wnat to see this memory, please click here.."
                        }
                        alt=""
                      />

                      <div className="d-flex medium-font px-2 clr-white likes-comment-position">
                        <div className="d-flex flex-row  cursor-pointer">
                          <FaHeart className="large-font clr-red me-1" />
                          <span className="clr-white medium-font">
                            {item?.total_likes_count}
                          </span>
                        </div>
                        <div className="d-flex flex-row  cursor-pointer">
                          <FaCommentDots className="large-font clr-blue me-1" />
                          <span className="clr-white medium-font">
                            {item?.comments_count}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={handleDeleteComment}
        description="Are you sure to delete this comment?"
        commentid={commentid}
      />
    </>
  );
}

export default LikesPage;
