import React, { useEffect, useState } from "react";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Images } from "../images";
import { FaPlus, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { MdOutlineEdit, MdOutlineBlock } from "react-icons/md";
import Customers from "../pages/popups/Customers";
import { api } from "../api";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { VscSearchStop } from "react-icons/vsc";
import { RxCross2 } from "react-icons/rx";
import Successpopup from "../pages/popups/Successpopup";

const Administrators = () => {
  const [loginUser, setLoginUser] = useState(JSON.parse(localStorage.getItem("parent_details")));
  const [parentDetails, setparentDetails] = useState("");
  const [message, setmessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [isBlocked, setIsBlocked] = useState("");
  const [parentFieldArray, setParentFieldArray] = useState([]);
  const [userName, setUserName] = useState(loginUser?.user_name);
  const [roleCode, setRoleCode] = useState(loginUser?.role_code);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [roleHistory, setRoleHistory] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [tabFilterType, setTabFilterType] = useState("");
  const [customerType, setCustomerType] = useState([]);
  const [hideBackButton, setHideBackButton] = useState(true);
  const [msg, setMsg] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("jwt_token");
      const response = await api.getCreatedUser(token);

      if (response.status === true) {
        setCustomerType(response.data);
        function countTypes(type) {
          console.log("customerType...type", type);
          return response.data.filter((customer) => customer.role_code === type).length;
        }

        const directorCount = countTypes("director");
        const managerCount = countTypes("manager");
        const agentCount = countTypes("agent");
        const userCount = countTypes("user");
        let allCount;
        if (loginUser?.role_id._id === 3) {
          allCount = managerCount + agentCount + userCount;
        } else if (loginUser?.role_id._id === 2) {
          allCount = agentCount + userCount;
        } else if (loginUser?.role_id._id === 4 || loginUser?.role_id._id === 5) {
          allCount = userCount;
        }

        if (loginUser.role_code === "director") {
          setButtons([`All (${allCount})`, `Manager (${managerCount})`, `Agent (${agentCount})`, `Customer (${userCount})`]);
          setTabFilterType("director");
        } else if (loginUser.role_code === "manager") {
          setButtons([`All (${allCount})`, `Agent (${agentCount})`, `Customer (${userCount})`]);
          setTabFilterType("manager");
        }
        if (loginUser.role_code === "agent") {
          setButtons([`All (${allCount})`, `Customer (${userCount})`]);
          setTabFilterType("agent");
        }
      }
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };

  const role = localStorage.getItem("role");
  const [activeButton, setActiveButton] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [addCustomerPopup, setAddCustomerPopup] = useState(false);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [getCreatedUserData, setGetCreatedUserData] = useState([]);
  const [blockUserId, setblockUserId] = useState(false);
  const [clintDetails, setClientDetails] = useState();
  const [updateShow, setUpdateShow] = useState({});
  const [successpopup, setsuccesspopup] = useState(false);

  const handleOpenCustomerPopup = () => {
    setSelectedUser(null);
    setAddCustomerPopup(true);
    setEditMode(false);
  };
  const handleButton = (index) => {
    setActiveButton(index);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (userId, is_blocked) => {
    setShow(true);
    setAddUpdatePopup(false);
    setIsBlocked(is_blocked);
    setblockUserId(userId);
  };

  const handleUpdatepopup = (item) => {
    setAddCustomerPopup(true);
    setEditMode(true);
    setSelectedUser(item);
    // setCustomerType(item);
    return;
  };

  const filteredCustomers = customerType.filter((item) => {
    if (tabFilterType === "director") {
      if (activeButton === 0) {
        return true; // All
      } else if (activeButton === 1) {
        return item.role_code === "manager";
      } else if (activeButton === 2) {
        return item.role_code === "agent";
      } else if (activeButton === 3) {
        return !["manager", "director", "agent"].includes(item.role_code);
      }
    }

    if (tabFilterType === "manager") {
      if (activeButton === 0) {
        return true; // All
      } else if (activeButton === 1) {
        return item.role_code === "agent";
      } else if (activeButton === 2) {
        return !["manager", "director", "agent"].includes(item.role_code);
      }
    }

    if (tabFilterType === "agent") {
      if (activeButton === 0) {
        return true; // All
      } else if (activeButton === 1) {
        return !["manager", "director", "agent"].includes(item.role_code);
      }
    }

    if (activeButton === 0) {
      return true; // All
    } else if (activeButton === 1) {
      return item.role_code === "director";
    } else if (activeButton === 2) {
      return item.role_code === "manager";
    } else if (activeButton === 3) {
      return item.role_code === "agent";
    } else if (activeButton === 4) {
      return !["manager", "director", "agent"].includes(item.role_code);
    }
  });

  const filteredSearch = filteredCustomers.filter((item) => item.user_name && item.user_name.toLowerCase().includes(searchQuery.toLowerCase()));

  console.log(filteredSearch, "what to see ", filteredCustomers);

  const handleBlockUser = async () => {
    try {
      const response = await api.blockUser(blockUserId);
      debugger
      if (response.status === true) {
        setShow(false);
        setShowSuccessPopup(true);
        setSuccessMsg("User Blocked Successfully");

        setTimeout(() => {
          fetchUsers();
          setShowSuccessPopup(false);
        }, 4000);
      }
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredSearch.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [userDetails, setUserDetails] = useState({
    user_name: "",
    password: "",
    location: "",
    city: "",
    state: "",
    country_code: "",
    phone: "",
    casino_comm: 0,
    other_tours_comm: 0,
  });
  const handleCancel = () => {
    setAddUpdatePopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEmply = () => {
    console.log("u cannot unblock the user");
  };

  console.log(isBlocked, "==>isBlocked");

  //// Calling a function to get the selected user details by api
  const handleUserClick = async (item) => {
    console.log("handleUserClick data:", item);

    const token = localStorage.getItem("jwt_token");
    try {
      const response = await api.getCreatedUserDownline(token, item.id);
      // setSelectedRole({ name, role });
      if (response.status === true) {
        console.log("get Downline:", response.data);
        const data = response.data;
        setHideBackButton(false);

        // Extract and update state
        setParentFieldArray(data.parents || []);
        setUserName(data.user_name || "");
        setRoleCode(data.role?.code || "");
        //// Call another function to get users which is created by its creater
        getCreatedUserById(item.id, item.role_id);
      } else {
        console.error("Failed to get downline:", response.message);
      }
    } catch (error) {
      console.error("Error getting downline", error);
    }
  };

  const getCreatedUserById = async (id, role_id) => {
    try {
      const token = localStorage.getItem("jwt_token");
      const response = await api.getCreatedUserById(token, id);
      if (response.status === true) {
        console.log("getCreatedUserById:", response.data);
        console.log("role_id:", role_id);

        setRoleHistory((prevRoleHistory) => [...prevRoleHistory, { id, role_id }]);
        setSelectedRoleId(role_id);

        function countTypes(type) {
          console.log("customerType...type", type);
          return response.data.filter((customer) => customer.role_code === type).length;
        }

        const directorCount = countTypes("director");
        const managerCount = countTypes("manager");
        const agentCount = countTypes("agent");
        const userCount = countTypes("user");
        let allCount;
        if (role_id === 3) {
          allCount = managerCount + agentCount + userCount;
        } else if (role_id === 2) {
          allCount = agentCount + userCount;
        } else if (role_id === 4 || role_id === 5) {
          allCount = userCount;
        }

        let newButtons = ["All"];
        let newActiveButton = 0; // Default to "All"

        switch (role_id) {
          // case 3:
          //   newButtons = [`All (${allCount})`, `Manager (${managerCount})`, `Agent (${agentCount})`, `Customer (${userCount})`];
          //   setTabFilterType("director");
          //   break;
          case 2:
            newButtons = [`All (${allCount})`, `Agent (${agentCount})`, `Customer (${userCount})`];
            setTabFilterType("manager");
            break;
          case 4:
            newButtons = [`All (${allCount})`, `Customer (${userCount})`];
            setTabFilterType("agent");
            break;
          case 5:
            newButtons = [`All (${allCount})`];
            setTabFilterType("agent");
            break;
          // case 4:
          //   newButtons = [`All (${allCount})`, `Customer (${userCount})`];
          //   setTabFilterType("agent");
          //   break;
          default:
            newButtons = [`All (${allCount})`, `Manager (${managerCount})`, `Agent (${agentCount})`, `Customer (${userCount})`];
        }

        setButtons(newButtons);
        setActiveButton(newActiveButton); // Reset active button
        setCustomerType(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };

  const handleBackClick = async () => {
    if (roleHistory.length > 0) {
      // Copy the roleHistory array
      const updatedRoleHistory = [...roleHistory];
      console.log("updatedRoleHistory", updatedRoleHistory);

      // Remove the last item using pop
      updatedRoleHistory.pop();
      console.log("Updated roleHistory after pop:", updatedRoleHistory);

      // Get the new last item from the updated array
      const lastItem = updatedRoleHistory[updatedRoleHistory.length - 1];
      console.log("New lastItem after pop:", lastItem);

      if (lastItem) {
        // Process the removed item
        const { id, role_id } = lastItem;
        await getCreatedUserById(id, role_id);

        //// Calling downline api again to get latest data
        const token = localStorage.getItem("jwt_token");
        const response = await api.getCreatedUserDownline(token, id);
        if (response.status === true) {
          console.log("get Downline:", response.data);
          const data = response.data;

          // Extract and update state
          setParentFieldArray(data.parents || []);
          setUserName(data.user_name || "");
          setRoleCode(data.role?.code || "");
        } else {
          console.error("Failed to get downline:", response.message);
        }

        // Update the state with the new array excluding the last item
        setRoleHistory(updatedRoleHistory);
      } else {
        console.log("else of last item");
        setHideBackButton(true);

        // fetchUsers();
        // await getCreatedUserById(1, 1);
        const token = localStorage.getItem("jwt_token");
        debugger
        const response = await api.getCreatedUserDownline(token, loginUser.id);
        // setSelectedRole({ name, role });
        if (response.status === true) {
          console.log("get Downline:", response.data);
          const data = response.data;
          setParentFieldArray([]);
          setUserName(loginUser.user_name);
          setRoleCode(loginUser.role_code);
          getCreatedUserById(loginUser.id, loginUser.role_id._id);
          fetchUsers();
        }
      }
    } else {
      console.log("No items in roleHistory to process.");
      fetchUsers();
      console.log("userName", userName);
      console.log("roleCode", roleCode);

      setUserName("");
      setRoleCode("");
    }
  };

  return (
    <div className="middle-bg p-3 br-20">
      <div className="flex-between text-hash">
        <div className="px-3 py-2 pointer fw-500 text-black bg-white flex-center rounded-pill" onClick={handleBackClick} style={{ visibility: hideBackButton ? "hidden" : "visible" }}>
          <FaChevronLeft className="me-1" />
          Back
        </div>
        <div className="px-3 py-2 fw-500 text-black bg-white flex-center rounded-pill">Total Earn - 500000</div>
      </div>
      <div className="border-bottom flex-between">
        <div></div>

        <div className="flex-column flex-center">
          <img src={Images.ad1} alt="" />
          <div className="fw-600 text-black">
            {loginUser?.user_name} - {loginUser?.role_id?.code}
          </div>
          <div className="text-hash fw-400 py-1">
            <CiLocationOn /> {loginUser?.location}, {loginUser?.city}, {loginUser?.state}, {loginUser?.country}
          </div>
          <div className="d-flex mt-1 align-items-center">
            {/* Loop through the parent objects */}
            {parentFieldArray.length > 0 &&
              parentFieldArray.map((parent, index) => (
                <React.Fragment key={index}>
                  <span className="fw-500 clr-black">{parent.name}</span>
                  <HiOutlineArrowNarrowRight className="ms-1" />
                  <div className="small-font mx-1">({parent.code})</div>
                  {index < parentFieldArray.length - 1 && <HiOutlineArrowNarrowRight className="ms-1" />}
                </React.Fragment>
              ))}
            <span className="text-orange fw-500 ps-1">{userName}</span>
            <HiOutlineArrowNarrowRight className="ms-1" />
            <div className="small-font clr-pink mx-1">({roleCode})</div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div onClick={handleOpenCustomerPopup} className="px-3 py-2 fw-500 pointer text-black bg-pink text-white rounded-pill">
            <FaPlus className="me-1" />
            Add
          </div>
        </div>
      </div>
      <div className="flex-between text-hash mt-2">
        <div className="d-flex">
          {buttons.map((btn, index) => (
            <div key={index} className={`cursor-pointer fw-500 clr-black flex-center rounded-pill py-2 px-3 me-3 ${activeButton === index ? "bg-pink text-white" : "bg-white"}`} onClick={() => handleButton(index)}>
              {btn}
            </div>
          ))}
        </div>
        <div className="ps-2 d-flex align-items-center text-bg-white rounded-pill">
          <CiSearch />
          <input placeholder="Search by name" className="text-hash fw-500 medium-font mx-2" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
      </div>
      <div className="mt-1">
        {currentPosts.map((item, index) => (
          <div className="d-flex my-2 bg-white rounded-4 p-3" key={index}>
            <img src={item.cusImg} alt="" className="" />
            <div className="flex-column w-100 justify-content-start">
              <div className="text-hash py-2 flex-between">
                <div className="d-flex text-black fw-600 medium-font">
                  <span className="ms-2" onClick={() => handleUserClick(item)}>
                    {item.user_name}{" "}
                  </span>
                </div>
                <span className="medium-font text-hash fw-500">
                  <CiLocationOn /> {item.location}, {item.city}, {item.state}, {item.country}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center text-black fw-500 medium-font">
                <div className="align-items-center d-flex">
                  <div className={`pointer mx-2 ${item.role_code === "director" ? "box-1" : "box-2"}`}></div>
                  <label>Director</label>
                </div>
                <div className="d-flex align-items-center">
                  <div className={`pointer mx-2 ${item.role_code === "manager" ? "box-1" : "box-2"}`}></div>
                  <label>Manager</label>
                </div>
                <div className="d-flex align-items-center">
                  <div className={`pointer mx-2 ${item.role_code === "agent" ? "box-1" : "box-2"}`}></div>
                  <label>Agent</label>
                </div>
                <div className="d-flex align-items-center">
                  <div className={`pointer mx-2 ${item.role_code === "user" ? "box-1" : "box-2"}`}></div>
                  <label>Customer</label>
                </div>
                <div className="d-flex">
                  <span className="rounded-circle p-2 text-hash-two bg-hash pointer me-3" onClick={() => handleShow(item.id, item?.is_blocked)}>
                    {item.is_blocked === 1 ? <VscSearchStop className="large-font clr-red" /> : <MdOutlineBlock className="large-font " onClick={() => handleEmply} />}
                  </span>
                  <span className="rounded-circle p-2 text-hash-two bg-hash pointer" onClick={() => handleUpdatepopup(item)}>
                    <MdOutlineEdit className="large-font" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <div className="flex-center">
            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600">
              <FaChevronLeft />
            </button>
            {Array.from({ length: Math.ceil(filteredSearch.length / postsPerPage) }, (_, i) => i + 1).map((page) => (
              <div className="d-flex flex-end" key={page} onClick={() => paginate(page)}>
                <span className={`page-btn large-font me-2 rounded fw-600 ${currentPage === page ? "pink-bg clr-white" : "input-bg cursor-pointer"}`}>{page}</span>
              </div>
            ))}
            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredSearch.length / postsPerPage)} className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton></Modal.Header>
          {isBlocked === 1 ? (
            <>
              <h4 className="text-center">You Cannot Unblock him </h4>

              <div className="flex-center clr-red pointer" onClick={handleClose}>
                {" "}
                Close
              </div>
            </>
          ) : (
            <>
              <h4 className="text-center">Are You Sure want to Block ?</h4>
              <div className="flex-between ">
                <div className="block-btn-cancel mt-4 flex-center pointer" onClick={handleClose}>
                  Cancel
                </div>
                <div className="block-btn flex-center pointer mt-4 bg-pink clr-white" onClick={handleBlockUser}>
                  Block
                </div>
              </div>
            </>
          )}
        </Modal>
        <Successpopup show={showSuccessPopup} message={successMsg} />
      </div>
      <Customers addCustomerPopup={addCustomerPopup} setAddCustomerPopup={setAddCustomerPopup} fetchUsers={fetchUsers} selectedUser={selectedUser} isEditMode={editMode} />
    </div>
  );
};
export default Administrators;