import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import QRCodeModal from "./QRCodeModal";

const PaymentModal = ({ paymentpopup, setPaymentpopup }) => {
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [currency, setCurrency] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const handleShowQrCode = () => {
    setShowQrCode(true);
  };
  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };

  const countryCurrencyMap = {
    India: "INR",
    UAE: "AED",
    USA: "USD",
    SriLanka: "LKR",
    Singapore: "SGD",
  };
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCurrency(countryCurrencyMap[selectedCountry] || "");
  };
  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
  };
  const handlePayment = () => {
    setPaymentpopup(false);
  };
  const handleQrCodeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQrCodeImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Modal show={paymentpopup} onHide={handlePayment} size="lg" centered>
        <Modal.Body>
          <div className="bg-white br-20">
            <div className="flex-space-between text-black fw-bold px-2">
              <h5 className="fw-bold">Add Payment Gateway</h5>
              <h4>
                <RxCross2 className="pointer fw-bold" onClick={handlePayment} />
              </h4>
            </div>
            <div className="d-flex w-100 mt-2 medium-font fw-600">
              <div className="col-4 px-2">
                <label className="mb-1">Country Name</label>
                <select
                  onChange={handleCountryChange}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                >
                  <option value="">Select Country</option>
                  {Object.keys(countryCurrencyMap).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4 px-2">
                <label className="mb-1">Currency Name</label>
                <input
                  type="text"
                  value={currency}
                  placeholder="Currency"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
              <div className="col-4 px-2">
                <label className="mb-1">Payment Gateway</label>
                <select
                  className="input-box1 br-10 p-2 w-fill fw-500 pointer"
                  value={selectedPaymentGateway}
                  onChange={handlePaymentGatewayChange}
                >
                  <option value="">Select Payment Gateway</option>
                  <option value="Phone Pe">Phone Pe</option>
                  <option value="Google Pay">Google Pay</option>
                  <option value="Paytm">Paytm</option>
                  <option value="NEFT">NEFT</option>
                  <option value="QR Code">QR Code</option>
                </select>
              </div>
            </div>
            <div className="d-flex w-100 mt-2 medium-font fw-600">
              <div className="col-4 px-2">
                <label className="mb-1">Select Tour</label>
                <select className="input-box1 br-10 p-2 w-fill fw-500">
                  <option value="">Select Tour</option>
                  <option value="Casino Tour">Casino Tour</option>
                  <option value="Goa Tour">Goa Tour</option>
                  <option value="Ladakh Tour">Ladakh Tour</option>
                </select>
              </div>
              <div className="col-4 px-2">
                <label className="mb-1">Status</label>
                <select className="input-box1 br-10 p-2 w-fill fw-500">
                  <option value="">Status</option>
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </select>
              </div>
              {selectedPaymentGateway === "QR Code" && (
                <div className="col-4 px-2">
                  <label className="mb-1">Upload QR Code</label>
                  <input
                    type="file"
                    className="input-box1 br-10 p-2 w-fill fw-500"
                    onChange={handleQrCodeUpload}
                  />
                </div>
              )}
            </div>
            {selectedPaymentGateway !== "QR Code" && (
              <div className="d-flex w-100 mt-2 medium-font fw-600 px-2 flex-column">
                <label className="mb-1">UPI/Bank Details</label>
                <textarea className="input-box1 br-10 p-2 w-fill fw-500" />
              </div>
            )}
            {qrCodeImage && (
              <div className="flex-center mt-2">
                <img
                  onClick={handleShowQrCode}
                  src={qrCodeImage}
                  alt="QR Code"
                  className="qr-code-img"
                />
              </div>
            )}
            <div className="d-flex w-100 mt-3 medium-font fw-600 px-2">
              <div className="col pink-bg clr-white p-2 rounded-pill text-center pointer">
                Submit
              </div>
            </div>
          </div>
        </Modal.Body>
        <QRCodeModal
          showQrCode={showQrCode}
          qrCodeImage={qrCodeImage}
          handleCloseQrCode={handleCloseQrCode}
        />
      </Modal>
    </div>
  );
};

export default PaymentModal;
