const endpoints = {
  loginUser: { method: "post", url: "/login-user" },
  signUpUser: { method: "post", url: "/sign-up-user" },
  getTourCategories: { method: "get", url: "/category" },
  resetUserPassword: { method: "put", url: "/reset-password" },
  updateUserProfile: { method: "put", url: "/user-edit-profile" },
  getAllTrips: { method: "get", url: "/user/trips" },
  getUpcomingTrips: { method: "get", url: "/user/trips" },
  getCompletedTrips: { method: "get", url: "/user/trips" },
  getUserDetails: { method: "get", url: "/get-user-details" },
  getBannerById: {
    method: "get",
    url: (id) => `/banner/${id}`,
  },
  postForgotPassword: { method: "post", url: "/forgot-password" },
  postVerifyOTP: { method: "post", url: "/verify-otp" },
  updatePassword: { method: "put", url: "/update-password" },
  getPublishedTours: { method: "get", url: "/get-published-tours" },
  getBannerById: {
    method: "get",
    url: (id) => `/banner/${id}`,
  },
  getPublishedTourById: {
    method: "get",
    url: (id) => `/get-published-tours/${id}`,
  },
  getBankDetailsByTourId: {
    method: "get",
    url: (tour_id) => `/banking-by-tourId/${tour_id}`,
  },
  getTravelReports: { method: "get", url: "/agent/travelreportagent" },
  getDownlineTravelReportsById: {
    method: "get",
    url: (user_id) => `/agent/travelreportagent/${user_id}`,
  },
  getBestDestination: { method: "get", url: "/get-best-destination" },
  // memories
  getAllMemories: { method: "get", url: "/agent/get-all-memories" },
  getMemoryDetailsById: {
    method: "get",
    url: (id) => `/agent/get-memory-details-by-id/${id}`,
  },
  AddCommentsById: {
    method: "post",
    url: (id) => `/agent/agent-comment/${id}`,
  },
  EditCommentsById: {
    method: "put",
    url: (id) => `/agent/agent-edit-comment/${id}`,
  },
  DeletecommentById: {
    method: "delete",
    url: (id) => `/agent/agent-delete-comment/${id}`,
  },
  PostLike: { method: "post", url: `/agent/like` },
  disLike: { method: "delete", url: (id) => `/agent/dislike/${id}` },
  replyComment: { method: "post", url: (id) => `/agent/agent-comment/${id}` },
  uploadImages: { method: "post", url: "/upload" },
  getTravelReportDetailsSummaryById: {
    method: "get",
    url: (id) => `/agent/travelreportagent-summary/${id}`,
  },
  getTravelReportDetailsById: {
    method: "get",
    url: (id) => `/agent/travelreportagent/${id}`,
  },
  getBestDestination: { method: "get", url: "/get-best-destination" },
  getDownlinesById: {
    method: "get",
    url: (id) => `/get-created-users/${id}`,
  },
  // bookingUser: { method: "post", url: "/user/bookinguser" },
  bookingUser: { method: "post", url: "/agent/bookinguser" },

  uploadImage: { method: "post", url: "/upload" },

  getAllTours: { method: "get", url: "/tours" },
  getItineraryTours: { method: "get", url: (id) => `/tours?itineary=${id}` },
  getApprovedTours: { method: "get", url:(id) => `/tours?approved=${id}` },
  getOngoingTours: { method: "get", url:(id) => `/tours?ongoing=${id}` },
  getRejectedTours: { method: "get", url: (id) => `/tours?rejected=${id}`},

  //WalletAgent
  getWalletTable: {method: "get", url:"/agent/tickets-table"},
  getWalletStatus: {method: "get", url: "/agent/tickets-stats"},

  //withdraw
  postWithdraw: {method:"post", url:"/agent/withdraw"},
  createUserDeposit: { method: "post", url: "/agent/deposit" },
  getAllDepositBankings: { method: "get", url: "/get-deposit-bankings" },

  deleteUserDepositDetailsByID: {
    method: "delete",
    url: (id) => `/user/deposit/${id}`,
  },

  deleteUserWithdrawDetailsByID: {
    method: "delete",
    url: (id) => `/agent/withdraw/${id}`,
  },

  getUserDepositDetailsByID: {
    method: "get",
    url: (id) => `/user/deposit/${id}`,
  },

  getUserWithdrawDetailsByID: {
    method: "get",
    url: (id) => `/agent/withdraw/${id} `,
  },

  updateUserDepositDetailsByID: {
    method: "put",
    url: (id) => `/user/deposit/${id}`,
  },

  updateUserWithdrawDetailsByID: {
    method: "put",
    url: (id) => `/agent/withdraw/${id}`,
  },

  getComingTours: {
    method : "get",
    url : "/user/upcoming-schedule-tour"
  },

  updateImageById: {
    method: "put",
    url: (id) => `/update-upload/${id}`,
  },
  getCompletedToursByUserId: {
    method:"get",
    url:(id) =>`/agent/get-completed-tours/${id}`,
  },

};

export default endpoints;
