import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import {
  FaArrowLeftLong,
  FaArrowRightArrowLeft,
  FaArrowRightLong,
} from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { GiReceiveMoney } from "react-icons/gi";
import { LuPlaneTakeoff } from "react-icons/lu";
import { PiUsersThree } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { api } from "./../api/index";
import { Images } from "./../images/index";
import {
  getBestDestination,
  getTourCategories,
  getTravelReportDetailsSummaryById,
} from "../api-folder/apiMethods";
import { setTourCategoryData } from "../redux/action";
import moment from "moment";
// import { Images } from "../images";

const MiddlPage = ({ isExtended }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role_id = localStorage?.getItem("myid");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [letArrow, setLeftArrow] = useState("left");
  const [tourCategory, setTourCategory] = useState([]);
  const [bestDestinationData, setBestDestinationData] = useState([]);
  const [travelReportsSummary, setTravelReportsSummary] = useState();
  const [categoryPoster, setCategoryPoster] = useState([]);
  const user_name = localStorage.getItem("user_name");
  const [searchBar, setSearchBar] = useState();
  console.log(bestDestinationData, "bestDestinationData");
  useEffect(() => {
    getTourCategories()
      .then((response) => {
        if (response.status === true) {
          setTourCategory(response?.data?.category);
          setCategoryPoster(response?.data?.category);
          dispatch(setTourCategoryData(response?.data?.category));
        } else {
          console.error("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  }, []);
  console.log(categoryPoster, "categoryPoster");
  const toursTypes = tourCategory?.map((item) => {
    return {
      tourImage: item?.upload_path || Images.Rectangles,
      // tourImage: (item?.upload_path || Images.tour1).replace(/^"(.*)"$/, "$1"), // Remove leading/trailing quotes
      tourname: item?.category_name,
      tourPath: () => navigate(`/tours/${item?.slug}`),
    };
  });

  console.log(toursTypes, "toursTypes");
  useEffect(() => {
    if (isExtended && toursTypes && currentIndex > toursTypes.length - 4) {
      setCurrentIndex(0);
    }
  }, [isExtended, toursTypes]);

  const handleArrowActive = (value) => {
    setLeftArrow(value);
    if (value === "left" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (
      value === "right" &&
      toursTypes &&
      currentIndex < toursTypes.length - (isExtended ? 4 : 3)
    ) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    getBestDestination()
      .then((response) => {
        if (response?.status) {
          setBestDestinationData(response.data || []);
        }
      })
      .catch((error) => console.error(error?.message));
  }, []);

  useEffect(() => {
    getTravelReportDetailsSummaryById(role_id)
      .then((response) => {
        if (response?.status === true) {
          setTravelReportsSummary(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  }, [role_id]);

  const data = [
    {
      img1: <LuPlaneTakeoff className=" clr-pink font-2rem2" />,
      ac1: travelReportsSummary?.totalTravelPlanned,
      total: "Total Travel Planned",
    },
    {
      img1: <PiUsersThree className=" clr-pink font-2rem2" />,
      total: "Total Travel Planned",
      Admin: "Admin",
      ac1: travelReportsSummary?.downlinesCount?.marcketingTeamCount,
      Customer: "Customer",
      ac2: travelReportsSummary?.downlinesCount?.usersCount,
      total: "Total Team Members",
    },
    {
      img1: <PiUsersThree className=" clr-pink font-2rem2" />,
      ac1: travelReportsSummary?.totalTravelCoustmers,
      total: "Total Travel Customers",
    },
    {
      img1: <GiReceiveMoney className=" clr-pink font-2rem2" />,
      ac1: travelReportsSummary?.totalEarnings,
      total: "Total Earnings",
    },
  ];

  useEffect(() => {
    if (isExtended && currentIndex > toursTypes.length - 4) {
      setCurrentIndex(0);
    }
  }, [isExtended]);

  const roleId = localStorage.getItem("myid");
  const [banner, setBanner] = useState();

  console.log(banner, "===>banner");

  const getBannerbyId = async () => {
    try {
      const response = await api.fetchBannerbyId(roleId);
      setBanner(response?.data?.data?.banner_path);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getBannerbyId();
  }, [roleId]);

  const filterTours = bestDestinationData?.filter((item) =>
    item?.tour_title?.toLowerCase().includes(searchBar?.toLowerCase())
  );
  console.log(filterTours, "filterTours");

  return (
    <div className="middle-bg br-20 p-3">
      <div
        className="dashboard-banner p-3 br-20"
        style={{ backgroundImage: `url(${banner || Images.MainBanner1})` }}
      >
        <div className="flex-between fw-600">
          <div className="white-space text-white fw-bold max-content flex-center me-3">
            Hello, {user_name || "Robin"}!
          </div>
          <div className="d-flex align-items-center grey-text white-bg rounded-pill w-fill me-3">
            <CiSearch className="mx-2 pointer text-start" />
            <input
              placeholder="Search Destination"
              className="w-75 fw-500"
              value={searchBar}
              onChange={(e) => setSearchBar(e.target.value)}
            />
          </div>
          <div className="flex-center py-2 px-3 bg-pink text-white rounded-pill pointer me-3">
            <FaArrowRightArrowLeft />
          </div>
          <div className="bg-pink py-2 px-3 flex-center text-white rounded-pill pointer">
            Search
          </div>
        </div>
        <div className="flex-between mt-3 align-items-center">
          <h6 className="fw-bold max-content text-white mb-2">
            Coming Most Popular Tours
          </h6>
          {!isExtended && (
            <div className="d-flex">
              <button
                onClick={() => handleArrowActive("left")}
                value="left"
                className={`rounded-circle medium-font flex-center me-2 p-2 pointer 
               ${
                 letArrow === "left"
                   ? "bg-pink text-white"
                   : "text-pink text-bg-white"
               }`}
                disabled={currentIndex === 0}
              >
                <FaArrowLeftLong />
              </button>
              <button
                onClick={() => handleArrowActive("right")}
                className={`rounded-circle medium-font flex-center me-3 p-2 pointer 
              ${
                letArrow === "right"
                  ? "bg-pink text-white"
                  : "text-pink text-bg-white"
              }`}
                disabled={
                  currentIndex >= toursTypes.length - (isExtended ? 4 : 3)
                }
              >
                <FaArrowRightLong />
              </button>
            </div>
          )}
        </div>
        <div className="d-flex mt-1 pe-3 w-100 ">
          {toursTypes && toursTypes && toursTypes.length > 0 ? (
            toursTypes
              .slice(currentIndex, currentIndex + (isExtended ? 5 : 3))
              .map((tour, index) => (
                <div
                  className={`text-bg-white box-shadow-1 p-2 br-20 me-2 justify-start flex-column pointer ${
                    isExtended ? "col-3" : "col-4"
                  }`}
                  key={index}
                  onClick={tour?.tourPath}
                >
                  <img
                    alt={tour?.tourname}
                    src={tour?.tourImage}
                    className="w-100 h-25vh"
                  />
                  <h6 className="medium-font fw-600 mt-2 text-black">
                    {tour?.tourname}
                  </h6>
                </div>
              ))
          ) : (
            <div>No tours available</div>
          )}
        </div>
      </div>
      <div className="w-100 mt-1 d-flex p-1">
        <div className="col-5 pe-2">
          <div>
            <h6 className="fw-bold max-content text-black">Activity Summary</h6>
            {data.map((item, index) => (
              <div
                key={index}
                className="text-black justfiy-start align-items-center mt-2 bg-white w_100_m p-3 rounded-4 box-shadow-1"
              >
                <div className="light-orange-bg flex-center p-2 rounded-circle me-3">
                  {item.img1}
                </div>
                <div>
                  <div className="d-flex">
                    <div>
                      <div className="small-font bg-green fw-600 text-start rounded-pill px-1 me-3 me_3">
                        {item.Admin}
                      </div>
                      <h5 className="fw-600">{item.ac1}</h5>
                    </div>
                    <div className="text-black pe-5">
                      <div className="small-font bg-blue flex-center fw-600 rounded-pill px-1">
                        {item.Customer}
                      </div>
                      <h5 className="fw-600">{item.ac2}</h5>
                    </div>
                  </div>
                  <div className="small-font fw-600">{item.total}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-7">
          <div className=" bg-white br-20 box-shadow-1">
            <div className="flex-between align-items-center border-bottom p-2">
              <h6 className="fw-bold max-content text-black">
                Best Destination
              </h6>
              <span className="fw-500 text-hash medium-font">
                {bestDestinationData?.length} destination found
              </span>
            </div>
            <div className="v-f-y middle-page-scorll p-1">
              {bestDestinationData?.map((item, index) => (
                <div
                  className="flex-between align-items-center mt-2 ps-1"
                  key={index}
                >
                  <div className="flex-center">
                    <iframe
                      width="120"
                      height="80"
                      src="https://www.youtube.com/embed/ahy5o5nT4oI?si=SMZfKW9SD6vtSiR_"
                      className="rounded-4"
                    ></iframe>
                    <div className="ms-2 line-hight-25px">
                      <h6 className="fw-bold text-black mb-1">
                        {item.tour_title}
                      </h6>
                      <div className="fw-600 text-hash small-font white-space">
                        <MdDateRange className="medium-font me-1" />
                        <span>
                          {moment(item?.schedule_from).format("DD-MM-YYYY")}
                        </span>
                      </div>
                      <div className="fw-600 text-hash small-font white-space">
                        <CiLocationOn className="medium-font me-1" />
                        <span>{item?.tour_location}</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-pink text-white white-space medium-font fw-500 pointer rounded-pill px-3 py-1">
                    Book Now
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddlPage;
