import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

function DeletePopup({
  showConfirmationPopup,
  setShowConfirmationPopup,
  handleConfirm,
  description,
}) {
  const [active, setActive] = useState("yes");

  const buttons = [
    { id: "yes", label: "Yes" },
    { id: "reject", label: "No" },
  ];

  return (
    <Modal
      show={showConfirmationPopup}
      onHide={() => setShowConfirmationPopup(false)}
      centered
    >
      <div className="w-100 px-2 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 text-end">
          <RxCross2
            className="clr-black large-font cursor-pointer"
            onClick={() => setShowConfirmationPopup(false)}
          />
        </div>
       
        <div className="w-100 d-flex flex-column justify-content-center align-items-center flex-between">
          <div className="d-flex w-85 flex-column  password-clr text-center my-2 large-font fw-600">
            {description}
          </div>
          <div className="d-flex w-100 my-2 mx-2 flex-between">
            <div className="br-20 fw-600 medium-font text-center col-5 py-2 m-2 pink-bg clr-white  cursor-pointer" onClick={handleConfirm}>
              Yes
            </div>
            <div className="br-20 fw-600 medium-font text-center col-5 py-2 m-2 white-bg grey-text border-grey cursor-pointer">
              No
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeletePopup;
