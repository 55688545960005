import axios from "axios";
import environment from "../environments/environment";

const jwt_token = localStorage.getItem("jwt_token");
const myId = localStorage.getItem("myid");

const chatting = {
  getMyCreatorDetails: async () => {
    try {
      const response = await axios.get(
        `${environment.baseChatUrl}/api/creater/user-creaters`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },

  getChattingMessages: async (id) => {
    try {
      const response = await axios.get(
        `${environment.baseChatUrl}/api/chats/messages/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response, "creator messages");
      return response.data;
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  },

  sendChatMessage: async (id, data) => {
    try {
      const response = await axios.post(
        `${environment.baseChatUrl}/api/chats/messages/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response.data, "message sent");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  editMessage: async (recipientId, messageId, data) => {
    try {
      const response = await axios.put(
        `${environment.baseChatUrl}/api/chats/messages/${recipientId}/${messageId}`,
        {
          content: data, // Send the new message content in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response.data, "message edited");
      return response.data;
    } catch (error) {
      console.error("Error editing message:", error);
    }
  },

  deleteMessage: async (messageId) => {
    
    
    try {
      const response = await axios.delete(
        `${environment.baseChatUrl}/api/chats/messages/me/${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response.data, "message deleted");
      return response.data;
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  },

  deleteMessageForBothUsers: async (recipientId, messageId) => {
    try {
      const response = await axios.delete(
        `${environment.baseChatUrl}/api/chats/messages/${recipientId}/${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response.data, "message deleted for all");
      return response.data;
    } catch (error) {
      console.error("Error deleting message for both users:", error);
    }
  },

  forwardMessages: async (messageId, recipientIds, data) => {
    try {
      const response = await axios.post(
        `${environment.baseChatUrl}/api/chats/messages/forward/${messageId}/${recipientIds}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      console.log(response.data, "message forwarded");
      return response.data;
    } catch (error) {
      console.error("Error forwarding message:", error);
    }
  },
   blockTheUser: async (id) => {
    console.log(id, "===>in api call blockTheUser");

    try {
      const response = await axios.post(
        `${environment.baseChatUrl}/api/users/block/${id}`,
        {}, // Empty body for POST request
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error blocking the user:", error);
    }
  },

  getMyCreationUsers: async () => {
    try {
      const response = await axios.get(
        `${environment.baseChatUrl}/api/creater/users-by-creator/${myId}`,
    
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching creator users:", error);
    }
  },

  getOnlineUsers: async () => {
    try {
      const response = await axios.get(`${environment.baseChatUrl}/api/users/online-users`, {
        headers: { Authorization: `Bearer ${jwt_token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching online users:", error);
    }
  },
  getDelievryMessage: async (messageId) => {
    try {
      const response = await axios.put(`${environment.baseChatUrl}/api/chats/messages/delivered/${messageId}`, {
        headers: { Authorization: `Bearer ${jwt_token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching online users:", error);
    }
  },
  getSeenMessage: async (messageId) => {
    try {
      const response = await axios.post(
        `${environment.baseChatUrl}/api/chats/messages-seen/seen`,
        { messageId: messageId }, 
        {
          headers: { 
            Authorization: `Bearer ${jwt_token}`,
            
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error marking message as seen:", error);
    }
  },
  unBlockTheUser: async (id) => {
   

    try {
      const response = await axios.post(
        `${environment.baseChatUrl}/api/users/un-block/${id}`,
        {}, // Empty body for POST request
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error blocking the user:", error);
    }
  },
  getMyCreatorDetails: async () => {
    try {
      const response = await axios.get(`${environment.baseChatUrl}/api/creater/user-creaters`, {
        headers: {
          Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },
  chatWithTeam: async () => {
    try {
      const response = await axios.get(
        `${environment.baseChatUrl}/api/creater/marketing-team`,
        {
          headers: { Authorization: `Bearer ${jwt_token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching team chat messages:", error);
    }
  },
};

export default chatting;
