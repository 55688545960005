import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

function ViewDepositViewPopup({
  viewDepositDetails,
  setViewDepositDetails,
  viewDepositMode,
  depositDetails,
}) {
  return (
    <>
      <Modal size="md" show={viewDepositDetails} centered>
        <Modal.Body>
          <div className="px-2">
            <div className="flex-between">
              <div className="text-black large-font fw-bold">
                {viewDepositMode
                  ? "View Deposit Details"
                  : "Edit Deposit Details"}
              </div>
              <RxCross2
                className="pointer font-2rem"
                onClick={() => setViewDepositDetails(false)}
              />
            </div>

            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Payment Gateway</label>
                <input
                  type="text"
                  value={depositDetails?.gateway_name || ""}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Amount</label>
                <input
                  type="text"
                  value={depositDetails?.deposit_amount || ""}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
            </div>

            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Utr No</label>
                <input
                  type="text"
                  placeholder="Payment Mode"
                  value={depositDetails?.utr_no || ""}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
              <div className="w-100 mt-2">
                <label className="mb-1">Upi Details</label>
                <textarea
                  value={`${depositDetails?.mobile_number || ""}\n ${
                    depositDetails?.tnx_id || ""
                  }\n ${depositDetails?.upi_id || ""}\n${
                    depositDetails?.upi_name || ""
                  }`}
                  className="input-box1 small-font br-10 p-2 w-fill fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
              <div className="w-100">
                <label className="mb-1">Uploaded Screenshot</label>
                <img
                  src={depositDetails?.payment_image}
                  alt="payment"
                  className="h-10vh w-100"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewDepositViewPopup;
