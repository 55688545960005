import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function CommonTable({ data, columns, footer, itemsPerPage }) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  let startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  let endPage = Math.min(totalPages, startPage + 5 - 1);
  if (endPage - startPage < 5 - 1) {
    startPage = Math.max(1, endPage - 5 + 1);
  }
  const pageButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <div
        key={i}
        onClick={() => handlePageChange(i)}
        className={`page-btn large-font me-2 rounded fw-600 ${
          currentPage === i ? "pink-bg clr-white" : "input-bg cursor-pointer"
        }`}
      >
        {i}
      </div>
    );
  }
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  const currentData = data.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <div className="w-100 table-container">
        <table className="w-100">
          <thead className="border">
            <tr>
              {columns.map((column) => (
                <th
                  className="text-center small-font fw-500 p-2"
                  key={column.field}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="py-2 border">
            {currentData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="sub-heading-text white-bg border fw-500"
              >
                {columns.map((column) => (
                  <td
                    className="white-space small-font text-center p-2"
                    key={column.field}
                  >
                    {row[column.field]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {footer && (
            <tfoot className="border">
              <tr>
                {footer?.map((column, footerIndex) => (
                  <th
                    className="text-center small-font fw-bold p-2"
                    key={footerIndex}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <div className="d-flex">
          <div
            className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
            onClick={
              currentPage !== 1 ? () => handlePageChange(currentPage - 1) : null
            }
          >
            <FaChevronLeft />
          </div>
          {pageButtons}
          <div
            className="page-btn rounded input-bg large-font cursor-pointer fw-600"
            onClick={
              currentPage !== totalPages
                ? () => handlePageChange(currentPage + 1)
                : null
            }
          >
            <FaChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonTable;
