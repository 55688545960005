import login_api from "./login_api";
import getCategory from "./getCategory";
import createRoles from "./createRoles";
import rolewiseBanner from "./rolewiseBanner";
import apigetCategory from "./apigetCategory";
import forgotPassword from "./forgotPassword";
import profile from "./profile";


export const api = {
  ...login_api,
  ...getCategory,
  ...createRoles,
  ...rolewiseBanner,
  ...apigetCategory,
  ...forgotPassword,
  ...profile

};
