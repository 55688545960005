export const Images = {
  Logo: "./assets/t2tlogo.png",
  t2tlogin: "./assets/t2tloginlogo.png",
  t2tlogo: "../assets/t2tlogo.png",
  whbg: "./assets/bg-wh.png",
  bgwh: "./assets/whbg.jpg",
  side1: "./assets/side-1-hash.png",
  side2: "./assets/side-2.png",
  side3: "./assets/side-3.png",
  side4: "./assets/side-4.png",
  side5: "./assets/side-5.png",
  side6: "./assets/side-6.png",
  side7: "./assets/side-7.png",
  side8: "./assets/side-8.png",
  side9: "./assets/side-9.png",
  side10: "./assets/side-10.png",
  side11: "./assets/side-11.png",
  tour1: "./assets/tour-1.png",
  tour2: "./assets/tour-2.png",
  tour3: "./assets/tour-3.png",
  hb1: "../assets/hb-1.png",
  hb2: "./assets/hb-2.png",
  hb3: "./assets/hb-3.png",
  dh1: "./assets/dh-1.png",
  dh2: "./assets/dh-2.png",
  dh3: "./assets/dh-3.png",
  dh4: "./assets/dh-2.png",
  profile: "../assets/left-profile-pic.png",
  sidebg: "./assets/side-bg.png",
  sidebg2: "./assets/side-2-bg.png",
  letImg1: "./assets/letImg-1.png",
  ad1: "./assets/ad-1.png",
  ad2: "./assets/ad-2.png",
  ad3: "./assets/ad-3.png",
  ad4: "./assets/ad-4.png",
  ad5: "./assets/ad-5.png",
  ad6: "./assets/ad-6.png",
  screenChat: "./assets/screenChat.png",
  Logo: "./assets/logo.png",
  Rectangle: "./assets/loginlogo.png",
  Logos: "./assets/Group.png",
  Rectangles: "./assets/Rectangle 28.png",
  Rectangless: "./assets/Rectangle 31.png",
  Ellipse: "./assets/Ellipse 14.png",
  Loginbg: "./assets/login-bg.png",
  trip_img_01: "/assets/trip_img_01.png",
  trip_img_02: "/assets/trip_img_02.png",
  trip_img_03: "/assets/trip_img_03.png",
  upload_img_01: "../assets/upload_img_01.png",
  upload_img_02: "/assets/upload_img_02.png",
  upload_img_03: "/assets/upload_img_03.png",
  upload_img_04: "/assets/upload_img_04.png",
  upload_img_05: "/assets/upload_img_05.png",
  upload_img_06: "/assets/upload_img_06.png",
  Rectangles: "/assets/rectangles.png",
  CasinoBanner: "/assets/casinobanner.png",
  chat1: "./assets/chart-1.png",
  chat2: "./assets/chart-2.png",
  chat3: "./assets/chart-3.png",
  chat4: "./assets/chart-4.png",
  chat5: "./assets/chart-5.png",
  sendImage: "./assets/sendImage.png",
  tickTwo: "./assets/tick-2.png",
  chatBg: "./assets/chatBg.png",
  chatsupport: "./assets/chat-support.webp",
  SuccessImage: "./assets/likeimage.jpg",
};
