import React, { useEffect, useRef, useState } from "react";
import { Images } from "../images";
import { FaCheckDouble, FaChevronDown, FaRegBell } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { IoSend } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";
import { BiCheckDouble } from "react-icons/bi";
import { PiSquaresFourFill } from "react-icons/pi";
import EditProfile from "../pages/popups/EditProfile";
import chatting from "../api/chatting";
import moment from "moment";
import { uploadFilesToS3 } from "../api-folder/s3Service";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../utils/socketio";
import { useLocation } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { getComingTours, getUserDetails } from "../api-folder/apiMethods";
import { api } from "../api";
import { RxCross2 } from "react-icons/rx";
import { Modal } from "react-bootstrap";

function ProfileSideBar({ toggleMiddlePageWidth }) {
  const myId = localStorage.getItem("myid");
  const myName = localStorage.getItem("user_name");
  const myRole = localStorage.getItem("role");
  const chatEndRef = useRef(null);

  const [sideAcitve, setSideActive] = useState("Profile");
  const [notifications, setNotifications] = useState([]);

  const [message, setMessage] = useState("");
  const [userMessages, setUserMessages] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const [editingMessageId, setEditingMessageId] = useState(null); // Track the message being edited
  const [editedContent, setEditedContent] = useState(""); // Hold the edited message content
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const location = useLocation();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [adminConnect, setAdminConnect] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [officeTeam, setOfficeTeam] = useState([]);
  const [chatFullImage, setChatFullImage] = useState(false);
  const [fullImage, setFullImage] = useState();


  const NOTIFICATIONS = [
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "16/02/2024",
      endDate: "17/02/2024",
      status: "Approved",
      uploadTime: "10:30 pm",
      uploadDate: "28/05/2024",
    },
    {
      title: "Goa Trip",
      type: "Image/Video",
      startDate: "01/05/2024",
      endDate: "10/05/2024",
      status: "Uploaded",
      uploadTime: "12:00 pm",
      uploadDate: "15/05/2024",
    },
  ];

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const userData = async () => {
    try {
      const response = await getUserDetails();
      if (response.status === true) {
        console.log(response?.data, "==>user data");
        setCurrentUserDetails(response.data);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (selectedTitle.id) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", selectedTitle.id);

        // Define the handlers
        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setMessage((prevMessages) => [...prevMessages, message]);
        };

        // Clean up previous listeners and add new ones
        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user:1`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      // Disconnect the socket if no chatting user
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        // Cleanup socket connection and listeners
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: 1`);
      }
    };
  }, [selectedTitle, socketConnected]);

  const menuRef = useRef(null);
  const toggleMenu = (id) => {
    setOpenMenuIndex(openMenuIndex === id ? null : id);
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const startEditing = (msg) => {
    setEditingMessageId(msg.id); // Set the message to edit
    setEditedContent(msg.content); // Pre-fill the input with the current message content
    setOpenMenuIndex();
  };

  const cancelEditing = () => {
    setEditingMessageId(null); // Exit edit mode
    setEditedContent(""); // Clear the edited content
  };

  const saveEdit = (recipientId, messageId) => {
    handleEditMessage(recipientId, messageId, editedContent);

    console.log(recipientId, messageId, "===>error");

    setEditingMessageId(null); // Exit edit mode
    setEditedContent(""); // Clear the edited content
  };

  const handleEditMessage = async (recipientId, messageId, editedContent) => {
    console.log(recipientId, messageId, editedContent, "===>edit data here");

    try {
      const response = await chatting.editMessage(
        recipientId,
        messageId,
        editedContent
      );
      handleChatMessages();
      // Handle success response here (optional)
      console.log("Message edited successfully", response);
    } catch (error) {
      // Handle error here
      console.error("Failed to edit message", error);
    }
  };

  const handleChatMessages = async () => {
    try {
      const response = await chatting?.getChattingMessages(selectedTitle?.id);
      const filteredMessages = response.data.filter(
        (message) => !message.deletedBy
      );
      setMessage(filteredMessages);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };
  const getMyCreatorDetails = async () => {
    try {
      const response = await chatting.getMyCreatorDetails();
      console.log(response.data, "==>creator response");
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  };

  const handleChatFullImage = (mediaUrl) => {
    setFullImage(mediaUrl);
    setChatFullImage(!chatFullImage);
  };
  const defaultUser = (userName, id) => {
    setSelectedTitle({
      userName: userName,
      id: id,
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };
  const handleChange = (e) => setUserMessages(e.target.value);
  const handleSendMessage = async () => {
    try {
      const messageData = {
        senderId: Number(selectedTitle.id),
        recipientId: Number(myId),
        content: userMessages,
        messageType: uploadPosterURL?.length > 0 ? "image" : "text",
        mediaUrl: uploadPosterURL[0],
      };
      console.log(messageData, "==>messageData");

      emitEvent("chat:sendMessage", messageData);
      setMessage((prevMessages) => [
        ...prevMessages,
        {
          content:
            messageData.messageType === "image"
              ? messageData.mediaUrl
              : messageData.content,
          senderId: Number(selectedTitle.id),
          date: moment(new Date()).format("DD-MM-YYYY hh:mm A"),
        },
      ]);
      setUserMessages("");
      setUploadPosterURL("");
      setUploadProgress("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuIndex(null); // Close the menu
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleSideActive = (data) => {
    setSideActive(data);
    setAdminConnect(false);
    console.log(data, "==>data outside ");
    if (data === "Chat") {
      setAdminConnect(true);
      handleChatMessages();
      console.log(data, "==>data in chat ");
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await chatting.deleteMessage(messageId);
      handleChatMessages();
      // Handle success response here (optional)
      console.log("Message deleted successfully", response);
    } catch (error) {
      // Handle error here
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async (recipientId, messageId) => {
    try {
      const response = await chatting.deleteMessageForBothUsers(
        recipientId,
        messageId
      );
      handleChatMessages();
      // Handle success response here (optional)
      console.log("Message deleted for both users successfully", response);
    } catch (error) {
      // Handle error here
      console.error("Failed to delete message for both users", error);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
      setUserMessages("");
    }
  };

  const [showEditProfile, setShowEditProfile] = useState();

  const handleEditProfile = () => {
    setShowEditProfile(true);
  };

  useEffect(() => {
    getMyCreatorDetails();
    defaultUser();
   
  }, []);

  useEffect(() => {
    if (selectedTitle?.id) {
      handleChatMessages();
    }
  }, [selectedTitle?.id]);

  const [getNotificationsData, setGetNotificationsData] = useState([]);
  const getNotifications = () => {
    getComingTours()
      .then((response) => {
        if (response.status === true) {
          setGetNotificationsData(response?.data);
          console.log("coming tourssss", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getNotifications();
    userData();
    handleChatWithTeam();
  }, []);

  const [selectedTrip, setSelectedTrip] = useState(0);

  const isDateInRange = (date) => {
    if (!selectedTrip) return false;
    const from = moment(selectedTrip.from).startOf("day");
    const to = moment(selectedTrip.to).endOf("day");
    return moment(date).isBetween(from, to, "day", "[]");
  };

  const handleChatWithTeam = async () => {
    try {
      const response = await chatting.chatWithTeam();

      setOfficeTeam(response.data);
    } catch (error) {
      console.error("Error while chatting with team:", error);
    }
  };

  return (
    <div className="position-fixed top-0 bottom-0 me-2 w-fill">
      <div className="left-bg rounded-4 p-2 my-2">
        <div className="w-100 flex-between  medium-font">
          <span
            onClick={() => handleSideActive("Profile")}
            className={`pointer fw-500  flex-center rounded-pill px-3 ${sideAcitve === "Profile"
              ? "bg-pink text-white"
              : "text-black bg-white"
              }`}
          >
            Profile
          </span>
          <span
            onClick={() => handleSideActive("Chat")}
            className={`pointer fw-500  flex-center rounded-pill px-3 ${sideAcitve === "Chat"
              ? "bg-pink text-white"
              : "text-black bg-white"
              }`}
          >
            Chat
          </span>
          <span
            onClick={() => handleSideActive("notification")}
            className={`pointer fw-500 pointer flex-center rounded-pill px_3_2 ${sideAcitve === "notification"
              ? "bg-pink text-white"
              : "text-black bg-white"
              }`}
          >
            <FaRegBell />
          </span>
        </div>
        <div className="flex-end mt-1">
          <div className=" side-bg radius-round p-2 flex-center pointer ms-minus1">
            <FaChevronRight
              onClick={toggleMiddlePageWidth}
              className="pointer text-black"
            />
          </div>
        </div>

        {sideAcitve === "Profile" && (
          <div className="d-flex flex-column h-100">
            <div className="d-flex align-items-center">
              <img
                src={currentUserDetails?.profile_image || Images?.profile}
                alt="profile"
                className="chat_img me-2 rounded-circle border-pink2"
              />
              <div className="clr-white">
                <div className="medium-font fw-600">
                  {currentUserDetails?.user_name}
                </div>
                <div className="small-font fw-500">
                  {currentUserDetails?.role?.code}
                </div>

                <div
                  className="small-font fw-500 cursor-pointer"
                  onClick={() => handleEditProfile()}
                >
                  Edit Profile <MdEdit className="clr-pink " />
                </div>
              </div>
            </div>
            <div className="text-white fw-600 medium-font mt-1">
              Coming Tours
            </div>
            {/* Scrollable Tours Section */}
            <div className="coming-tours-div overflow-scroll-vertical  mt-2">
              {getNotificationsData.map((item, index) => (
                <div
                  key={item.tour_id}
                  // className="d-flex black-bg br-10 p-1 mt-1 align-items-center"

                  className={`schedule-tour-card d-flex mt-1 small-font w-100 tours-card p-2 rounded-4 ${selectedTrip &&
                    selectedTrip.from.toISOString() ===
                    new Date(item.schedule_from).toISOString() &&
                    selectedTrip.to.toISOString() ===
                    new Date(item.schedule_upto).toISOString()
                    ? "border-yellow cursor-pointer"
                    : ""
                    }`}
                  onClick={() =>
                    setSelectedTrip({
                      from: new Date(item.schedule_from),
                      to: new Date(item.schedule_upto),
                    })
                  }
                >
                  <img src={Images.letImg1} alt="" className="chat_img3 me-2" />
                  <div className="flex-column justify-content-evenly">
                    <div className="medium-font clr-white fw-600">
                      {item.tour_title}
                    </div>
                    <div className="d-flex clr-white">
                      <MdDateRange className="medium-font me-1" />
                      <span className="small-font fw-500">
                        {moment(item.schedule_from).format("DD MM,YYYY")}
                        
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="schedule-tour-card clr-white small-font my-1 br-10 p-2">
              Hi, If you are like any tour Please click on I am interest button
              then, I accept you, you see terms and book your trip
            </div>

            {/* Fixed Calendar */}
            <div className="calendar-container ">
              {/* <Calendar className="br-10 calender small-font black-clr w-100" /> */}
              <Calendar
                className="br-10 calender small-font black-clr w-100"
                tileClassName={({ date, view }) =>
                  view === "month" && isDateInRange(date) ? "highlight-red" : ""
                }
              />
            </div>
          </div>
        )}

        {sideAcitve === "Chat" && (
          <div>
            <div className="d-flex border-bottom-grey justify-content-around w-80">
              {officeTeam.map((online, index) => (
                <div className="p-2 col-5 pos-relative" key={index}>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100 cursor-pointer">
                    <img
                      src={Images.chat1}
                      className={`online-chat-profile rounded-pill ${
                        selectedTitle.userName === online.userName
                          ? "border-green"
                          : ""
                      }`}
                      onClick={() => defaultUser(online.userName, online.id)}
                      alt="Profile"
                    />
                    <div
                      className={`fw-bold font-10 ${
                        selectedTitle.userName === online.userName
                          ? "clr-white"
                          : "grey-text"
                      } text-center`}
                    >
                      {online.userName.split("_").map((part, index) => (
                        <div key={index}>{part}</div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{ height: "65vh" }}
              className="overflow-scroll-vertical"
            >
              <div className="profile-chat clr-white pt-2 pb-4">
                {message.length &&
                  message.map((msg, index) => (
                    <div key={index}>
                      <div
                        className={
                          Number(msg?.senderId) !== Number(myId)
                            ? "w-100 d-flex justify-content-end"
                            : "w-100 d-flex justify-content-start"
                        }
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                      >
                        <div className="d-flex flex-column max-50w position-relative message-container">
                          <div
                            className={
                              Number(msg?.senderId) !== Number(myId)
                                ? "small-font w-100 black-bg p-2 outgoing-msg br-10"
                                : "small-font w-100 black-bg p-2 outgoing-msg br-10"
                            }
                          >
                            <div>
                              {editingMessageId === msg.id ? (
                                <input
                                  type="text"
                                  value={editedContent}
                                  onChange={(e) =>
                                    setEditedContent(e.target.value)
                                  }
                                  className="edit-message-input"
                                />
                              ) : (
                                <div className="message-content small-font">
                                  {msg?.messageType === "image" ? (
                                    <img
                                      src={msg?.mediaUrl || msg?.content}
                                      alt="Message Image"
                                      className="chatImagesize-dashboard pointer"
                                      onClick={() =>
                                        handleChatFullImage(
                                          msg?.mediaUrl || msg?.content
                                        )
                                      }
                                    />
                                  ) : typeof msg?.content === "string" &&
                                    msg?.content.includes("http") ? (
                                    <img
                                      src={msg?.content}
                                      alt="Chat Image"
                                      className="chatImagesize-dashboard pointer"
                                    />
                                  ) : (
                                    <div>{msg?.content}</div>
                                  )}

                                  <span className="font-10">
                                    {msg.edited === true ? "edited" : ""}
                                  </span>
                                </div>
                              )}
                              {hoverIndex === index && (
                                <span
                                  className="three-dots-outer pointer"
                                  onClick={() => toggleMenu(msg.id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaChevronDown className="small-font down-icon-chat" />
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="d-flex w-100  flex-between">
                            <span className="timestamp mx-2 small-font">
                              {msg.timestamp
                                ? new Date(msg.timestamp).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )
                                : new Date().toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })}
                            </span>

                            {Number(msg?.senderId) !== Number(myId) && (
                              <span>
                                <FaCheckDouble className="font-8" />
                              </span>
                            )}
                          </div>

                          {openMenuIndex === msg.id && (
                            <div
                              className="position-absolute"
                              ref={menuRef}
                              style={{
                                top: "20px",
                                fontSize: ".6rem",
                                right: "10px",
                                background: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                zIndex: 1000,
                                color: "black",
                              }}
                            >
                              <ul className="popup-menu-homepage fw-700 small-font">
                                {/* Conditionally render Edit if the sender is the current user */}
                                {Number(msg?.senderId) !== Number(myId) &&
                                  msg?.messageType !== "image" && (
                                    <li
                                      className="menu-item"
                                      onClick={() => startEditing(msg)}
                                    >
                                      Edit
                                    </li>
                                  )}
                                <li
                                  className="menu-item"
                                  onClick={() => handleDeleteMessage(msg.id)}
                                >
                                  Delete
                                </li>

                                <li
                                  className="menu-item"
                                  onClick={() =>
                                    handleDeleteMessageForBothusers(
                                      msg.recipientId,
                                      msg.id
                                    )
                                  }
                                >
                                  Delete All
                                </li>
                              </ul>
                            </div>
                          )}

                          {editingMessageId === msg.id && (
                            <div className="edit-options d-flex justify-content-end mt-2">
                              <div
                                className="bg-yellow font-10 pointer"
                                onClick={() =>
                                  saveEdit(msg.recipientId, msg.id)
                                }
                              >
                                Save
                              </div>
                              <div
                                className="bg-yellow font-10 mx-2 pointer"
                                onClick={cancelEditing}
                              >
                                Cancel
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div ref={chatEndRef}></div>
            </div>

            <div className="white-bg w-100 flex-between align-items-center w-100 br-20 px-2 py-1 mt-2">
              <input
                type="text"
                placeholder={`${selectedFile &&
                  uploadProgress &&
                  uploadProgress[selectedFile.name] === 100
                  ? "Image uploaded"
                  : "Type your message"
                  }`}
                className="grey-text all-none w-75 py-2 small-font fw-600"
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                value={userMessages}
              />

              <label htmlFor="file-upload">
                <PiSquaresFourFill className="large-font clr-pink mx-1 pointer" />
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                id="file-upload"
                onChange={handleFileChange}
              />
              <div className="pink-bg rounded-circle text-center px-2 py-1 pointer">
                <IoSend
                  className="small-font clr-white text-center"
                  onClick={handleSendMessage}
                />
              </div>
            </div>
          </div>
        )}
        {sideAcitve === "notification" && (
          <div className="flex-column flex-between mt-2">
            <h6 className="clr-white fw-600 mb-0">Notifications</h6>
            <div className="profile-chat clr-white mt-2">
              {NOTIFICATIONS?.map((item, index) => (
                <div key={index}>
                  <div className="black-bg p-2 br-20 mt-1">
                    <div className="flex-between flex-wrap medium-font fw-600">
                      <span>{item?.title}</span>
                      <span>{item?.type}</span>
                    </div>
                    <div className="flex-between small-font fw-500 mt-1 clr-grey2">
                      <span>{item?.startDate}</span>
                      <span>{item?.endDate}</span>
                    </div>
                    <div
                      className={`d-flex small-font fw-600 mt-1 ${["Approved", "Uploaded", "Rejected"].includes(
                        item.status
                      )
                        ? "justify-content-end"
                        : ""
                        } `}
                    >
                      <li
                        className={
                          item.status === "Approved"
                            ? "clr-green"
                            : item.status === "Uploaded"
                              ? "upload-clr"
                              : item.status === "Rejected"
                                ? "reject-clr"
                                : "clr-white"
                        }
                      >
                        {item?.status}
                      </li>
                    </div>
                  </div>
                  <div className="flex-between small-font fw-500 mt-1 clr-grey2">
                    <span>{item?.uploadTime}</span>
                    <span>{item?.uploadDate}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal
        show={chatFullImage}
        onHide={() => setChatFullImage(false)}
        centered
        className="custom-modal"
        size="lg"
      >
        <div>
          <div className="w-100 d-flex justify-content-end">
            <RxCross2
              className="large-font pointer clr-white"
              onClick={() => setChatFullImage(false)}
            />
          </div>
          <div className="modal-body">
            <img
              src={fullImage}
              alt="Error To Show Image"
              className="full-image"
            />
          </div>
        </div>
      </Modal>
      <EditProfile
        showEditProfile={showEditProfile}
        setShowEditProfile={setShowEditProfile}
      />
    </div>
  );
}

export default ProfileSideBar;
