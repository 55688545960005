import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { updateUserDepositDetailsByID } from "../../api-folder/apiMethods";
import { useSelector } from "react-redux";

function EditDepositDetails({
  editDepositDetail,
  setEditDepositDetail,
  depositDetails,
  userWalletStatsData,
  selectDepositId,
}) {
  console.log(depositDetails, "depositDetails");
  const UserID = useSelector((state) => state.userDetailsData.id);

  const [formState, setFormState] = useState({
    deposit_amount: "",
    balance_amount: "",
    tnx_id: "",
  });
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);

  useEffect(() => {
    if (depositDetails && userWalletStatsData) {
      setFormState({
        deposit_amount: depositDetails.deposit_amount || "",
        balance_amount: userWalletStatsData.balance || "",
        tnx_id: depositDetails.tnx_id || "",
      });
    }
  }, [depositDetails, userWalletStatsData, selectDepositId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    const payload = {
      deposit_amount: formState.deposit_amount,
      balance_amount: formState.balance_amount,
      user_id: UserID,
      tnx_id: formState.tnx_id,
    };

    updateUserDepositDetailsByID(selectDepositId, payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Updated Response");
          setEditDepositDetail(false);
          setTimeout(() => {
            setShowSubmitPopup(true);
          }, 1000);
        } else {
          console.error("Error updating deposit details.");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Modal size="md" show={editDepositDetail} centered>
        <Modal.Body>
          <div className="px-2">
            <div className="flex-between">
              <div className="text-black large-font fw-bold">
                Edit Deposit Details
              </div>
              <RxCross2
                className="pointer font-2rem"
                onClick={() => setEditDepositDetail(false)}
              />
            </div>

            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Balance Amount</label>
                <input
                  type="number"
                  name="balance_amount"
                  placeholder="Amount"
                  value={formState.balance_amount}
                  onChange={handleInputChange}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  min={1000}
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Deposit Amount</label>
                <input
                  type="text"
                  name="deposit_amount"
                  placeholder="Enter Amount"
                  value={formState.deposit_amount}
                  onChange={handleInputChange}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                />
              </div>
            </div>
            <div className="w-100 medium-font fw-600 mt-2">
              <label className="mb-1">Tnx ID</label>
              <input
                type="text"
                name="tnx_id"
                placeholder="Enter Tnx id"
                value={formState.tnx_id}
                onChange={handleInputChange}
                className="input-box1 br-10 p-2 w-fill fw-500"
              />
            </div>
            <div
              className="pink-bg text-center py-2 clr-white br-20 mt-4 pointer"
              onClick={handleSaveChanges}
            >
              Save Changes
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditDepositDetails;
