import { useRef, useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { api } from "../../api";
import Successpopup from "./Successpopup";

const Customers = ({
  isEditMode,
  addCustomerPopup,
  setAddCustomerPopup,
  fetchUsers,
  selectedUser,
}) => {
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [msg, setMsg] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const role = localStorage.getItem("role");
  const updatedFields = useRef({});
  const token = localStorage.getItem("jwt_token");
  const handleCancel = () => {
    setAddCustomerPopup(false);
    setMsg(null);
    setFormErrors({});
    fetchUsers();
  };

  console.log(isEditMode,"==>isEditMode");
  
  useEffect(() => {
    if (isEditMode && selectedUser) {
      setFormData({
        role: selectedUser.role_code || "",
        user_name: selectedUser.user_name || "",
        password: "",
        location: selectedUser.location || "",
        city: selectedUser.city || "",
        state: selectedUser.state || "",
        country: selectedUser.country || "",
        country_code: selectedUser.country_code || "",
        phoneNumber: selectedUser.phone || "",
        casino_comm: selectedUser.casino_comm || "",
        other_tours_comm: selectedUser.other_tours_comm || "",
      });
    } else {
      setFormData({
        role: "",
        user_name: "",
        password: "",
        location: "",
        city: "",
        state: "",
        country: "",
        country_code: "",
        phoneNumber: "",
        casino_comm: "",
        other_tours_comm: "",
      });
    }
  }, [selectedUser, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "phoneNumber"
          ? value.replace(/[^0-9]/g, "").slice(0, 10)
          : value,
    }));

    if (isEditMode) {
      updatedFields.current[name] = value;
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;
  
    Object.keys(formData).forEach((field) => {
      // Skip validation for casino_comm and other_tours_comm if role is "user"
      if (formData?.role === "user" && ["casino_comm", "other_tours_comm"].includes(field)) {
        return;
      }
  
      // Validate other fields
      if (!formData[field] && (field !== "password" || !isEditMode)) {
        errors[field] = `${field.replace("_", " ")} is required`;
        isValid = false;
      }
    });
  
    // Special condition for password in edit mode
    if (isEditMode && !formData.password) {
      errors.password = "Password is required";
      isValid = false;
    }
  
    setFormErrors(errors);  
    return isValid;
  };
  
  

  const handleSubmit = () => {
    console.log("add clicked");
    
    if (!validateForm()) return;

  
    

   const response= api
      .createRole({
        role: formData?.role,
        user_name: formData.user_name,
        password: formData.password,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        location: formData.location,
        country_code: formData.country_code,
        phone: formData.phoneNumber,
        casino_comm: formData?.casino_comm || 0,
        other_tours_comm: formData?.other_tours_comm || 0,
      })
      .then((response) => {
        if (!response.status) {
          setMsg({ text: response.message, type: "Error" });
          return;
        }

        
        
        setSuccessMsg("User created successfully!");
        setFormData(null);
        setMsg(null);
        setShowSuccessPopup(true);
        setAddCustomerPopup(false);

        setTimeout(() => {
          fetchUsers();
          setShowSuccessPopup(false);
        }, 4000);
      })
      
      .catch((error) => {
        console.error("Error in creation:", error);
      });

      console.log(response,"==>response 123");
  };

  const handleUpdate = () => {
    console.log("update is clicked");
    
    if (!validateForm()) return;

    const dataToUpdate = {
      password: updatedFields.current.password,
      ...updatedFields.current,
    };

    api
      .updateUser(selectedUser.id, dataToUpdate, token)
      .then((response) => {
        if (!response.data.status) {
          setMsg({ text: response.message, type: "Error" });
          return;
        }

        setSuccessMsg("User updated successfully!");
        setFormData(null);
        setAddCustomerPopup(false);
        setShowSuccessPopup(true);

        setTimeout(() => {
          fetchUsers();
          setShowSuccessPopup(false);
        }, 4000);
      })
      .catch((error) => {
        console.error("Error in update:", error);
        setMsg({ type: "error", text: "Failed to update user." });
      });
  };

  const renderOptions = () => {
    switch (role) {
      case "director":
        return (
          <>
            <option value="manager">Manager</option>
            <option value="agent">Agent</option>
            <option value="user">Customer</option>
          </>
        );
      case "manager":
        return (
          <>
            <option value="agent">Agent</option>
            <option value="user">Customer</option>
          </>
        );
      case "agent":
        return <option value="user">Customer</option>;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal size="lg" show={addCustomerPopup} centered>
        <Modal.Body>
          <div className="bg-white br-20">
            <div className="flex-space-between text-black fw-bold px-2">
              <h5 className="fw-bold">
                {isEditMode ? "Edit" : "Add"} Administrators / Customers
              </h5>
              <h4>
                <RxCross2 onClick={handleCancel} className="pointer fw-bold" />
              </h4>
            </div>

            <>
                <div className="d-flex w-100 mt-2 medium-font fw-600">
                  <div className="col-4 px-2">
                    <label className="mb-1">Role</label>
                    <select
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="role"
                      value={formData?.role || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {renderOptions()}
                    </select>
                    {formErrors.role && (
                      <div className="error-text">{formErrors.role || ""}</div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="user_name"
                      value={formData?.user_name || ""}
                      onChange={handleChange}
                    />
                    {formErrors.user_name && (
                      <div className="error-text">{formErrors.user_name}</div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">Password</label>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="password"
                      value={formData?.password || ""}
                      onChange={handleChange}
                    />
                    {formErrors.password && (
                      <div className="error-text">{formErrors.password}</div>
                    )}
                  </div>
                </div>
                <div className="d-flex w-100 mt-2 medium-font fw-600">
                  <div className="col-4 px-2">
                    <label className="mb-1">Location</label>
                    <input
                      type="text"
                      placeholder="Enter Location"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="location"
                      value={formData?.location || ""}
                      onChange={handleChange}
                    />
                    {formErrors.location && (
                      <div className="error-text">{formErrors.location}</div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">City</label>
                    <input
                      type="text"
                      placeholder="Enter City"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="city"
                      value={formData?.city || ""}
                      onChange={handleChange}
                    />
                    {formErrors.city && (
                      <div className="error-text">{formErrors.city}</div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">State</label>
                    <input
                      type="text"
                      placeholder="Enter State"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="state"
                      value={formData?.state || ""}
                      onChange={handleChange}
                    />
                    {formErrors.state && (
                      <div className="error-text">{formErrors.state}</div>
                    )}
                  </div>
                </div>

                <div className="d-flex w-100 mt-2 medium-font fw-600">
                  <div className="col-4 px-2">
                    <label className="mb-1">Country</label>
                    <input
                      type="text"
                      placeholder="Enter Country"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="country"
                      value={formData?.country || ""}
                      onChange={handleChange}
                    />
                    {formErrors.country && (
                      <div className="error-text">{formErrors.country}</div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">Country Code</label>
                    <input
                      type="text"
                      placeholder="Enter Country Code"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="country_code"
                      value={formData?.country_code || ""}
                      onChange={handleChange}
                    />
                    {formErrors.country_code && (
                      <div className="error-text">
                        {formErrors.country_code}
                      </div>
                    )}
                  </div>
                  <div className="col-4 px-2">
                    <label className="mb-1">Phone Number</label>
                    <input
                      type="number"
                      placeholder="Enter Phone Number"
                      className="input-box1 br-10 p-2 w-fill fw-500"
                      name="phoneNumber"
                      value={formData?.phoneNumber || ""}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <div className="error-text">{formErrors.phoneNumber}</div>
                    )}
                  </div>
                </div>

                <div className="d-flex w-100 mt-2 medium-font fw-600">
                  {role !== "agent" && formData?.role !== "user" && (
                    <div className="col-4 px-2">
                      <label className="mb-1">Casino Comm </label>
                      <input
                        type="number"
                        placeholder="Enter Comm"
                        className="input-box1 br-10 p-2 w-fill fw-500"
                        name="casino_comm"
                        value={formData?.casino_comm || ""}
                        onChange={handleChange}
                      />
                      {formErrors.casino_comm && (
                        <div className="error-text">
                          {formErrors.casino_comm}
                        </div>
                      )}
                    </div>
                  )}
                  {role !== "agent" && formData?.role !== "user" && (
                    <div className="col-4 px-2">
                      <label className="mb-1">Other Tours Comm </label>
                      <input
                        type="number"
                        placeholder="Enter Comm"
                        className="input-box1 br-10 p-2 w-fill fw-500"
                        name="other_tours_comm"
                        value={formData?.other_tours_comm || ""}
                        onChange={handleChange}
                      />
                      {formErrors.other_tours_comm && (
                        <div className="error-text">
                          {formErrors.other_tours_comm}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>

            <div className="col-12 mt-2 d-flex justify-content-center align-items-center flex-column">
              <div
                className="text-center br-10 p-2 fw-600 pink-bg clr-white pointer rounded-pill mb-2"
                onClick={isEditMode ? handleUpdate : handleSubmit}
                style={{
                  width: "20%",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                {isEditMode ? "Update" : "Add"}
              </div>
              {msg && (
                <div
                  className={`text-center ${
                    msg.type === "success"
                      ? "alert-success-btn"
                      : "alert-danger-btn"
                  }`}
                >
                  {msg.text}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Successpopup show={showSuccessPopup} message={successMsg} />
    </>
  );
};

export default Customers;
