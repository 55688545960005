import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import QRCodeModal from "../addpayment/QRCodeModal";

function CancelExpences({
  showExpenceModal,
  setShowExpenceModal,
  setConfirmModal,
}) {
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
  };
  const handleOpenConfirmModal = () => {
    setConfirmModal(true);
  };
  const handleShowQrCode = () => {
    setShowQrCode(true);
  };
  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };
  const handleQrCodeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQrCodeImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const EXPENSE_DATA = [
    {
      item: "Room Booking",
      amount: 10000,
    },
    {
      item: "SGST",
      amount: 10000,
    },
    {
      item: "CGST",
      amount: 10000,
    },
    {
      item: "Other Charges",
      amount: 10000,
    },
  ];
  return (
    <Modal show={showExpenceModal} centered>
      <Modal.Body className="px-3">
        <div className="d-flex justify-content-end pointer">
          <RxCross2
            className="icon-font"
            onClick={() => setShowExpenceModal(false)}
          />
        </div>
        <h5 className="fw-600 text-black d-flex justify-content-center mb-2">
          TOTAL EXPENCES
        </h5>
        <div className="w-100 d-flex p-2 booknow-table-header clr-white medium-font fw-600">
          <div className="col-6 text-center">Total Paid Amount</div>
          <div className="col-6 text-center">100000</div>
        </div>
        {EXPENSE_DATA?.map((entry, index) => (
          <div
            key={index}
            className="w-100 d-flex p-2 border-bottom-grey password-clr small-font fw-600"
          >
            <div className="col-6 text-center">{entry.item}</div>
            <div className="col-6 text-center">{entry.amount}</div>
          </div>
        ))}
        <div className="w-100 d-flex p-2 input-bg password-clr medium-font fw-600">
          <div className="col-6 text-center">Total Return Amount</div>
          <div className="col-6 text-center">940000</div>
        </div>
        <h6 className="fw-600 text-black d-flex justify-content-center my-2">
          Add Your Original Payment Method(IND/INR)
        </h6>
        <div className="w-100">
          <label className="medium-font fw-500 mb-1 text-black">
            Payment Gateway
          </label>
          <select
            className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
            value={selectedPaymentGateway}
            onChange={handlePaymentGatewayChange}
          >
            <option value="">Select Payment Gateway</option>
            <option value="Phone Pe">Phone Pe</option>
            <option value="Google Pay">Google Pay</option>
            <option value="Paytm">Paytm</option>
            <option value="NEFT">NEFT</option>
            <option value="QR Code">QR Code</option>
          </select>
        </div>
        {selectedPaymentGateway === "QR Code" && (
          <div className="w-100 mt-2">
            <label className="medium-font fw-500 mb-1 text-black">
              Upload QR Code
            </label>
            <input
              type="file"
              className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
              onChange={handleQrCodeUpload}
            />
          </div>
        )}
        {selectedPaymentGateway !== "QR Code" && (
          <div className="w-100 mt-2">
            <label className="medium-font fw-500 mb-1 text-black">
              UPI/Bank Details
            </label>
            <textarea className="medium-font text-hash input-box1 br-10 p-2 w-fill fw-500 pointer" />
          </div>
        )}
        {qrCodeImage && (
          <div className="flex-center mt-2">
            <img
              onClick={handleShowQrCode}
              src={qrCodeImage}
              alt="QR Code"
              className="qr-code-img"
            />
          </div>
        )}

        <div className="flex-between w-100 mt-3 medium-font fw-600">
          <div
            className="btn-1 p-2 rounded-pill"
            onClick={handleOpenConfirmModal}
          >
            Proceed
          </div>
          <div
            className="btn-2 p-2 rounded-pill"
            onClick={() => setShowExpenceModal(false)}
          >
            Cancel
          </div>
        </div>
      </Modal.Body>
      <QRCodeModal
        showQrCode={showQrCode}
        qrCodeImage={qrCodeImage}
        handleCloseQrCode={handleCloseQrCode}
      />
    </Modal>
  );
}

export default CancelExpences;
