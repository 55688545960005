import { CiSearch } from "react-icons/ci";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

function SearchComponent() {
  const user_name = localStorage?.getItem("user_name")
  return (
    <div className="flex-between fw-600">
      <div className="white-space  fw-bold max-content flex-center me-3">
        Hello, {user_name || "Robin"}!
      </div>
      <div className=" text-bg-white rounded-pill w-fill justfiy-start-center me-3">
        <CiSearch className="mx-2 pointer text-start" />
        <input placeholder="Search Destination" className="w-75 rounded-pill" />
      </div>
      <div className="flex-center py-2 px-3 bg-pink text-white rounded-pill pointer me-3">
        <FaArrowRightArrowLeft />
      </div>
      <div className="bg-pink py-2 px-3 flex-center text-white rounded-pill pointer">
        Search
      </div>
    </div>
  );
}

export default SearchComponent;
