import React, { useState, useRef, useEffect } from "react";
import { Images } from "../../images";
import { GoDotFill } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { FaCheckDouble } from "react-icons/fa6";
import { FcAddImage } from "react-icons/fc";
import { IoSendSharp } from "react-icons/io5";
import moment from "moment";
import ForwardMessage from "../popups/ForwardMessage";
import chatting from "../../api/chatting";
import { FaChevronDown } from "react-icons/fa";
import EditMessage from "../popups/EditMessage";
import BlockPopup from "../popups/BlockPopup";
import { uploadFilesToS3 } from "../../api-folder/s3Service";
import { useLocation } from "react-router-dom";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketio";
import ChatBubble from "./ChatBubble";
import Notice from "./Notice";
import { RxCross2 } from "react-icons/rx";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "./animations/animation.json";
import animationdeleteall from "./animations/animationdeleteall.json";
import animationedit from "./animations/animationedit.json";
import { useDispatch, useSelector } from "react-redux";
import { setEditAnimation } from "../../redux/action";

const Chat = () => {
  const menuRef = useRef(null);
  const myId = localStorage.getItem("myid");
  const myRole = localStorage.getItem("role");
  const [userMessages, setUserMessages] = useState("");
  const [downLineUsers, setDownLineUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [onlineUsers, setonlineUsers] = useState("");
  const [trueOnlineUsers, setTrueOnlineUsers] = useState([]);
  const [forwardMessageId, setForwardMessageId] = useState("");
  const [hoverIndex, setHoverIndex] = useState(null);
  const [chattingWithUser, setChattingWithUser] = useState({
    userId: "",
    name: "",
    block: "",
    pic: "",
  });
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [openEditpopup, setOpenEditPopup] = useState(false);
  const [blockPopoup, setBlockPopup] = useState(false);
  const [editContent, setEditContent] = useState({
    userId: "",
    MessageId: "",
    content: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const [chatFullImage, setChatFullImage] = useState(false);
  const [messageSeenId, setMessageSeenId] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fullImage, setFullImage] = useState();
  const [socketConnected, setSocketConnected] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const location = useLocation();
  const [chatWindow, setChatWindow] = useState(false);
  const [chatImageUpload, setChatImageUpload] = useState([]);
  const [myCreatorDetails, setMyCreatorDetails] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(1);
  const [deletingMessageId, setDeletingMessageId] = useState(null);
  const [deleteMessageAllId, setDeleteMessageAllId] = useState(null);
  const [editMessageId, setEditMessageId] = useState(null);
const dispatch=useDispatch()
  const editAnimation = useSelector((state) => state.editAnimation);
  console.log(editAnimation, "==>editAnimation");

  const handleRoleSelection = (roleId) => {
    setSelectedRoleId(roleId);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const deleteAll = {
    loop: true,
    autoplay: true,
    animationData: animationdeleteall,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const animationeditOption = {
    loop: true,
    autoplay: true,
    animationData: animationedit,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (chattingWithUser.userId) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", chattingWithUser.userId);

        // Define the handlers
        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setMessage((prevMessages) => [...prevMessages, message]);
        };

        // Clean up previous listeners and add new ones
        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user: ${chattingWithUser.userId}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      // Disconnect the socket if no chatting user
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        // Cleanup socket connection and listeners
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${chattingWithUser.userId}`);
      }
    };
  }, [chattingWithUser, socketConnected]);

  useEffect(() => {
    disconnectSocket();
    setSocketConnected(false);

    if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
    }
  }, [location]);

  useEffect(() => {
    onEvent("user:typing", ({ senderId }) => {
      if (!typingUsers.includes(senderId)) {
        setTypingUsers((prev) => [...prev, recipientId]);
      }
      setTimeout(() => {
        setTypingUsers((prev) => prev.filter((id) => id !== recipientId));
      }, 10000);
    });

    onEvent("user:stoppedTyping", ({ senderId }) => {
      setTypingUsers((prev) => prev.filter((id) => id !== senderId));
    });

    return () => {
      offEvent("user:typing");
      offEvent("user:stoppedTyping");
      disconnectSocket();
    };
  }, []);

  const handleChatFullImage = (mediaUrl) => {
    setFullImage(mediaUrl);
    setChatFullImage(!chatFullImage);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const startEditing = (msg) => {
    setOpenEditPopup(true);
    setEditMessageId(msg.id);
    setEditContent({
      userId: msg.recipientId,
      MessageId: msg.id,
      content: msg.content,
    });
  };
  useEffect(() => {
    if (editAnimation) {
      // Set a timer to dispatch setEditAnimation(false) after 1.5 seconds
      const timer = setTimeout(() => {
        dispatch(setEditAnimation(false));
      }, 1500);
  
      // Cleanup the timeout in case the component is unmounted or the editAnimation state changes
      return () => clearTimeout(timer);
    }
  }, [editAnimation, dispatch]);
  const toggleMenu = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleChange = (e) => {
    handleTyping();
    setUserMessages(e.target.value);
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const blockUser = async () => {
    setBlockPopup(!blockPopoup);
  };

  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
      setChatImageUpload(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const getMyDownLineUsers = async () => {
    try {
      const response = await chatting.getMyCreationUsers();

      setDownLineUsers(response.data);
    } catch (error) {
      console.error("Error fetching downline users:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendChatMessages();
      setUserMessages("");
    }
  };
  const handleSelectedUser = async (id, userName, block, pic) => {
    setChatWindow(true);
    setChattingWithUser({
      userId: id,
      name: userName,
      block: block ?? false,
      pic: pic,
    });
    markMessageAsSeen();
  };

  const getMessages = async () => {
    try {
      await chatting
        .getChattingMessages(chattingWithUser.userId)
        .then((response) => {
          const filteredMessages = response?.data?.filter(
            (message) => !message.deletedBy
          );
          setMessage(filteredMessages);

          console.log(filteredMessages, "==>filteredMessages");

          const matchedContents = filteredMessages
            .filter((message) => Number(message.recipientId) !== Number(myId))
            .map((message) => message.id);

          setMessageSeenId(matchedContents);

          console.log(`Matching message ids:`, matchedContents);
          markMessageAsSeen();
        });
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  };
  useEffect(() => {
    if (chattingWithUser.userId) getMessages();
  }, [chattingWithUser.userId]);

  const senderId = Number(myId);
  const recipientId = Number(chattingWithUser?.userId);

  const sendChatMessages = async () => {
    try {
      const messageData = {
        senderId: Number(chattingWithUser.userId),
        recipientId: Number(myId),
        content: userMessages,
        messageType: uploadPosterURL?.length > 0 ? "image" : "text",
        mediaUrl: uploadPosterURL[0],
      };

      emitEvent("chat:sendMessage", messageData);
      if (Number(chattingWithUser.userId)) markMessageAsSeen();

      setMessage((prevMessages) => [
        ...prevMessages,
        {
          content:
            messageData.messageType === "image"
              ? messageData.mediaUrl
              : messageData.content,
          senderId: Number(chattingWithUser.userId),
          date: moment(new Date()).format("DD-MM-YYYY hh:mm A"),
          messageType: messageData.messageType,
        },
      ]);

      // if (messageData.messageType === "image") {
      //   await getMessages();
      // }

      setUploadedFile(null);
      setUploadedFileName("");
      setUserMessages("");
      setUploadProgress({});
      setUploadPosterURL("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const getOnlineusersDetails = async () => {
    try {
      const response = await chatting.getOnlineUsers();

      setonlineUsers(response.data.onlineFriends);
    } catch (error) {
      console.error("Error getting online users details:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await chatting.deleteMessage(messageId);
      setDeletingMessageId(messageId);
      console.log("Message deleted successfully", response);

      setTimeout(() => {
        getMessages();
        setDeletingMessageId(null);
      }, 1000);

      setOpenMenuIndex(null);
    } catch (error) {
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async (recipientId, messageId) => {
    try {
      const response = await chatting.deleteMessageForBothUsers(
        recipientId,
        messageId
      );

      setDeleteMessageAllId(messageId);
      setTimeout(() => {
        getMessages();
        setDeleteMessageAllId(null);
      }, 1000);
      setOpenMenuIndex(null);
    } catch (error) {
      console.error("Failed to delete message for both users", error);
    }
  };

  useEffect(() => {
    getMyDownLineUsers();
  }, []);
  useEffect(() => {
    getOnlineusersDetails();
    getMyCreatorDetails();
  }, []);

  useEffect(() => {
    const filteredOnlineFriends = downLineUsers.filter((friend) =>
      onlineUsers.includes(friend.userId)
    );

    setTrueOnlineUsers(filteredOnlineFriends);
  }, [downLineUsers, onlineUsers]);

  const handleForwardMessageId = (id) => {
    setIsModalOpen(true);
    setForwardMessageId(id);
  };

  const handleTyping = () => {
    if (!isTyping) {
      setIsTyping(true);

      // onTyping(senderId, recipientId);
    }

    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        setIsTyping(false);
        // onStopTyping(senderId, recipientId);
      }, 3000)
    );
  };

  const markMessageAsSeen = async () => {
    try {
      const response = await chatting.getSeenMessage(messageSeenId);
      if (response.data.status) {
        console.log("Message marked as seen:", response.data.data);

        const updatedMessages = userMessages.map((message) =>
          message.id === messageSeenId ? { ...message, seen: true } : message
        );
        setUserMessages(updatedMessages);
      }
    } catch (error) {
      console.error("Error marking message as seen:", error.message);
    }
  };

  const filterDownLine = downLineUsers.filter(
    (chat) =>
      chat.userName &&
      chat.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const getMyCreatorDetails = async () => {
    try {
      const response = await chatting.getMyCreatorDetails();
      setMyCreatorDetails(response.data);

      console.log(response.data, "==>creator response");
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  };

  return (
    <div className="w-100 d-flex p-2 middle-bg h90 br-20">
      <div className="col-3">
        <div className="bg-white br-10">
          <div className="border-bottom p-2">
            <h6 className="medium-font fw-600">Online Now</h6>
            <div className="d-flex scroll-x mt-2">
              {trueOnlineUsers.map((item, index) => (
                <div className="position-relative" key={index}>
                  <img src={Images.chat1} alt="" className="chat_img me-2" />
                  {item.unread && (
                    <div className="box-chat2 x-small-font">1</div>
                  )}
                  {item.online && (
                    <GoDotFill className="text-green-chat position-absolute bottom-0 end-0 me-2" />
                  )}
                  <span>{item.userName}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="p-2">
            <div className="medium-font fw-600">
              <span>
                {{
                  1: "Admin",
                  2: "Manager",
                  3: "Director",
                  4: "Agent",
                }[myCreatorDetails.roleId] || "Your Creator"}
              </span>
            </div>

            <hr />
            <div
              className="creator-chat w-100  px-1"
              onClick={() =>
                handleSelectedUser(
                  myCreatorDetails.id,
                  myCreatorDetails.userName,
                  myCreatorDetails.blocked,
                  myCreatorDetails.profileImage
                )
              }
            >
              <div className="flex-between align-items-center w-100 data-1 mb-2">
                <img
                  src={myCreatorDetails.profileImage || Images.chat1}
                  alt="image"
                  className="chat_img"
                />

                <div className="flex-between flex-column w-80 small-font pointer">
                  <div className="flex-row flex-between w-100 px-2">
                    <div className="text-black fw-600 w-70">
                      {myCreatorDetails.userName}
                    </div>
                  </div>
                  <div className="flex-row d-flex w-100 justify-content-between px-2">
                    <div
                      className={`pt-2 fw-500 max-content ${
                        myCreatorDetails.typing
                          ? "text-green-chat"
                          : "text-hash"
                      }`}
                    >
                      {myCreatorDetails.blocked ? (
                        <span className="small-font">Blocked</span>
                      ) : (
                        <span className="small-font">Tap To Chat</span>
                      )}

                      {myCreatorDetails.typing
                        ? `${myCreatorDetails.name} is typing..`
                        : myCreatorDetails.chatmsg}
                    </div>
                    {myCreatorDetails.unread && (
                      <div className="box-chat text-white bg-green-chat flex-center x-small-font fw-600 pointer mt-2">
                        1
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="p-2">
            <div className="medium-font fw-600">Users</div>
            <div className="flex-between border p-2 rounded-3">
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                className="small-font"
              />
              <CiSearch className="pointer" />
            </div>
          </div> */}
          <hr />
          <div className="d-flex border-bottom-grey w-100">
            <div className="d-flex flex-between p-2 col-12 cursor-pointer">
              {/* Manager */}
              {myRole === "director" && (
                <div
                  className="d-flex flex-column"
                  onClick={() => handleRoleSelection(2)}
                >
                  <img
                    src={Images.chat1}
                    className={`online-chat-profile rounded-pill ${
                      selectedRoleId === 2 ? "green-border-thick" : ""
                    }`}
                    alt="Profile"
                  />
                  <span className="fw-600 small-font">Manager</span>
                </div>
              )}

              {/* Agent */}
              {(myRole === "director" || myRole === "manager") && (
                <div
                  className="d-flex flex-column"
                  onClick={() => handleRoleSelection(4)}
                >
                  <img
                    src={Images.chat1}
                    className={`online-chat-profile rounded-pill ${
                      selectedRoleId === 4 ? "green-border-thick" : ""
                    }`}
                    alt="Profile"
                  />
                  <span className="fw-600 small-font">Agent</span>
                </div>
              )}

              {/* Customer */}
              {(myRole === "director" ||
                myRole === "manager" ||
                myRole === "agent") && (
                <div
                  className="d-flex flex-column"
                  onClick={() => handleRoleSelection(5)}
                >
                  <img
                    src={Images.chat1}
                    className={`online-chat-profile rounded-pill ${
                      selectedRoleId === 5 ? "green-border-thick" : ""
                    }`}
                    alt="Profile"
                  />
                  <span className="fw-600 small-font">Customer</span>
                </div>
              )}
            </div>
          </div>

          <div className="hight55vh w-100 scroll-y px-1">
            <div>
              {filterDownLine.filter(
                (item) =>
                  selectedRoleId === null || item.roleId === selectedRoleId
              ).length === 0 ? (
                <div className="text-center mt-3 text-hash fw-600">
                  No user found
                </div>
              ) : (
                filterDownLine
                  .filter(
                    (item) =>
                      selectedRoleId === null || item.roleId === selectedRoleId
                  )
                  .map((item, index) => {
                    const createdAtTime = new Date(item.createdAt);
                    const hours = createdAtTime.getHours() % 12 || 12;
                    const minutes = createdAtTime
                      .getMinutes()
                      .toString()
                      .padStart(2, "0");
                    const ampm = createdAtTime.getHours() >= 12 ? "pm" : "am";
                    const time = `${hours}:${minutes} ${ampm}`;

                    return (
                      <div
                        className="flex-between align-items-center w-100 mt-2 mb-2"
                        key={index}
                        onClick={() =>
                          handleSelectedUser(
                            item.id,
                            item.userName,
                            item.blocked
                          )
                        }
                      >
                        <img
                          src={item.profileImage || Images.chat1}
                          alt=""
                          className="chat_img"
                        />

                        <div className="flex-between flex-column w-80 small-font pointer">
                          <div className="flex-row flex-between w-100 px-2">
                            <div className="text-black fw-600 w-70">
                              {item.userName}
                            </div>
                            <div className="text-hash fw-500 w-90 text-end">
                              {time}
                            </div>
                          </div>
                          <div className="flex-row d-flex w-100 justify-content-between px-2">
                            <div
                              className={`pt-2 fw-500 max-content ${
                                item.typing ? "text-green-chat" : "text-hash"
                              }`}
                            >
                              {item.blocked ? (
                                <span className="small-font">Blocked</span>
                              ) : (
                                <span className="small-font">Tap To Chat</span>
                              )}

                              {item.typing
                                ? `${item.userName} is typing..`
                                : item.chatmsg}
                            </div>
                            {item.unread && (
                              <div className="box-chat text-white bg-green-chat flex-center x-small-font fw-600 pointer mt-2">
                                1
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        </div>
      </div>
      {chatWindow === true ? (
        <div className="col-9 chatbg-pic px-2 ">
          <div>
            <div className="border-bottom br-10 white-bg   position-relative">
              <div className="flex-between align-items-center p-2">
                <div className="d-flex align-items-center">
                  <img
                    src={chattingWithUser?.pic || Images.chat1}
                    alt=""
                    className="chat_img me-2"
                  />
                  <div>
                    <div className="medium-font text-black fw-600">
                      {chattingWithUser.name}
                    </div>
                    <div className="small-font text-green-chat fw-500 mt-1"></div>
                  </div>
                </div>
                <div>
                  {/* <MdBlock className="large-font grey-text" onClick={blockUser} /> */}
                </div>
              </div>
            </div>
            <div className="h80   w-100 medium-font scroll-y">
              <div className="medium-font fw-500 text-center text-hash my-1"></div>

              {message?.length &&
                message?.map((msg, index) => {
                  const messageDate = moment(msg.timestamp).format(
                    "YYYY-MM-DD"
                  );
                  const today = moment().format("YYYY-MM-DD");
                  const yesterday = moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");

                  const prevMessageDate =
                    index > 0
                      ? moment(message[index - 1]?.timestamp).format(
                          "YYYY-MM-DD"
                        )
                      : null;

                  const displayDate = messageDate !== prevMessageDate;

                  const displayDateText =
                    messageDate === today
                      ? "Today"
                      : messageDate === yesterday
                      ? "Yesterday"
                      : moment(msg.timestamp).format("MMMM DD, YYYY");

                  return (
                    <React.Fragment key={index}>
                      {displayDate && (
                        <div className="text-center fw-700 mt-3 mb-2">
                          <span className="small-font">{displayDateText}</span>
                        </div>
                      )}
                      <div
                        className={
                          Number(msg?.senderId) === Number(myId)
                            ? "w-100 d-flex justify-content-start"
                            : "w-100 d-flex justify-content-end"
                        }
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                      >
                        <div className="d-flex flex-column max-50w position-relative">
                          <div
                            className={
                              Number(msg?.senderId) === Number(myId)
                                ? "flex-column position-relative grey-text fw-600 grey-bg2 small-font p-2 br-10 px-3 position-relative m-2"
                                : "flex-column position-relative outgoing-chat-clr fw-600 lightgreen-bg small-font p-2 px-3 br-10 position-relative m-2"
                            }
                          >
                            {editingMessageId === msg?.id ? (
                              <input
                                type="text"
                                value={editedContent}
                                onChange={(e) =>
                                  setEditedContent(e.target.value)
                                }
                                className="edit-message-input"
                              />
                            ) : (
                              <div className="message-content medium-font pe-3">
                                {hoverIndex === index && (
                                  <span
                                    className="three-dots flex-end"
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => toggleMenu(index)}
                                  >
                                    <FaChevronDown className="small-font down-icon-chat" />
                                  </span>
                                )}

                                {/\.(jpg|jpeg|png|gif)$/i.test(
                                  msg?.mediaUrl || msg?.content
                                ) ? (
                                  <img
                                    src={msg?.mediaUrl || msg?.content}
                                    alt="Message Image"
                                    className="chatImagesize pointer"
                                    onClick={() =>
                                      handleChatFullImage(
                                        msg?.mediaUrl || msg?.content
                                      )
                                    }
                                  />
                                ) : /\.(mp4|webm|ogg)$/i.test(
                                    msg?.mediaUrl || msg?.content
                                  ) ? (
                                  <video
                                    controls
                                    className="chatImagesize pointer"
                                  >
                                    <source
                                      src={msg?.mediaUrl || msg?.content}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : typeof msg?.content === "string" &&
                                  msg?.content.includes("http") ? (
                                  <img
                                    src={msg?.content}
                                    alt="Chat Image"
                                    className="chatImagesize pointer"
                                  />
                                ) : (
                                  <div>{msg?.content}</div>
                                )}

                                <span className="font-10">
                                  {msg.edited === true ? "edited" : ""}
                                </span>
                              </div>
                            )}
                          </div>

                          {deletingMessageId === msg.id && (
                            <div className="lottie-container">
                              <Lottie
                                options={defaultOptions}
                                height={50}
                                width={50}
                              />
                            </div>
                          )}
                          {deleteMessageAllId === msg.id && (
                            <div className="lottie-container">
                              <Lottie
                                options={deleteAll}
                                height={50}
                                width={50}
                              />
                            </div>
                          )}
                          {editMessageId === msg.id && editAnimation && (
                            <div className="lottie-container">
                              <Lottie
                                options={animationeditOption}
                                height={50}
                                width={50}
                              />
                            </div>
                          )}

                          <div className="message-footer flex-between">
                            <span className="timestamp font-10 px-2">
                              {msg?.timestamp
                                ? new Date(msg?.timestamp).toLocaleTimeString(
                                    [],
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )
                                : new Date().toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                            </span>
                            {Number(msg?.senderId) !== Number(myId) && (
                              <FaCheckDouble
                                className={`${
                                  msg.seen === true
                                    ? "font-10  mx-1"
                                    : "font-10  mx-1"
                                }`}
                              />
                            )}
                          </div>

                          {openMenuIndex === index && (
                            <div
                              className="menu-options position-absolute"
                              ref={menuRef}
                              style={{
                                top: "20px",
                                fontSize: ".6rem",
                                right: "10px",
                                background: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                zIndex: 1000,
                              }}
                            >
                              <ul className="popup-menu fw-700 small-font">
                                <li
                                  className="menu-item"
                                  onClick={() => handleForwardMessageId(msg.id)}
                                >
                                  Forward
                                </li>
                                <li
                                  className="menu-item"
                                  onClick={() => {
                                    handleDeleteMessage(msg.id);
                                  }}
                                >
                                  Delete
                                </li>
                                <li
                                  className="menu-item"
                                  onClick={() => {
                                    handleDeleteMessageForBothusers(
                                      msg.recipientId,
                                      msg.id
                                    );
                                  }}
                                >
                                  Delete All
                                </li>
                                {Number(msg?.senderId) !== Number(myId) &&
                                  msg?.messageType !== "image" && (
                                    <li
                                      className="menu-item"
                                      onClick={() => startEditing(msg)}
                                    >
                                      Edit
                                    </li>
                                  )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}

              {typingUsers.length > 0 && (
                <>
                  <ChatBubble />
                </>
              )}

              <div ref={chatEndRef} />
            </div>
            <div className="p-2">
              <div className="flex-between align-items-center py-2 px-3 border rounded-pill">
                {uploadProgress !== null && (
                  <div className="mt-3 d-flex align-items-center"></div>
                )}
                <input
                  className="w-100 pe-3 small-font text-hash fw-500"
                  placeholder={`${
                    selectedFile &&
                    uploadProgress &&
                    uploadProgress[selectedFile.name] === 100
                      ? "Image uploaded"
                      : "Type your message"
                  }`}
                  value={userMessages}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <div className="flex-center">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="file-upload"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-upload">
                    <FcAddImage className="icon-font pointer" />
                  </label>

                  <div className="hight-25 mx-3"></div>
                  <IoSendSharp
                    className="icon-font pointer"
                    onClick={sendChatMessages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Notice />
      )}
      <Modal
        show={chatFullImage}
        onHide={() => setChatFullImage(false)}
        centered
        className="custom-modal"
        size="lg"
      >
        <div>
          <div className="w-100 d-flex justify-content-end">
            <RxCross2
              className="large-font pointer clr-white"
              onClick={() => setChatFullImage(false)}
            />
          </div>
          <div className="modal-body">
            <img
              src={fullImage}
              alt="Error To Show Image"
              className="full-image"
            />
          </div>
        </div>
      </Modal>

      <ForwardMessage
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        forwardMessageId={forwardMessageId}
        downLineUsers={downLineUsers}
      />
      <EditMessage
        openEditpopup={openEditpopup}
        setOpenEditPopup={setOpenEditPopup}
        editContent={editContent}
        getMessages={getMessages}
      />
      <BlockPopup
        blockPopoup={blockPopoup}
        setBlockPopup={setBlockPopup}
        chattingWithUser={chattingWithUser}
      />
    </div>
  );
};

export default Chat;
