import axios from "axios";
import environment from "../environments/environment";
const apiInstance = axios.create({
  baseURL: environment,
});


const setAuthHeader = () => {
  const jwt = localStorage.getItem("jwt_token");
  if (jwt) {
    apiInstance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  } else {
    delete apiInstance.defaults.headers.common["Authorization"];
  }
};

setAuthHeader();


apiInstance.interceptors.request.use(
  (config) => {
    setAuthHeader();
    return config;
  },
  (error) => Promise.reject(error)
);

const api = {
 
  //   try {
  //     const response = await apiInstance.get("/category");
      
  //     return response.data;
  //   } catch (error) {
  //     console.error(
  //       "Error fetching category:",
  //       error.response?.data || error.message
  //     );
  //     throw error;
  //   }
  // },
  getCategoryById:async(id)=>{
    try {
      const response=await apiInstance.get(`/category/${id}`)
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching category:",
        error.response?.data || error.message
      );
      throw error;
    }
  }
};

export default api;
