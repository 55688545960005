import { useState, useEffect, useMemo } from "react";
import "./styles.css";
import { Images } from "../../images";
import { MdCalendarMonth } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { Carousel } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import SearchComponent from "../../components/SearchComponent";
import { useNavigate, useParams } from "react-router";
import {
  getPublishedTours,
  getDownlinesById,
  getTourCategories,
} from "../../api-folder/apiMethods";
import BookNow from "../booknowpopup/BookNow";
import UserDetails from "../booknowpopup/UserDetails";
import { setTourCategoryData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

function BookNowTrip() {
  const { tourCategory } = useParams();
  console.log(tourCategory, "===>tourCategory");

  const { tourId } = useParams();
  const userId = localStorage.getItem("myid");
  const navigate = useNavigate();
  const [downlines, setDownlines] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [activeSlide, setActiveSlide] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [publishedToursData, setPublishedTourData] = useState([]);

  const tourCategoryData = useSelector((state) => state?.tourCategoryData);
  console.log(tourCategoryData, "===>tourCategoryDataaaaa");

  const tourCategoryHeading = useMemo(
    () =>
      tourCategory
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
    [tourCategory]
  );

  useEffect(() => {
    const getAllPublishedTours = () => {
      getPublishedTours({})
        .then((response) => {
          if (response?.status === true) {
            const filteredTours = response?.data?.data?.filter(
              (tour) => tour.slug.toLowerCase() === tourCategory.toLowerCase()
            );
            setPublishedTourData(filteredTours || []);
          } else {
            console.error("Failed to fetch tours");
          }
        })
        .catch((error) => {
          console.error("Error fetching tours:", error?.message);
        });
    };

    getAllPublishedTours();
  }, [tourCategory]);

  const IndividualTours = useMemo(
    () =>
      publishedToursData.map((tour) => ({
        imgSrc: tour.uploads.path || Images.Rectangles,
        title: tour.tour_title,
        date: new Date(tour.schedule_from).toLocaleDateString("en-GB"),
        location: tour.tour_location,
        path: `/tours/${tourCategory}/${tour.tour_id}`,
      })),
    [publishedToursData, tourCategory]
  );

  const descriptions = useMemo(
    () =>
      publishedToursData.reduce((acc, tour) => {
        acc[tour.tour_title] = tour.quotations || "No description available";
        return acc;
      }, {}),
    [publishedToursData]
  );

  const currentTitle = IndividualTours[activeSlide]?.title;
  const currentDescription =
    descriptions[currentTitle] || "No description available";

  useEffect(() => {
    setAnimationClass("fade-in-from-top");
    const timer = setTimeout(() => setAnimationClass(""), 1000);
    return () => clearTimeout(timer);
  }, [activeSlide]);

  const handlePrevClick = () => {
    setActiveSlide((prevSlide) =>
      prevSlide > 0 ? prevSlide - 1 : IndividualTours.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveSlide((prevSlide) =>
      prevSlide < IndividualTours.length - 1 ? prevSlide + 1 : 0
    );
  };

  console.log(publishedToursData, "===>publishedToursData");
  const [showBookNow, setShowBookNow] = useState(false);
  const handleBookNow = () => {
    setShowBookNow(true);
  };
  const fetchDownlineusersVyId = () => {
    getDownlinesById(userId)
      .then((response) => {
        if (response.status === true) {
          console.log(UserDetails, "===>UserDetailsssss");
          setDownlines(response.data);
        } else {
          console.log("Error Occured");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchDownlineusersVyId();
  }, [userId]);
  const handleSelectChange = (event) => {
    setSelectedUser(event.target.value);
    console.log("Selected user ID:", event.target.value);
  };

  const categoryData = tourCategoryData?.map((item) => ({
    name: item?.category_name,
    path: `/tours/${item?.slug}`,
  }));
  console.log(categoryData, "categoryData");

  const getBanners = useMemo(() => {
    return tourCategoryData?.find((item) => item?.slug === tourCategory);
  }, [tourCategoryData, tourCategory]);

  console.log(getBanners, "====>getBanners");

  return (
    <div className="middle-bg p-3 br-20">
      <SearchComponent />
      <h6 className="fw-700 my-2">{tourCategoryHeading}</h6>

      <Carousel>
        {getBanners?.banners?.map((image, index) => (
          <Carousel.Item key={index}>
            <img src={image?.path} className="w-100" alt={image?.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="white-bg p-3 mt-2 br-20">
        <div className="d-flex align-items-end">
          <h6 className="fw-bold">Upcoming {tourCategoryHeading}s</h6>
          <span className="small-font text-hash fw-500 ms-2">
            {publishedToursData.length} destinations found
          </span>
        </div>
        {IndividualTours.length > 0 ? (
          <div className="position-relative mt-2">
            <div className="d-flex w-100 justify-content-between pos-icon">
              <FaChevronLeft
                onClick={handlePrevClick}
                className="icon-theme cursor-pointer"
              />
              <FaChevronRight
                onClick={handleNextClick}
                className="icon-theme cursor-pointer"
              />
            </div>
            <div className="d-flex w-100 horizontal-scroll cursor-pointer">
              {IndividualTours.slice(activeSlide, activeSlide + 2).map(
                (item, index) => (
                  <div
                    key={item.title}
                    className="col-6 px-3"
                    onClick={() => navigate(item.path)}
                  >
                    <div
                      className={`w-100 d-flex section-bg br-20 ${
                        index === 0 ? "green-border" : ""
                      }`}
                    >
                      <img
                        src={item.imgSrc}
                        alt="img"
                        className="w-30 image-height br-20"
                      />
                      <div className="w-70 ps-3">
                        <div className="w-100 flex-column py-2">
                          <h6 className="fw-bold">{item.title}</h6>
                          <div className="d-flex small-font text-hash fw-600 my-2">
                            <div className="d-flex me-2">
                              <MdCalendarMonth className="medium-font me-1" />
                              <div>{item.date}</div>
                            </div>
                            <div className="d-flex">
                              <GrLocation className="medium-font me-1" />
                              <div>{item.location}</div>
                            </div>
                          </div>
                          <div className="pink-clr border-pink2 px-3 py-1 rounded-pill small-font fw-600 w-fit">
                            Book Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className="text-center mt-4">
            <h6 className="fw-700 password-clr">No Tours Available</h6>
          </div>
        )}
        <div className={`d-flex flex-column my-2 p-1 ${animationClass}`}>
          <h6 className="fw-700 password-clr">
            <u>{currentTitle} Details</u>
          </h6>
          <div className="small-font fw-500 grey-text mt-1">
            {currentDescription}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookNowTrip;
