import React, { useState, useEffect } from "react";
import { Images } from "../images";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { PiAirplaneTilt } from "react-icons/pi";
import { FiFileText } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";
import ResetPassword from "../pages/booknow/ResetPassword";
import { useNavigate, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiAdminLine } from "react-icons/ri";
import {
  IoWalletOutline,
  IoChatboxOutline,
  IoImagesOutline,
  IoSettingsOutline,
} from "react-icons/io5";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [openNested, setOpenNested] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [showResetPasswordPopup, setShowPasswordPopup] = useState(false);

  const tourCategoryData = useSelector((state) => state?.tourCategoryData);
  const categoryData = tourCategoryData?.map((item) => ({
    name: item?.category_name,
    path: item?.slug,
  }));

  // Logout Function
  const LogoutPage = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Handle Item Click
  const handleItemClick = (item, route, parentItem = null) => {
    setActiveItem(parentItem || item); // Highlight parent or current item
    setOpenNested(null); // Close any open nested menu
    localStorage.setItem("activeTab", parentItem || item); // Persist active tab in local storage
    navigate(route); // Navigate to the route
  };

  // Toggle Nested Menu
  const toggleNested = (item) => {
    setOpenNested(openNested === item ? null : item);
    setActiveItem(item);
    localStorage.setItem("activeTab", item); // Persist active parent tab
  };

  // Sidebar Items Configuration
  const sidebarItems = [
    {
      icon: <LuLayoutDashboard />,
      text: "Dashboard",
      onClick: () => handleItemClick("Dashboard", "/"),
      path: "/",
    },
    {
      icon: <FaRegCalendarAlt className="large-font" />,
      text: "Categories",
      onClick: () => toggleNested("Categories"),
      nestedItems: categoryData?.map((item) => ({
        text: item?.name,
        onClick: () => {
          handleItemClick("Categories", `/tours/${item?.path}`, "Categories");
        },
      })),
    },
    {
      icon: <PiAirplaneTilt className="large-font" />,
      text: "Tours",
      onClick: () => handleItemClick("Tours", "/tours"),
      path: "/tours",
    },
    {
      icon: <RiAdminLine />,
      text: "Marketing",
      onClick: () => handleItemClick("Marketing", "/marketing"),
      path: "/marketing",
    },
    {
      icon: <FiFileText />,
      text: "TravelReports",
      onClick: () => handleItemClick("TravelReports", "/travel-reports"),
      path: "/travel-reports",
    },
    {
      icon: <IoWalletOutline />,
      text: "Wallet",
      onClick: () => handleItemClick("Wallet", "/wallet"),
      path: "/wallet",
    },
    {
      icon: <IoChatboxOutline className="large-font" />,
      text: "Chat",
      onClick: () => handleItemClick("Chat", "/chat"),
      path: "/chat",
    },
    {
      icon: <IoImagesOutline className="large-font" />,
      text: "Memories",
      onClick: () => handleItemClick("Memories", "/memories"),
      path: "/memories",
    },
    {
      icon: <IoSettingsOutline className="large-font" />,
      text: "Settings",
      onClick: () => toggleNested("Settings"),
      nestedItems: [
        {
          text: "Reset Password",
          onClick: () => {
            setShowPasswordPopup(true);
            setOpenNested(null);
            setActiveItem("Settings");
          },
        },
        {
          text: "Privacy Policy",
          onClick: () =>
            handleItemClick("Settings", "/privacypolicy", "Settings"),
        },
      ],
    },
  ];

  // Load active tab from localStorage or determine by current route
  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) {
      setActiveItem(savedTab);
    } else {
      const matchingItem = sidebarItems.find(
        (item) => item.path === location.pathname
      );
      if (matchingItem) {
        setActiveItem(matchingItem.text);
      }
    }
  }, [location.pathname]);

  return (
    <div className="position-fixed top-0 bottom-0 text-start h-100vh w-inherit ps-2">
      <div className="px-3 py-2 pointer" onClick={() => navigate("/")}>
        <img src={Images.t2tlogo} alt="Logo" className="w-100" />
      </div>
      <div className="h78 fw-500 grey-text">
        {sidebarItems.map((item, index) => (
          <div key={index}>
            <div
              className={`flex-between align-items-center side-bar-div mt-1 p-2 br-10 medium-font largescreen-spaces cursor-pointer ${
                activeItem === item.text ? "pink-bg clr-white w-100" : ""
              }`}
              onClick={
                item.nestedItems
                  ? item.onClick
                  : () => {
                      item.onClick();
                    }
              }
            >
              <div className="w-80 flex-betwen">
                <span className="large-font">{item.icon}</span>
                <span className="ms-3 medium-font">{item.text}</span>
              </div>
              {item.nestedItems &&
                (openNested === item.text ? (
                  <FaAngleUp className="large-font me-2" />
                ) : (
                  <FaAngleDown className="large-font me-2" />
                ))}
            </div>
            {item.nestedItems && openNested === item.text && (
              <div className="back-ground grey-bg small-font rounded">
                {item.nestedItems.map((nestedItem, nestedIndex) => (
                  <div
                    key={nestedIndex}
                    className="ps-3 pb-2 grey-text mt-1 fw-500 cursor-pointer pink-hover medium-font"
                    onClick={nestedItem.onClick}
                  >
                    <span className="name-hover">{nestedItem.text}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <hr />
      <div
        className="text-hash flex-start p-2 w-100 pointer my-2 text-hash name-hover pointer"
        onClick={LogoutPage}
      >
        <IoIosLogOut className="d-flex large-font me-2" />
        <span className="medium-font fw-600">Log Out</span>
      </div>
      <ResetPassword
        showResetPasswordPopup={showResetPasswordPopup}
        setShowPasswordPopup={setShowPasswordPopup}
      />
    </div>
  );
}

export default SideBar;
