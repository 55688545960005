import React, { useState, useEffect } from "react";
import ProfileSideBar from "./ProfileSideBar";
import SideBar from "./SideBar";
import MiddlPage from "./MiddlPage";
import TravelReport from "./TravelReports";
import Wallet from "../pages/wallet/Wallet";
import Tours from "../pages/tours/Tours";
import Chat from "../pages/chat/Chat";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import BookNowTrip from "../pages/booknow/BookNowTrip";
import PrivacyPolicy from "../pages/booknow/PrivacyPolicy";
import ResetPassword from "./../pages/booknow/ResetPassword";
import AddPayment from "./../pages/addpayment/AddPayment";
import CloseSidebar from "./CloseSidebar";
import LoginComponent from "./../pages/loginpage/LoginComponent";
import Administrators from "./Administrators";
import Memories from "./../pages/booknow/Memories";
import TourMessage from "../pages/booknow/TourMessage";
// import OTP from "../pages/loginpage/OTP";
import LikesPage from "../pages/booknow/LikesPage";
import OtpComponent from "../pages/otppage/OtpComponent";
import InputDetailsPage from "../pages/booknowpopup/InputDetailsPage";

const Dashboard = () => {
  const [isExtended, setIsExtended] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("jwt_token");
    if (!isAuthenticated && location.pathname !== "/otp") {
      navigate("/login");
    }
  }, [navigate]);

  const toggleMiddlePageWidth = () => {
    setIsExtended(!isExtended);
  };

  const isChatPage = location.pathname === "/chat";

  return location.pathname === "/login" ? (
    <LoginComponent />
  ) : location.pathname === "/otp" ? (
    <OtpComponent />
  ) : (
    <div className="w-100 d-flex">
      <div className="col-2 white-bg">
        <SideBar />
      </div>
      <div
        className={`${isChatPage ? "col p-2" : isExtended ? "col-8 p-2" : "col p-2"
          }`}
      >
        <Routes>
          <Route path="/" element={<MiddlPage isExtended={!isExtended} />} />
          <Route path="/travel-reports" element={<TravelReport />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/tours/:tourCategory/:tourId" element={<TourMessage />} />
          <Route path="/tours/:tourCategory" element={<BookNowTrip />} />
          <Route path="/memories" element={<Memories />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/addpayment" element={<AddPayment />} />
          <Route path="/marketing" element={<Administrators />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/likes-page/:id" element={<LikesPage />} />
        </Routes>
      </div>
      {!isChatPage &&
        (isExtended ? (
          <div className="col-2">
            <ProfileSideBar toggleMiddlePageWidth={toggleMiddlePageWidth} />
          </div>
        ) : (
          <div className="w-4">
            <CloseSidebar toggleMiddlePageWidth={toggleMiddlePageWidth} />
          </div>
        ))}
    </div>
  );
};

export default Dashboard;
