import React, { useEffect, useRef, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { Images } from "./../../images/index";
import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateImageById, updateUserProfile, uploadImage, uploadImages } from "../../api-folder/apiMethods";
import SuccessPopup from "../popups/Successpopup";
import { IoMdAdd } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { api } from "./../../api/index";
import axios from "axios";
import { setUserDetails } from "../../redux/action";
import { uploadFilesToS3 } from "../../api-folder/s3Service";

const EditProfile = ({ showEditProfile, setShowEditProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryCodes = [
    { code: "+91", name: "India" },
    { code: "+971", name: "Dubai" },
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+61", name: "Australia" },
  ];

  const [userDetailsData, setUserDetailsData] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetailsData?.profile_image || Images.profile_img
  );
  const fileInputRef = useRef();


  const userData = async () => {
    try {
      const response = await api.getEdituserDetails();
      if (response.status === true) {
        setUserDetailsData(response?.data);
        dispatch(setUserDetails(response?.data));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    userData();
  }, []);

  const [editProfileData, setEditProfileData] = useState({});

  console.log(editProfileData,userDetailsData, "===>editProfileData")

  const [error, setError] = useState();
  const [message, setMessage] = useState();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setEditProfileData((prev) => ({
      ...prev,
      [name]:
        name === "phone" ? value.slice(0, 10) : files ? files[0].name : value,
    }));
  };

  const [uploadProfileImg, setUploadProfileImg] = useState();
  // const handleImageChange = (event) => {
  //   if (event.target.files.length > 0) {
  //     setUploadProfileImg(event.target.files[0]);
  //     setEditProfileData((prev) => ({
  //       ...prev,
  //       profileImage: event.target.files[0].name,
  //     }));
  //   }
  // };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log("fileee", file);
    if (file) {
      try {
        const url = await uploadFilesToS3([file], setUploadProgress);

        console.log([url[0]], "urllll");

        if (!url) throw new Error("Image upload failed");

        const uploadData = editProfileData.upload_id
          ? updateImageById(editProfileData.upload_id, {
            path: url,
            type: "image",
          })
          : uploadImage({ path: [url[0]], type: "image" });

        const response = await uploadData;

        if (response?.status) {
          setEditProfileData((prevData) => ({
            ...prevData,
            profile_image:[ url[0]],
            upload_id: editProfileData.upload_id || response.data[0]?.id,
          }));

          setProfileImageUrl(url);
        } else {
          throw new Error("Failed to upload/update image");
        }
      } catch (err) {
        console.error(
          "Image upload failed:",
          err.response?.data || err.message || "Unexpected error"
        );
      }
    }
  };

  console.log(editProfileData, "editProfileData");
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCountryCodeChange = (e) => {
    setEditProfileData((prev) => ({
      ...prev,
      country_code: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      let uploadProfileId = userDetailsData?.upload_id;

      if (uploadProfileImg) {
        const imgData = {
          path: JSON.stringify([uploadProfileImg?.name]),
          type: "image",
        };

        const responseProfile = await uploadImages(imgData);
        console.log(responseProfile, "responseProfile");

        uploadProfileId = responseProfile?.data[0]?.id;
      }

      const response = await updateUserProfile({
        name: editProfileData?.name || userDetailsData?.name,
        country_code:
          editProfileData?.country_code || userDetailsData?.country_code,
        phone: editProfileData?.phone || userDetailsData?.phone,
        upload_id: uploadProfileId,
      });

      if (response?.status === true) {
        console.log("Profile updated successfully", response);
        setMessage({
          text: "Your Profile Update Successfully",
          type: "success",
        });
        setEditProfileData({});
        setTimeout(() => {
          setMessage("");
        }, 500);
      } else {
        setError("Something went wrong while updating the profile");
      }
    } catch (error) {
      setError(error?.message || "An error occurred");
      console.error("Error during profile update:", error);
      setMessage({ text: "Your Profile not Updated", type: "error" });
    }
  };

  return (
    <Modal
      size="md"
      show={showEditProfile}
      centered
      onHide={() => setShowEditProfile(false)}
    >
      <>
        <div className="p-2">
          <div className="clr-black d-flex flex-between align-items-center">
            <div>
              <h5 className="mr-4 mx-2">Edit Profile</h5>
            </div>
            <div></div>
            <div>
              <span onClick={() => setShowEditProfile(false)}>
                <IoMdClose className="font-24 mx-2 pointer" />
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="flex-center mt-2 postion-relative pointer">
              <img
                src={userDetailsData?.profile_image}
                alt="profile-image"
                className="img-height rounded-circle"
              />

              <div className="clr-white bg-addicon edit-icon">
                <IoMdAdd
                  className="font-30 flex-center"
                  onClick={handleIconClick}
                />
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <div className="d-flex flex-column">
              <div className="password-clr fw-600 font-16 mx-2 ">Name</div>
              <input
                type="text"
                placeholder="Enter Name"
                className="input-box mx-2 br-5 py-2 w-96 fw-500 mt-1 px-2 clr-grey2"
                name="name"
                value={
                  editProfileData?.name !== undefined
                    ? editProfileData?.name
                    : userDetailsData?.name || ""
                }
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="password-clr fw-600 font-16 mx-2 mb-2">
                Phone number
              </div>
              <div className="d-flex">
                <select
                  className="input-box br-5 py-2 mx-2 px-2 w-34 fw-500 mt-1 clr-grey2"
                  value={
                    editProfileData?.country_code ||
                    userDetailsData?.country_code ||
                    ""
                  }
                  onChange={handleCountryCodeChange}
                >
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>

                <input
                  type="tel"
                  placeholder="Enter Phone Number"
                  className="input-box br-5 py-2 mx-1 fw-500 mx-2 px-2 mt-1 w-59 clr-grey2"
                  name="phone"
                  value={
                    editProfileData?.phone !== undefined
                      ? editProfileData?.phone
                      : userDetailsData?.phone || ""
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 mb-4 d-flex flex-column flex-center">
            <div
              className="white-input-bg border w-96 text-center clr-white py-2 br-20 medium-font pointer"
              onClick={handleSubmit}
            >
              Save
            </div>
            {message && (
              <span
                className={` mt-1 ${message?.type === "success" ? "clr-green" : "clr-red"
                  }`}
              >
                {message.text}
              </span>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default EditProfile;