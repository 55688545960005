import React, { useEffect, useState } from "react";
import { HiDownload } from "react-icons/hi";
import { FaRegShareSquare } from "react-icons/fa";
import {
  getDownlinesById,
  getPublishedTourById,
} from "../../api-folder/apiMethods";
import BookNow from "../booknowpopup/BookNow";
import { useParams } from "react-router";
import { Carousel } from "react-bootstrap";
import { Images } from "../../images";

function TourMessage() {
  const { tourId } = useParams();
  const userId = localStorage.getItem("myid");
  const [downlines, setDownlines] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTourData, setSelectedTourData] = useState();
  const [showBookNow, setShowBookNow] = useState(false);
  const handleBookNow = () => {
    setShowBookNow(true);
  };
  const fetchTourDataById = () => {
    getPublishedTourById(tourId)
      .then((response) => {
        if (response?.status === true) {
          setSelectedTourData(response?.data[0]);
        } else {
          console.log("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  const fetchDownlineusersVyId = () => {
    getDownlinesById(userId)
      .then((response) => {
        if (response.status === true) {
          console.log(response);
          setDownlines(response.data);
        } else {
          console.log("Error Occured");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchDownlineusersVyId();
  }, [userId]);

  useEffect(() => {
    fetchTourDataById();
  }, [tourId]);
  console.log(downlines, "downlines");
  const handleSelectChange = (event) => {
    setSelectedUser(event.target.value);
    console.log("Selected user ID:", event.target.value);
  };

  console.log(selectedTourData, "===>selectedTourData");
  console.log(downlines, "downlines");
  console.log(selectedUser, "selectedUser");
  return (
    <div>
      <h5 className="password-clr my-2 fw-700">Tour Message</h5>
      <Carousel>
        {selectedTourData?.banners?.map((image, index) => (
          <Carousel.Item key={index}>
            <img src={image?.path} className="w-100" alt={image?.alt} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="white-bg my-2 p-2 br-10">
        <h5 className="password-clr fw-700">Message</h5>
        <hr className="hr-line" />
        <div className="flex-between grey-text small-font p-2 grey-border br-20 mt-2">
          {selectedTourData?.quotations}
        </div>

        {/* <div className="d-flex justify-content-end">
          <div className="flex-between w-100 mt-3 fw-600 medium-font">
            <select
              className="w-30 p-2 input-bg bg-none br-10 clr-black fw-600 small-font"
              value={selectedUser}
              onChange={handleSelectChange}
            >
              <option value="">Select Customer</option>
              {downlines.length > 0 ? (
                downlines.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.user_name}
                  </option>
                ))
              ) : (
                <option disabled>No downlines available</option>
              )}
            </select>
          </div>

          <div
            className="w-25 pink-bg py-2 br-20 clr-white fw-700 text-center my-2 pointer"
            onClick={() => handleBookNow()}
          >
            Book Now
          </div>
        </div> */}
        <div className="d-flex justify-content-end">
          <div className="flex-between w-100 mt-3 fw-600 medium-font">
            <select
              className="w-30 p-2 input-bg bg-none br-10 clr-black fw-600 small-font"
              value={selectedUser}
              onChange={handleSelectChange}
            >
              <option value="">Select Customer</option>
              {downlines.length > 0 ? (
                downlines.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.user_name}
                  </option>
                ))
              ) : (
                <option disabled>No downlines available</option>
              )}
            </select>
          </div>

          <button
            className={`w-25 py-2 br-20 fw-700 text-center my-2 pointer ${
              selectedUser ? "pink-bg clr-white" : "bg-grey clr-grey"
            }`}
            onClick={handleBookNow}
            disabled={!selectedUser}
          >
            Book Now
          </button>
        </div>
      </div>
      <BookNow
        showBookNow={showBookNow}
        setShowBookNow={setShowBookNow}
        tourId={tourId}
        selectedTourData={selectedTourData}
        selectedUser={selectedUser}
      />
    </div>
  );
}

export default TourMessage;
