import { memo, useEffect, useRef, useState } from "react";
import { CgCalendarDates } from "react-icons/cg";
import { Images } from "../../images";
import { disLike, getAllMemories, PostLike } from "../../api-folder/apiMethods";
import { useNavigate } from "react-router";
import { FaCommentDots, FaHeart } from "react-icons/fa6";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

function Memories() {
  const navigate = useNavigate();

  const buttons = ["All Photos", "Latest", "Popular"];

  const [selectedCard, setSelectedCard] = useState(null);
  const [activeButton, setActiveButton] = useState(0);
  const handleButton = (index) => {
    setActiveButton(index);

    if (index === 1) {
      fetchAlMemories({ latest: "latest" });
    } else if (index === 2) {
      fetchAlMemories({ latest: "popular" });
    } else {
      fetchAlMemories();
    }
  };

  // here i need to separate videos and images by type
  const [allMemories, setAllMemories] = useState([]);
  const [photos, setphotos] = useState([]);
  console.log("photos", photos);
const videoRef = useRef()
  const [videos, setvideos] = useState([]);
  console.log("videos", videos);
  console.log(allMemories, "get all memories");
  const fetchAlMemories = (latest = {}) => {
    getAllMemories({ latest })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "resposne", latest);
          setAllMemories(response?.data?.records);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAlMemories();
  }, []);

  useEffect(() => {
    const photosData = [];
    const videosData = [];

    allMemories.forEach((memory) => {
      if (memory.type === "image") {
        photosData.push(memory);
      } else if (memory.type === "video") {
        videosData.push(memory);
      }
    });

    setphotos(photosData);
    setvideos(videosData);
  }, [allMemories]);

  useEffect(() => {
    if (videos.length > 0) {
      setSelectedCard(0);
      setIsVideo(videos[0]?.path);
    }
  }, [videos]);

  const [likedStatus, setLikedStatus] = useState({});
  useEffect(() => {
    const initialLikeStatus = {};
    photos.forEach((photo) => {
      initialLikeStatus[photo.memory_id] = !!photo.like_id;
    });
    setLikedStatus(initialLikeStatus);
  }, [photos]);

  const handleLikes = (memoryId, likedId) => {
    if (likedStatus[memoryId]) {
      disLike(likedId)
        .then((response) => {
          if (response.status === true) {
            console.log(response, "response dislike");
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: false,
            }));
            setphotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                    ...photo,
                    likes_count: photo.total_likes_count - 1,
                    like_id: null,
                  }
                  : photo
              )
            );
            fetchAlMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      PostLike({ memories_id: memoryId })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "liked successfully");
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: true,
            }));
            setphotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                    ...photo,
                    likes_count: photo.total_likes_count + 1,
                    like_id: response?.data?.like_id,
                  }
                  : photo
              )
            );
            fetchAlMemories();
            console.log(response, "post likes");
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const [videoPlay, setVideoPlay] = useState();
  const [isVideo, setIsVideo] = useState();

  
  const handleVideoPlay = (videoPath, index) => {
    setIsVideo(videoPath);
    setSelectedCard(index); 
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 100);
  };
  
  const videoPlayModal = (videoPath) => {
    if (videoRef.current) {
      videoRef.current.pause(); 
    }
    setIsVideo(videoPath);
    setVideoPlay(true);
  };

  return (
    <div className="middle-bg p-3 br-20">
      <h5 className="fw-600">Tour Videos</h5>
      <div className="mt-2 row">
        <div className="col-8" onClick={() => videoPlayModal(isVideo)}>   
                <video
                  ref = {videoRef}
                 className="memory-scroll br-30 w-100"
                  src={isVideo }
                  style={{ pointerEvents: "none" }}
                  controls
                />
          </div>
      
        <div className="col-4 memory-scroll">
          {videos.map((item, i) => {
            const isSelected = selectedCard === i;
            return (
              <div
                key={i}
                className={`bg-white clr-black d-flex br-10 mb-2 p-2 
                
                  ${isSelected ? "border-pink2" : ""}
                }`}
                onClick={() => {
                  // setSelectedCard(i);
                  handleVideoPlay(item?.path,i);
                }}
              >
                <video
                  alt="title"
                  src={item?.path }
                  className="img-fluid br-20 "
              
                />
                <div className="flex-column justify-content-center ms-2">
                  <div className="medium-font fw-600 text-black">
                    {item?.tour_title}
                  </div>
                  <div className="fw-500 text-hash d-flex align-items-center mt-2 small-font">
                    <CgCalendarDates className="me-1" />

                    {formatDateTime(item?.created_at)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-white br-20 px-3 mt-2">
        <div className="align-items-center py-2">
          <h5 className="fw-600">Tour Photos</h5>
        </div>
        <div className="d-flex flex-start medium-font fw-600">
          {buttons.map((btn, index) => (
            <div
              key={index}
              className={`py-2 px-3 my-1 rounded-pill ms-3 pointer
                 ${activeButton === index
                  ? "pink-bg clr-white pointer "
                  : "section-bg password-clr pointer"
                }
                `}
              onClick={() => handleButton(index)}
            >
              {btn}
            </div>
          ))}
        </div>
        {activeButton === 0 && (
          <div className="row memory-scroll ">
            {photos?.map((img, index) => {
              return (
                <div className="px-1 col-4 " key={index}>
                  <img
                    alt="memoryname"
                    src={img?.path || Images?.upload_img_01}

                    className="img-card m-1 cursor-pointer"
                    onClick={() => {
                      navigate(`/likes-page/${img?.memory_id}`);
                    }}
                  />
                  <div className="d-flex justify-content-between position-comment w-100 px-2">
                    <div className="d-flex flex-row justify-content-start  align-items-center large-font cursor-pointer">
                      <FaHeart
                        className={`large-font pointer ${likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                          } mr-1`}
                        onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                      />

                      <span className="clr-white mx-1 medium-font">
                        {img?.total_likes_count || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-center large-font cursor-pointer">
                      <FaCommentDots className="large-font me-1 clr-red" />
                      <span className="clr-white medium-font">{img?.comments_count || 0}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeButton === 1 && (
          <div className="row memory-scroll ">
            {photos?.map((img, index) => {
              return (
                <div className="px-1 col-4 " key={index}>
                  <img
                    alt="memoryname"

                    src={img?.path || Images?.upload_img_01}
                    className="img-card m-1 cursor-pointer"
                    onClick={() => {
                      navigate(`/likes-page/${img?.memory_id}`);
                    }}
                  />
                  <div className="d-flex justify-content-between position-comment w-100 px-2">
                    <div className="d-flex flex-row justify-content-start  align-items-center large-font cursor-pointer">
                      <FaHeart
                        className={`large-font pointer ${likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                          } mr-1`}
                        onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                      />

                      <span className="clr-white mx-1 medium-font">
                        {img?.total_likes_count}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-center large-font cursor-pointer">
                      <FaCommentDots className="large-font me-1 clr-red" />
                      <span className="clr-white medium-font">{img?.comments_count}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeButton === 2 && (
          <div className="row memory-scroll ">
            {photos?.map((img, index) => {
              return (
                <div className="px-1 col-4 " key={index}>
                  <img
                    alt="memoryname"
                    src={img?.path || Images?.upload_img_01}
                    className="img-card m-1 cursor-pointer"
                    onClick={() => {
                      navigate(`/likes-page/${img?.memory_id}`);
                    }}
                  />
                  <div className="d-flex justify-content-between position-comment w-100 px-2">
                    <div className="d-flex flex-row justify-content-start  align-items-center large-font cursor-pointer">
                      <FaHeart
                        className={`large-font pointer ${likedStatus[img?.memory_id] ? "clr-red" : "clr-white"
                          } mr-1`}
                        onClick={() => handleLikes(img?.memory_id, img?.like_id)}
                      />

                      <span className="clr-white mx-1 medium-font">
                        {img?.total_likes_count}
                      </span>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-center large-font cursor-pointer">
                      <FaCommentDots className="large-font me-1 clr-red" />
                      <span className="clr-white medium-font">{img?.comments_count}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

      </div>

      <Modal show={videoPlay} onHide={() => setVideoPlay(false)} centered>
        <div className="d-flex flex-column">
          <div className="text-end" onClick={() => setVideoPlay(false)}>
            <IoClose className="font-30 mb-2" />
          </div>

          {videoPlay && (
            <div>
                {/* <div className="medium-font fw-600 text-black">
                    {item?.tour_title}
                  </div> */}
              <video
                src={ isVideo }
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                controls
                style={{ width: "100%", height: "500px", border: "none" }}
              />
             
             {/* <video
                  ref = {videoRef}
                 className="memory-scroll br-30 w-100"
                  src={isVideo }
                  style={{ pointerEvents: "none" }}
                  controls
                /> */}

            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Memories;
