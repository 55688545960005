import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import chatting from "../../api/chatting";
import { Images } from "../../images";


const ForwardMessage = ({  isOpen, onClose,forwardMessageId,downLineUsers }) => {
  const [search, setSearch] = useState("");
  const [forwardMembers, setForwardMembers] = useState([]);
  const dummyImage=Images.chat2
   
  const forwardMembersList = (userId) => {
    setForwardMembers((prevForwardMembers) => {
 
      if (prevForwardMembers.includes(userId)) {
      
        return prevForwardMembers.filter(id => id !== userId);
      } else {
        
        return [...prevForwardMembers, userId];
      }
    });
   
  };

  const filteredChats = downLineUsers.filter((chat) =>
    chat.userName.toLowerCase().includes(search.toLowerCase())
  );

  const handleSendForwardMessage = async () => {
    const response=chatting.forwardMessages(forwardMessageId,forwardMembers.join(','),"data")
      console.log(response.data, "==>forwardTheMessages");
      onClose(true)
  
  }


  return (
    <Modal show={isOpen} size="sm" centered>
      <div className="my-2 d-flex flex-between">
        <h6>Forward Message</h6>
        <IoClose className="pointer" onClick={onClose} />
      </div>

      <Modal.Body>
        {/* Search input */}
        <div className="mb-3">
          <FormControl
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* Recent Chats */}

        <div className="chat-list-container">
          <ListGroup>
            {filteredChats.map((chat, index) => (
              <ListGroup.Item key={index} className="d-flex align-items-center">
                <div className="ml-2 d-flex w-100 ">
                  <img src={chat.img || dummyImage} alt="chat" className="chat_img2 me-2" />
                  <div className="d-flex flex-between w-100">
                    
                    <h6 className="my-2">{chat.userName}</h6>
                    <Form.Check type="checkbox" className="my-2" onClick={() => forwardMembersList(chat?.id)}
            checked={forwardMembers?.includes(chat.id)} />
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
        <div className="w-100">
          <div className=" flex-end  ">
            
            <span className="Forward-btn flex-center pointer" onClick={handleSendForwardMessage}>Send</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForwardMessage;
