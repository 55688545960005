import React, { useEffect, useState } from "react";
import { CiLocationOn, CiSearch } from "react-icons/ci";
import { FaRegSquareCheck } from "react-icons/fa6";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdDateRange } from "react-icons/md";
import { Images } from "../../images";
import { IoMdEye } from "react-icons/io";
import { LuArrowDownToLine } from "react-icons/lu";
import { FaShareFromSquare } from "react-icons/fa6";
import CancelTrip from "./CancelTrip";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import CancelExpences from "./CancelExpences";
import ConfirmModal from "./ConfirmModal";
import { MdOutlineZoomOutMap } from "react-icons/md";
import {
  getAllTours,
  getApprovedTours,
  getItineraryTours,
  getOngoingTours,
  getRejectedTours,
} from "../../api-folder/apiMethods";
import { Scheduler } from "aws-sdk";
import moment from "moment";
import { Modal } from "react-bootstrap";

const Tours = () => {
  const [showCancel, setShowCancel] = useState(false);
  const [showExpenceModal, setShowExpenceModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [searchBar, setSearchBar] = useState();
  const [searchDate, setSearchDate] = useState("");

  const [approvedDataTickets, setApprovedDataTickets] = useState([null]);
  const [itinearyDataTickets, setItinearyDataTickets] = useState([null]);
  const [ongoingDataTickets, setOningDataTickets] = useState([null]);
  const [rejectedDataTickets, setRejetedDataTickets] = useState([null]);

  const allTickets = [
    ...itinearyDataTickets,
    ...approvedDataTickets,
    ...ongoingDataTickets,
    ...rejectedDataTickets,
  ];

  const [cancelId, setCancelId] = useState();
  const handleCancel = (userId) => {
    setShowCancel(!showCancel);
    setCancelId(userId);
  };

  const [itinerary, setItineray] = useState(true);
  const openConfirmTicket = () => {
    setItineray(true);
    setApproved(false);
    setReject(false);
    setOpenOngoing(false);
  };

  console.log(itinerary, "itinerary");

  const [openOngoing, setOpenOngoing] = useState(false);
  const gotoOnngoingPage = () => {
    setOpenOngoing(true);
    setApproved(false);
    setReject(false);
    setItineray(false);
  };

  const [approved, setApproved] = useState(false);
  const approvePage = () => {
    setApproved(true);
    setOpenOngoing(false);
    setReject(false);
    setItineray(false);
  };

  const [reject, setReject] = useState(false);
  const rejectPage = () => {
    setReject(true);
    setApproved(false);
    setOpenOngoing(false);
    setItineray(false);
  };

  const [presentPage, setPresentPage] = useState(1);
  const itemsPerPage = 1;
  const indexOfLastItem = presentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = approvedDataTickets.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const prevPage = () => {
    if (presentPage > 1) {
      setPresentPage(presentPage - 1);
    }
  };
  const nextPage = () => {
    if (indexOfLastItem < approvedDataTickets.length) {
      setPresentPage(presentPage + 1);
    }
  };
  const [pagesInApprove, setPagesInApprove] = useState(1);
  const approveperPages = 1;
  const EndApprPageIndex = pagesInApprove * approveperPages;
  const FirstApprPageIndex = EndApprPageIndex - approveperPages;
  const currentApprvPages = itinearyDataTickets.slice(
    FirstApprPageIndex,
    EndApprPageIndex
  );
  const PrevApprvPage = () => {
    if (pagesInApprove > 1) {
      setPagesInApprove(pagesInApprove - 1);
    }
  };
  const NextApprvPage = () => {
    if (EndApprPageIndex < itinearyDataTickets.length);
    setPagesInApprove(pagesInApprove + 1);
  };
  const [pagesinPending, setPagesinPending] = useState(1);
  const pagesperPending = 2;
  const LastPenIndex = pagesinPending * pagesperPending;
  const FirstPenIndex = LastPenIndex - pagesperPending;
  const currentPendingPages = ongoingDataTickets.slice(
    FirstPenIndex,
    LastPenIndex
  );
  const PenPrevPage = () => {
    if (pagesinPending > 1) {
      setPagesinPending(pagesinPending - 1);
    }
  };
  const PenNextpage = () => {
    if (LastPenIndex < ongoingDataTickets.length) {
      setPagesinPending(pagesinPending + 1);
    }
  };

  const [rejectPages, setRejectPages] = useState(1);
  const pagesPerReject = 2;
  const RejectLastIndex = rejectPages * pagesPerReject;
  const RejectFirstIndex = RejectLastIndex - pagesPerReject;
  const currentRejectPages = rejectedDataTickets.slice(
    RejectFirstIndex,
    RejectLastIndex
  );
  const PrevRejectPage = () => {
    if (rejectPages > 1) {
      setRejectPages(rejectPages - 1);
    }
  };
  const NextRejectPage = () => {
    if (rejectPages < rejectedDataTickets.length) {
      setRejectPages(rejectPages + 1);
    }
  };

  const itineryTickets = () => {
    getAllTours()
      .then((response) => {
        if (response.status === true) {
          console.log(response, "itineary tickets");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    itineryTickets();
  }, []);

  const itinearyData = () => {
    getItineraryTours(itinerary)
      .then((response) => {
        if (response.status === true) {
          setItinearyDataTickets(response?.data);
          console.log("itinerryyyyy", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    itinearyData();
  }, [itinerary]);

  console.log(approvedDataTickets, "approvedDataTickets");

  const approvedData = () => {
    getApprovedTours(approved)
      .then((response) => {
        if (response.status === true) {
          setApprovedDataTickets(response?.data);
          console.log("approvedddd", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    approvedData();
  }, [approved]);

  console.log("ongoingDataTickets", ongoingDataTickets);
  const ongoingData = () => {
    getOngoingTours(openOngoing)
      .then((response) => {
        if (response.status === true) {
          setOningDataTickets(response?.data);
          console.log("ongoingggg", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    ongoingData();
  }, [openOngoing]);

  console.log("rejectedDataTickets", rejectedDataTickets);
  const rejectedData = () => {
    getRejectedTours(reject)
      .then((response) => {
        if (response.status === true) {
          setRejetedDataTickets(response?.data);
          console.log("rejecteddddd", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    rejectedData();
  }, [reject]);

  const filterTours = allTickets?.filter((item) => {
    const matchesTitle = item?.tour_title
      ?.toLowerCase()
      .includes(searchBar?.toLowerCase());
    const matchesDate = searchDate ? item?.schedule_from === searchDate : true;
    return matchesTitle && matchesDate;
  });
  console.log(filterTours, "======>Filtered Tours");
  console.log(itinearyDataTickets, "itinearyDataTickets");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imagePath) => {
    setSelectedImage(imagePath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);

  const handleToggleShareMenu = (index) => {
    setActiveMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderTickets = (details) =>
    details.map((item, index) => {
      const fileName = item.path.split("/").pop();
      const truncatedFileName =
        fileName.length > 15 ? `${fileName.substring(0, 10)}...` : fileName;
      const handleDownload = (filePath) => {
        const link = document.createElement("a");
        link.href = filePath;
        link.download = fileName; // Use the original file name for download
        link.click();
      };

      // Redirection links for sharing
      const shareLinks = {
        instagram: `https://www.instagram.com/share?url=${encodeURIComponent(
          item.path
        )}`,
        mail: `mailto:?subject=Check this file&body=${encodeURIComponent(
          item.path
        )}`,
        whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `Check out this file: ${item.path}`
        )}`,
        telegram: `https://t.me/share/url?url=${encodeURIComponent(
          item.path
        )}&text=Check out this file`,
      };
      return (
        <>
          <div className="w-100" key={index}>
            <div className="d-flex align-items-center justify-content-between w-100 mb-2">
              <div className="d-flex align-items-center ms-2 w-100">
                <div className="ms-2 d-flex align-items-center w-100 bg-hash p-2 br-10">
                  <div className="d-flex flex-column small-font w-75">
                    <div className="mx-2 text-black fw-500 medium-font">
                      {truncatedFileName}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-50">
                    <MdOutlineZoomOutMap
                      className="large-font clr-green pointer"
                      onClick={() => openModal(item.path)}
                    />
                    <LuArrowDownToLine
                      className="large-font pointer"
                      onClick={() => handleDownload(item.path)}
                    />
                    <FaShareFromSquare
                      className="large-font pointer"
                      onClick={() => handleToggleShareMenu(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activeMenuIndex === index && (
            <div
              className="share-menu"
              style={{
                position: "absolute",
                background: "#fff",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                zIndex: 100,
              }}
            >
              <div>
                <a
                  href={shareLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block"
                >
                  Share on Instagram
                </a>
              </div>
              <div>
                <a
                  href={shareLinks.mail}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block"
                >
                  Share via Email
                </a>
              </div>
              <div>
                <a
                  href={shareLinks.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block"
                >
                  Share on WhatsApp
                </a>
              </div>
              <div>
                <a
                  href={shareLinks.telegram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-block"
                >
                  Share on Telegram
                </a>
              </div>
            </div>
          )}
        </>
      );
    });

  return (
    <div className="middle-bg p-3 br-20">
      <div className="w-100 flex-between">
        <div className="col-2 d-flex align-items-center">
          <h6 className="fw-bold white-space black-text">Travel Details</h6>
        </div>
        <div className="col-4 d-flex align-items-center text-hash text-bg-white rounded-pill me-1">
          <CiSearch className="medium-font pointer mx-2" />
          <input
            type="text"
            placeholder="Search by trip name"
            className="medium-font fw-500"
            value={searchBar}
            onChange={(e) => setSearchBar(e.target.value)}
          />
        </div>
        <div className="col-4 d-flex align-items-center text-hash text-bg-white rounded-pill ms-1">
          {/* <MdDateRange className="medium-font pointer mx-2" /> */}
          {/* <label htmlFor="trip-date" className="medium-font fw-500">
            Search by trip date
          </label> */}
          <input
            type="date"
            placeholder=" Search by trip date "
            id="trip-date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
          />
        </div>
        <div className="col-2 flex-center">
          <div className="bg-pink px-3 py-2 text-white rounded-pill pointer fw-600">
            Search
          </div>
        </div>
      </div>
      <div className="flex-between align-items-center bg-white rounded-4 p-3 mt-2 medium-font boxShadow">
        <div className="flex-center">
          <label class="container" onClick={openConfirmTicket}>
            <input type="checkbox" checked={itinerary} />
            <span class="checkmark"></span>
          </label>
          <div>Itinerary</div>
        </div>
        <div className="hight-34"></div>
        <div className="flex-center">
          <label class="container" onClick={approvePage}>
            <input type="checkbox" checked={approved} />
            <span class="checkmark"></span>
          </label>
          <div>Approved</div>
        </div>
        <div className="hight-34"></div>
        <div className="flex-center">
          <label class="container" onClick={gotoOnngoingPage}>
            <input type="checkbox" checked={openOngoing} />
            <span class="checkmark"></span>
          </label>
          <div>Ongoing</div>
        </div>
        <div className="hight-34"></div>
        <div className="flex-center">
          <label class="container" onClick={rejectPage}>
            <input type="checkbox" checked={reject} />
            <span class="checkmark"></span>
          </label>
          <div>Rejected</div>
        </div>
      </div>
      {itinerary && (
        <div>
          {itinearyDataTickets.length > 0 ? (
            itinearyDataTickets.map((item, data) => (
              <div className="bg-white rounded-4 mt-2 medium-font boxShadow p-3">
                <div className="flex-between small-font">
                  <div className="d-flex">
                    <img
                      src={item?.image || Images.profile}
                      alt="profile"
                      className="hight-68"
                    />
                    <div className="ms-2">
                      <div className="fw-600 text-black medium-font">
                        {item?.user_name}
                      </div>
                      <div className="fw-500 text-black mt-1">
                        <FaRegSquareCheck /> Book By: {item?.booking_id}
                      </div>
                      <div className="text-hash fw-500 mt-1">
                        <CiLocationOn /> {item?.location}
                      </div>
                      <div className="d-flex max-content mt-1">
                        <span className="fw-500 text-black d-flex">
                          {/* Manager -{item.n1} */}
                          {item?.parents?.map((item, index) => (
                            <div className="d-flex">
                              <span>
                                <HiOutlineArrowNarrowRight className="ms-1" />
                              </span>
                              {item?.user_name}-{item?.role}
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="fw-600 text-black medium-font me-2">
                      Trip:
                      {item?.tour_title}
                    </span>
                    <span className="text-hash fw-400 small-font">
                      <MdDateRange className="me-1" />
                      {moment(item?.schedule_from).format("Do MMMM,YYYY")}
                    </span>
                  </div>
                </div>
                <div className="row border-grey1 br-10 my-2 p-2">
                  <div className="col-4">
                    <b className="m-2">Travel Tickets</b>
                    <div className="w-100">
                      {item?.travel_details &&
                        renderTickets(item.travel_details)}
                    </div>
                  </div>

                  <div className="col-4">
                    <b className="m-2">Hotel Tickets</b>
                    <div className="w-100">
                      {item?.hotel_details && renderTickets(item.hotel_details)}
                    </div>
                  </div>

                  <div className="col-4">
                    <b className="m-2">Guidance Tickets</b>
                    <div className="w-100">
                      {item?.guidance_details &&
                        renderTickets(item.guidance_details)}
                    </div>
                  </div>

                  {/* Modal for Viewing Full Image */}
                  {modalIsOpen && (
                    <Modal
                      show={modalIsOpen}
                      size="md"
                      centered
                      onHide={closeModal}
                    >
                      <img
                        src={selectedImage}
                        alt="Full View"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "10px",
                        }}
                      />
                    </Modal>
                  )}
                </div>

                <div className="flex-between  small-font mt-2">
                  <div className="fw-500 text-hash">
                    {" "}
                    {moment(item?.schedule_upto).format("dddd hh:mm A")}
                  </div>
                  <span className="fw-500 text-hash">
                    {" "}
                    {moment(item?.schedule_upto).format("Do MMMM,YYYY")}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="clr-red">No Tickets Available</div>
          )}
          <div className="flex-end mt-2">
            <div className="flex-center">
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={prevPage}
                disabled={presentPage === 1}
              >
                <GrFormPrevious />
              </button>
              <span className="page-btn large-font me-2 rounded fw-600 pink-bg clr-white">
                {presentPage}
              </span>
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={nextPage}
                disabled={indexOfLastItem >= approvedDataTickets.length}
              >
                <GrFormNext />
              </button>
            </div>
          </div>
        </div>
      )}
      {approved && (
        <div>
          {approvedDataTickets.length > 0 ? (
            approvedDataTickets.map((item, index) => (
              <div className="bg-white rounded-4 mt-2 medium-font boxShadow p-3">
                <div className="flex-between  small-font">
                  <div className="d-flex">
                    <img
                      src={item?.image || Images.profile}
                      alt=""
                      className="hight-68"
                    />
                    <div className="ms-2">
                      <div className="fw-600 text-black medium-font">
                        {item?.user_name}
                      </div>
                      <div className="fw-500 text-black mt-1">
                        <FaRegSquareCheck /> Book By: {item?.bookedBy_id}
                      </div>
                      <div className="text-hash fw-500 mt-1">
                        <CiLocationOn /> {item?.location}
                      </div>
                      <div className="d-flex max-content mt-1">
                        <span className="fw-500 text-black d-flex">
                          {item?.parents?.map((item, index) => (
                            <div className="d-flex">
                              <span className="mx-1">
                                <HiOutlineArrowNarrowRight className="ms-1" />
                              </span>
                              {item?.user_name} - {item?.role}
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="fw-600 text-black medium-font me-2">
                      Trip: {item?.tour_title}
                    </span>
                    <span className="text-hash fw-400 py-1 small-font">
                      <MdDateRange className="me-1" />
                      {moment(item?.schedule_from).format("Do MMMM,YYYY")}
                    </span>
                  </div>
                </div>

                {/* fifth sections */}
                <div className="flex-between  small-font mt-2">
                  <div className="fw-500 text-hash ">
                    {moment(item?.schedule_upto).format("dddd hh:mm A")}
                  </div>

                  <span className="fw-500 text-hash ">
                    {" "}
                    {moment(item?.schedule_upto).format("Do MMMM,YYYY")}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p className="clr-red">No Tickets Available</p>
          )}
          <div className="flex-end mt-2">
            <div className="flex-center">
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={PrevApprvPage}
                disabled={pagesInApprove === 1}
              >
                <GrFormPrevious />
              </button>
              <span className="page-btn large-font me-2 rounded fw-600 pink-bg clr-white">
                {pagesInApprove}
              </span>
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={NextApprvPage}
                disabled={EndApprPageIndex >= itinearyDataTickets.length}
              >
                <GrFormNext />
              </button>
            </div>
          </div>
        </div>
      )}
      {openOngoing && (
        <div>
          {ongoingDataTickets.length > 0 ? (
            ongoingDataTickets.map((item, data) => (
              <div className="bg-white rounded-4 mt-2 medium-font boxShadow p-3">
                <div className="flex-between  small-font">
                  <div className="d-flex">
                    <img
                      src={item?.image || Images.profile}
                      alt="profile"
                      className="hight-68"
                    />
                    <div className="ms-2">
                      <div className="fw-600 text-black medium-font">
                        {item?.user_name}
                      </div>
                      <div className="fw-500 text-black mt-1">
                        <FaRegSquareCheck className="" /> Book By:{" "}
                        {item?.bookedBy_id}
                      </div>
                      <div className="text-hash fw-500 mt-1">
                        <CiLocationOn /> {item?.location}
                      </div>
                      <div className="d-flex max-content mt-1">
                        <span className="fw-500 text-black d-flex">
                          {item?.parents?.map((item, index) => (
                            <div className="d-flex">
                              <span className="mx-1">
                                <HiOutlineArrowNarrowRight className="ms-1" />
                              </span>
                              {item?.user_name} - {item?.role}
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span className="fw-600 text-black medium-font me-2">
                      {" "}
                      Trip:
                      {item?.tour_title}
                    </span>
                    <span className="text-hash fw-400 py-1 small-font">
                      <MdDateRange className="me-1" />
                      {moment(item?.schedule_from).format("Do MMMM,YYYY")}
                    </span>
                    <div className="d-flex justify-content-center pt-3">
                      <span
                        className="px-3 py-2 rounded-pill fw-500 text-hash medium-font pointer border"
                        onClick={() => handleCancel(item?.bookedFor_id)}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-between  small-font mt-2">
                  <div className="fw-500 text-hash ">
                    {moment(item?.schedule_upto).format("dddd hh:mm A")}
                  </div>

                  <span className="fw-500 text-hash ">
                    {" "}
                    {moment(item?.schedule_upto).format("Do MMMM,YYYY")}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p className="clr-red">No Tickets Available</p>
          )}

          <div className="flex-end mt-2">
            <div className="flex-center">
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={PenPrevPage}
                disabled={pagesinPending === 1}
              >
                <GrFormPrevious />
              </button>
              <span className="page-btn large-font me-2 rounded fw-600 pink-bg clr-white">
                {pagesinPending}
              </span>
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={PenNextpage}
                disabled={LastPenIndex >= ongoingDataTickets.length}
              >
                <GrFormNext />
              </button>
            </div>
          </div>
        </div>
      )}
      {reject && (
        <div>
          {rejectedDataTickets.length > 0 ? (
            rejectedDataTickets.map((item, data) => (
              <div className="bg-white rounded-4 mt-2 medium-font boxShadow p-3">
                <div className="flex-between small-font">
                  <div className="d-flex">
                    <img
                      src={item?.image || Images.profile}
                      alt=""
                      className="hight-68"
                    />
                    <div className="ms-2">
                      <div className="fw-600 text-black medium-font">
                        {item?.user_name}
                      </div>
                      <div className="fw-500 text-black mt-1">
                        <FaRegSquareCheck className="" /> Book By:{" "}
                        {item?.bookedBy_id}
                      </div>
                      <div className="text-hash fw-500 mt-1">
                        <CiLocationOn /> {item?.location}
                      </div>
                      <div className="d-flex max-content mt-1">
                        <span className="fw-500 text-black d-flex">
                          {/* Manager -{item.n1} */}
                          {item?.parents?.map((item, index) => (
                            <div>
                              <span className="mx-1">
                                <HiOutlineArrowNarrowRight className="ms-1" />
                              </span>
                              {item?.user_name} -{item?.role}
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span className="fw-600 text-black medium-font me-2">
                      Trip: {item?.tour_title}
                    </span>
                    <span className="text-hash fw-400 small-font">
                      <MdDateRange className="me-1" />
                      {moment(item?.schedule_from).format("Do MMMM,YYYY")}
                    </span>
                    <div className="flex-column align-items-center mt-3">
                      <span className="w-fit reject-btn fw-500 px-3 py-2 rounded-pill medium-font pointer">
                        {item?.rejectbtn}
                      </span>
                      <span className="small-font text-hash fw-500 pt-3">
                        {item.tour_status}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-between  small-font mt-2">
                  <div className="fw-500 text-hash">
                    {moment(item?.schedule_upto).format("dddd, hh:mm A")}
                  </div>
                  <span className="fw-500 text-hash">
                    {moment(item?.schedule_upto).format("Do MMMM,YYYY")}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p className="clr-red">No Tickets Available</p>
          )}

          <div className="flex-end mt-2">
            <div className="flex-center">
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={PrevRejectPage}
                disabled={rejectPages === 1}
              >
                <GrFormPrevious />
              </button>
              <span className="page-btn large-font me-2 rounded fw-600 pink-bg clr-white">
                {rejectPages}
              </span>
              <button
                className="page-btn rounded input-bg large-font me-2 cursor-pointer fw-600"
                onClick={NextRejectPage}
                disabled={RejectLastIndex >= rejectedDataTickets.length}
              >
                <GrFormNext />
              </button>
            </div>
          </div>
        </div>
      )}
      <CancelTrip
        showCancel={showCancel}
        setShowCancel={setShowCancel}
        setShowExpenceModal={setShowExpenceModal}
        cancelId={cancelId}
      />
      <CancelExpences
        showExpenceModal={showExpenceModal}
        setShowExpenceModal={setShowExpenceModal}
        setConfirmModal={setConfirmModal}
      />
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        setShowCancel={setShowCancel}
        setShowExpenceModal={setShowExpenceModal}
      />
    </div>
  );
};

export default Tours;
