import React, { useState } from "react";
import { Images } from "../../images";
import "./styles.css";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router";

function OtpComponent() {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="row">
        <div className="col-6 h-100vh pos-relative">
          <div className="h-100vh d-flex flex-column w-100 justify-content-between">
            <div className="d-flex d-flex justify-content-center align-items-center flex-column form-location">
              <center className="text-start w-50">
                <img src={Images.Logos} alt="logo" className="mt-3" />
                <div className="d-flex flex-column w-100">
                  <div className="fw-600 mt-4" style={{ fontSize: "24px" }}>
                    OTP
                  </div>
                  <div className="small-font fw-600 grey-text my-2 max-50w">
                    We have sent a 6 digit OTP please check your registered
                    mobile number or email
                  </div>
                </div>
                <div className="w-100 my-4">
                  <div className="small-font password-clr mb-2 fw-600">
                    Enter OTP
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                    <div className="col">
                      <input
                        placeholder="0"
                        className="rounded input-bg py-2 w-100 text-center border-black"
                      ></input>
                    </div>
                  </div>
                  <div className="w-100 text-center grey-text small-font mt-3">
                    <b>Don't received any OTP </b>
                    <span className="password-clr fw-600">
                      <u>Resend OTP</u>
                    </span>
                  </div>
                  <div className="w-100 pink-bg clr-white fw-600 br-20 py-2 text-center cursor-pointer my-3">
                    Login
                  </div>
                </div>
              </center>
            </div>
            <div className="w-100">
              <img src={Images.Loginbg} className="w-100 photo-location" />
            </div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end h-100vh">
          <img src={Images.Rectangle} className="w-100" alt="side-img" />
        </div>
      </div>
    </div>
  );
}

export default OtpComponent;
