import axios from "axios";
import environment from "../environments/environment";

export default {
  //// Get user profile
  async getUserProfile(token) {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/get-user-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },

  //// Update user profile
  async updateUserProfile(data, token) {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/user-edit-profile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
};
