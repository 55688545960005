import axios from "axios";
import environment from "../environments/environment";


const token = localStorage.getItem("jwt_token");
const myid = localStorage.getItem("myid");

export default {
  async createRole(data) {
    try {
      const response = await axios.post(
        `${environment.baseUrl}/create-user`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },

  getCreatedUser: async (token) => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/get-created-users/${myid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },

  blockUser: async (id) => {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/block-user/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in blocking:", error);
      throw error;
    }
  },

  updateUser: async (id, data, token) => {
    console.log("token", token)
    try {
      const response = await axios.put(
        `${environment.baseUrl}/update-user/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error in blocking:", error);
      throw error;
    }
  },

  getEdituserDetails: async () => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/get-user-details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error", error);
    }
  },

  getCurrnetUserDetails: async () => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/user-data/${myid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },
};
