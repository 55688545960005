import React, { useState } from "react";
import { FaRegBell } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa";

const CloseSidebar = ({ toggleMiddlePageWidth }) => {
  const [bgActive, setBgActive] = useState(false);
  const toggleNextBg = () => {
    setBgActive(!bgActive);
    toggleMiddlePageWidth();
  };

  return (
    <div>
      <div className="ms-1 position-fixed top-0 bottom-0 tour-bg rounded-4 px-2 my-2 py-2">
        <div className="flex-column align-items-center">
          <div className="side-bg radius-round p-2 flex-center pointer">
            <FaRegBell className="text-black fw-600 " />
          </div>
          <div
            className="mt-3 pointer side-bg radius-round p-2 flex-center "
            onClick={toggleNextBg}
          >
            <FaChevronLeft className="text-black fw-600 pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseSidebar;
