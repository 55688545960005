import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import chatting from "../../api/chatting";
import { useDispatch } from "react-redux";
import { setEditAnimation } from "../../redux/action";

const EditMessage = ({
  openEditpopup,
  setOpenEditPopup,
  editContent,
  getMessages,
}) => {
  const [message, setMessage] = useState(editContent.content || "");

  const onClose = () => {
    setOpenEditPopup(false);
    setMessage("");
  };
const dispatch=useDispatch() 

  const handleEditMessage = async () => {
    try {
      const response = await chatting.editMessage(
        editContent.userId,
        editContent.MessageId,
        message
      );
      setOpenEditPopup(false);
      dispatch(setEditAnimation(true))
      setTimeout(() => {
        getMessages();
      }, 1000);
      setMessage("");
      console.log("Message edited successfully", response);
    } catch (error) {
      console.error("Failed to edit message", error);
    }
  };

  // Trigger handleEditMessage when the Enter key is pressed
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEditMessage();
    }
  };

  if (!openEditpopup) return null;

  return (
    <div className="edit-popup-overlay">
      <div className="edit-popup-container">
        <div className="edit-popup-header">
          <span className="fw-600 large-font">Edit Message</span>
          <IoClose className="font-16" onClick={onClose} />
        </div>

        <div className="edit-popup-body">
          {/* Message Bubble */}
          <div className="message-bubble d-flex align-items-center">
            <span>{editContent.content}</span>
          </div>

          {/* Input to edit the message */}
          <div className="message-input-container">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Listen for the Enter key
              className="message-input"
              placeholder="Type your message"
            />
            <div onClick={handleEditMessage}>
              <FaCheckCircle className="save-button mx-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMessage;
