import { useEffect, useState } from "react";
import { Images } from "../images";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineEye } from "react-icons/hi2";
import { BsPeople } from "react-icons/bs";
import { LuPlaneTakeoff } from "react-icons/lu";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import {
  getCompletedToursByUserId,
  getTravelReportDetailsById,
  getTravelReportDetailsSummaryById,
  getTravelReports,
} from "../api-folder/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "./CommonTable";

const TravelReports = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const user_name = localStorage?.getItem("user_name");
  const userRole = localStorage?.getItem("role");
  const role_id = localStorage?.getItem("myid");
  const [travelReportsData, setTravelReportsData] = useState();
  const [travelReportsTotal, setTravelReportsTotal] = useState();
  const [selectedUserId, setSelectedUserId] = useState(role_id);
  const [travelReportsSummary, setTravelReportsSummary] = useState();
  const [toursData, setTourData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [travelReports, setTravelReports] = useState([]);
  const tourCategoryData = useSelector((state) => state?.tourCategoryData);
  const [manager, setManager] = useState({
    name: "",
    role: "",
  });
  const [agent, setAgent] = useState({
    name: "",
    role: "",
  });
  const handleSelectedDirector = () => {
    setSelectedUserId(role_id);
    setManager({
      name: "",
      role: "",
    });
    setAgent({
      name: "",
      role: "",
    });
  };
  const handleSelectedManager = () => {
    setSelectedUserId(manager?.user_id);
    setAgent({
      name: "",
      role: "",
    });
  };

  const handleSelectedUser = (userId, userName, role) => {
    setSelectedUserId(Number(userId));
    if (role === "manager") {
      setManager({
        name: userName,
        role: role,
      });
    } else if (role === "agent") {
      setAgent({
        name: userName,
        role: role,
      });
    }
  };

  useEffect(() => {
    getTravelReportDetailsSummaryById(selectedUserId)
      .then((response) => {
        if (response?.status === true) {
          setTravelReportsSummary(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));

    getTravelReportDetailsById(selectedUserId)
      .then((response) => {
        if (response?.status === true) {
          setTravelReportsData(response?.data?.downlinesWithDetails || []);
          setTravelReportsTotal(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  }, [selectedUserId]);

  const TRAVEL_REPORTS_COLUMNS = [
    { header: <div className="text-start">Name</div>, field: "name" },
    { header: "Total Customers", field: "total_cus" },
    { header: "Completed Trips", field: "completed_trips" },
    { header: "Total Booking", field: "tot_booking" },
    { header: "Comm", field: "comm" },
    { header: "Downline Comm", field: "downline_comm" },
    { header: "View", field: "view" },
  ];

  const TRAVEL_REPORTS_DATA = travelReportsData?.map((item) => {
    return {
      name: (
        <div className="text-start">
          {item?.user_details?.user_name}({item?.user_details?.role?.role_code})
          <br /> (C 0% & OT 0%)
        </div>
      ),
      total_cus: item?.user_details?.total_customers,
      completed_trips: item?.user_details?.total_completed_trips,
      tot_booking: item?.user_details?.total_bookings,
      comm: item?.user_details?.casino_comm || 0,
      downline_comm: item?.user_details?.downline_commission || 0,
      view:
        item?.user_details?.role?.role_code === "user" ? (
          "- -"
        ) : (
          <HiOutlineEye
            className="icon-font"
            onClick={() =>
              handleSelectedUser(
                item?.user_details?.role?.user_id,
                item?.user_details?.role?.user_name,
                item?.user_details?.role?.role_code
              )
            }
          />
        ),
    };
  });
  
  const downlineData = travelReportsTotal?.downlinesWithDetails
  const total_customers = downlineData?.reduce((total, item) => total + item?.user_details?.total_customers, 0);
  const total_completed_trips = downlineData?.reduce((total, item) => total + item?.user_details?.total_completed_trips, 0);
  const total_bookings = downlineData?.reduce((total, item) => total + item?.user_details?.total_bookings, 0);

  const total_casino_comm = downlineData?.reduce((total, item) => total + (item?.user_details?.casino_comm || 0), 0);
  const total_commission = downlineData?.reduce((total, item) => total + (item?.user_details?.commission || 0), 0);
  const total_downline_commission = downlineData?.reduce((total, item) => total + (item?.user_details?.downline_commission || 0), 0);
  const total_other_tours_comm = downlineData?.reduce((total, item) => total + (item?.user_details?.other_tours_comm || 0), 0);


  const TRAVEL_REPORTS_FOOTER = [
    { header: <div className="text-start">Total</div> },
    { header: total_customers || 0},            
    { header: total_completed_trips || 0},   
    { header: total_bookings || 0 },         
    { header: total_casino_comm || 0},
    { header: total_downline_commission || 0 },
    { header: "" },
  ];


  const TRAVEL_DETAILS = [
    {
      id: 1,
      icon: <LuPlaneTakeoff className="font-2rem2" />,
      number: travelReportsSummary?.totalTravelPlanned,
      name: "Total Travel Planned",
    },
    {
      id: 2,
      icon: <BsPeople className="font-2rem2" />,
      number1: travelReportsSummary?.downlinesCount?.marcketingTeamCount,
      number2: travelReportsSummary?.downlinesCount?.usersCount,
      name: "Total Team Members",
    },
    {
      id: 3,
      icon: <BsPeople className="font-2rem2" />,
      number: travelReportsSummary?.totalTravelCoustmers,
      name: "Total Travel Customer",
    },
    {
      id: 4,
      icon: <FaHandHoldingDollar className="font-2rem2" />,
      number: travelReportsSummary?.totalEarnings,
      name: "Total Earning",
    },
  ];

  const usersList = travelReportsData
  ?.filter((item) => item?.user_details?.user_userName)
  .map((user) => ({
    id: user?.user_details?.id,
    userName: user?.user_details?.user_userName,
  }));

  const [selectedUserIdForTours, setSelectedUserIdForTours] = useState();
  const handleUsertoTour = (event) => {
    const userId = event.target.value;
    setSelectedUserIdForTours(userId);
    console.log(userId, "id");
  };

  const getFilteredTours = () => {
    if (!selectedUserIdForTours) return;
    getCompletedToursByUserId(selectedUserIdForTours)
      .then((response) => {
        if (response.status === true) {
          const filteredTours = selectedCategoryId
            ? response.data.filter(
                (tour) => tour.category_id === Number(selectedCategoryId)
              )
            : response.data;

          setTourData(filteredTours);
          console.log(filteredTours, "Filtered Tours");
        }
      })
      .catch((error) => setTravelReports(error?.message));
  };
  useEffect(() => {
    getFilteredTours();
  }, [selectedUserIdForTours, selectedCategoryId]);

  
  const fetchTravelReports = () => {
    getTravelReports({})
      .then((response) => {
        console.log(response, " travel reports response");
        if (response?.status === true) {
          setTravelReports(response.data);
          dispatch(setTravelReportsData(response.data));
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    fetchTravelReports();
  }, [dispatch]);

 const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
  };
  
  return (
    <div className="middle-bg p-3 br-20">
      <div className="flex-space-between px-2 text-hash">
        <div className="d-flex align-items-center">
          <img src={Images.ad1} alt="" />
          <div className="flex-column ms-2">
            <div className="d-flex align-items-center flex-wrap">
              <h6
                className="fw-bold text-black cursor-pointer white-space"
                onClick={handleSelectedDirector}
              >
                {user_name}
                <span className="small-font fw-500 text-orange">
                  ({userRole})
                </span>
              </h6>
              {manager?.name !== "" && (
                <h6
                  className="fw-bold text-black cursor-pointer white-space"
                  onClick={handleSelectedManager}
                >
                  <FaAngleRight className="brown-text" /> {manager?.name}
                  <span className="small-font fw-500 text-orange">
                    ({manager?.role})
                  </span>
                </h6>
              )}
              {agent?.name !== "" && (
                <h6 className="fw-bold text-black cursor-pointer white-space">
                  <FaAngleRight className="brown-text" /> {agent?.name}
                  <span className="small-font fw-500 text-orange">
                    ({agent?.role})
                  </span>
                </h6>
              )}
            </div>
            <div className="text-hash fw-500 small-font mt-1">
              <CiLocationOn /> {userDetails?.location},{userDetails?.city},
              {userDetails?.state},{userDetails?.country}
            </div>
          </div>
        </div>
        <h6 className="fw-600">
          Wallet :<span className="clr-green"> 1000000 </span>
        </h6>
      </div>
      <div className="row">
        {TRAVEL_DETAILS?.map((item) => (
          <div key={item.id} className="col-6 px-2 mt-2">
            <div className="d-flex align-items-center bg-white br-20 py-3 ps-3">
              <div className="me-3 bg-pink flex-center p-2 rounded-pill clr-white">
                {item.icon}
              </div>
              <div>
                <div className="large-font fw-bold text-black">
                  {item?.id === 2 ? (
                    <div className="d-flex flex-between">
                      <div>
                        <span className="small-font clr-light p-1 me-5">
                          Admin
                        </span>
                        <div className="">{item.number1}</div>
                      </div>
                      <div>
                        <span className="small-font clr-lightblue p-1">
                          Customers
                        </span>
                        <div>{item.number2}</div>
                      </div>
                    </div>
                  ) : (
                    <div>{item.number}</div>
                  )}
                </div>
                <div className="small-font fw-500 text-hash">{item.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row my-2">
        <div className="col-5 flex-center text-orange fw-500 medium-font max-content">
          {manager.name
            ? `${manager.name} (${manager.role})`
            : agent.name
              ? `${agent.name} (${agent.role})`
              : `${user_name} (${userRole})`}
        </div>
        <div className="col">
          <select className="text-hash fw-500 medium-font border_style_none p-2 rounded-pill pointer w-100"
          onChange={handleUsertoTour}
          >
            <option>Select Customer</option>
            {usersList?.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.userName}
                    </option>
                  ))}
            
          </select>
        </div>
        <div className="col">
          <select className="text-hash fw-500 medium-font border_style_none p-2 rounded-pill pointer w-100">
            <option>Select Tour</option>
             {toursData?.map((tour) => {
                      <option
                        className=""
                        key={tour?.tour_id}
                        value={tour?.tour_id}
                      >
                        {tour?.tour_title}
                      </option>;
                    })}
          </select>
        </div>
        <div className="col">
          <select className="text-hash fw-500 medium-font border_style_none p-2 rounded-pill pointer w-100"
          onChange={handleCategoryChange}>
            <option>Select Category</option>
            {tourCategoryData?.map((category) => (
                    <option
                      key={category?.category_id}
                      value={category?.category_id}
                    >
                      {category?.category_name}
                    </option>
                  ))}
          </select>
        </div>
      </div>
      <CommonTable 
        columns={TRAVEL_REPORTS_COLUMNS}
        data={TRAVEL_REPORTS_DATA || []}
        footer={TRAVEL_REPORTS_FOOTER}
        itemsPerPage={5}
      />
    </div>
  );
};

export default TravelReports;