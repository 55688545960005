import axios from "axios";
import environment from "../environments/environment";
const token = localStorage.getItem("jwt_token");

export default {
  async getAllCategories() {
    try {
      const response = await axios.get(`${environment.baseUrl}/category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  },
};