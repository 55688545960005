import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { api } from "../../api";
import Successpopup from "../popups/Successpopup";

function ResetPassword({ showResetPasswordPopup, setShowPasswordPopup }) {
  const [msg, setMsg] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [resetpassword, setresetpassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const handlePasswordClose = () => {
    setShowPasswordPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setresetpassword({ ...resetpassword, [name]: value });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const handleResetPassword = async () => {
    if (resetpassword.new_password !== resetpassword.confirm_password) {
      setMsg({
        text: "Password and Confirm Password do not match",
        type: "error",
      });
      return;
    }

    try {
      const token = localStorage.getItem("jwt");
      const response = await api.resetPassword(resetpassword, token);
      debugger;
      if (response.status !== true) {
        setMsg({ text: response.message, type: "error" });
        return;
      }

      if (response.status === true) {
        setMsg("");
        setSuccessMsg(response.message);
        setShowPasswordPopup(false);
        setShowSuccessPopup(true);

        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 4000);
      }
    } catch (error) {
      console.error("Failed to reset password", error);
    }
  };

  

  return (
    <>
      <Modal
        show={showResetPasswordPopup}
        onHide={handlePasswordClose}
        className="login-popup"
      >
        <div className="white-bg p-3">
          <div className="w-100 d-flex justify-content-end">
            <RxCross2
              className="large-font"
              onClick={() => handlePasswordClose()}
            />
          </div>
          <h4 className="password-clr text-center fw-700 text-center">
            Reset Password
          </h4>
          <div className="d-flex flex-column">
            <div className="black-text medium-font fw-600">
              Old Password <span className="star">*</span>
            </div>
            <div className="input-bg br-10 my-1 d-flex align-items-center">
              <input
                type={showPassword.old_password ? "text" : "password"}
                name="old_password"
                placeholder="Enter Password"
                className="w-100 p-2 br-10 bg-none input-bg"
                onChange={handleChange}
              />
              {showPassword.old_password ? (
                <AiFillEyeInvisible
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("old_password")}
                />
              ) : (
                <AiFillEye
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("old_password")}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="black-text medium-font fw-600">
              New Password <span className="star">*</span>
            </div>
            <div className="input-bg br-10 my-1 d-flex align-items-center">
              <input
                type={showPassword.new_password ? "text" : "password"}
                name="new_password"
                placeholder="Enter Password"
                className="w-100 p-2 br-10 bg-none input-bg"
                onChange={handleChange}
              />
              {showPassword.new_password ? (
                <AiFillEyeInvisible
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("new_password")}
                />
              ) : (
                <AiFillEye
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("new_password")}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="black-text medium-font fw-600">
              Confirm Password <span className="star">*</span>
            </div>
            <div className="input-bg br-10 my-1 d-flex align-items-center">
              <input
                type={showPassword.confirm_password ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm Password"
                className="w-100 p-2 br-10 bg-none input-bg"
                onChange={handleChange}
              />
              {showPassword.confirm_password ? (
                <AiFillEyeInvisible
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("confirm_password")}
                />
              ) : (
                <AiFillEye
                  className="font-30 clr-grey"
                  onClick={() => togglePasswordVisibility("confirm_password")}
                />
              )}
            </div>
          </div>
          <div
            className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
            onClick={handleResetPassword}
          >
            Reset Password
          </div>
          {msg && (
            <div
              className="mt-2"
              style={{ color: msg.type === "success" ? "inherit" : "red" }}
            >
              {msg.text}
            </div>
          )}
        </div>
      </Modal>
      <Successpopup show={showSuccessPopup} message={successMsg} />
    </>
  );
}

export default ResetPassword;
