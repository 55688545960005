import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineEdit, MdOutlineBlock } from "react-icons/md";
import PaymentModal from "./PaymentModal";
import CommonTable from "../../components/CommonTable";

const AddPayment = () => {
  const [paymentpopup, setPaymentpopup] = useState(false);
  const [blockGateway, setBlockGateway] = useState(true);
  const addPaymentPop = () => {
    setPaymentpopup(true);
  };
  const handleBlockGateway = () => {
    setBlockGateway(!blockGateway);
  };
  const PAYMENT_GATEWAY_COLUMNS = [
    { header: "Type", field: "type" },
    { header: "UPI Id", field: "upi_id" },
    { header: "Phone", field: "phone_no" },
    { header: "Edit", field: "edit" },
    { header: "Action", field: "active" },
  ];
  const PAYMENT_GATEWAY_DATA = [
    {
      type: "Phone Pe",
      upi_id: "agentphonepe@ybl",
      phone_no: "9000900090",
      edit: <MdOutlineEdit className="icon-font" />,
      active: (
        <MdOutlineBlock
          className={`icon-font ${blockGateway ? "clr-green" : "clr-red"}`}
          onClick={handleBlockGateway}
        />
      ),
    },
    {
      type: "Goodle Pay",
      upi_id: "agentphonepe@ybl",
      phone_no: "9000900090",
      edit: <MdOutlineEdit className="icon-font" />,
      active: (
        <MdOutlineBlock
          className={`icon-font ${blockGateway ? "clr-green" : "clr-red"}`}
          onClick={handleBlockGateway}
        />
      ),
    },
  ];

  return (
    <div className="bg-grey br-20 p-3">
      <div className="flex-space-between mb-2">
        <h5 className="text-black fw-bold">Add Banking</h5>
        <div
          className="text-white fw-500 add-btn medium-font py-2 px-3"
          onClick={addPaymentPop}
        >
          <IoMdAdd className="mx-1 medium-font" />
          <span className="medium-font">Add New</span>
        </div>
      </div>
      <CommonTable
        data={PAYMENT_GATEWAY_DATA}
        columns={PAYMENT_GATEWAY_COLUMNS}
        itemsPerPage={2}
      />
      <PaymentModal
        paymentpopup={paymentpopup}
        setPaymentpopup={setPaymentpopup}
      />
    </div>
  );
};

export default AddPayment;
