import React from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

function QRCodeModal({ showQrCode, qrCodeImage, handleCloseQrCode }) {
  return (
    <Modal className="qr-code-modal" show={showQrCode} centered>
      <div className="flex-between mb-2">
        <h6 className="fw-bold">QR Code</h6>
        <IoClose className="icon-font" onClick={handleCloseQrCode} />
      </div>
      <img className="qr-code-img2" src={qrCodeImage} alt="" />
    </Modal>
  );
}

export default QRCodeModal;
