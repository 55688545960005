import axios from "axios"
import environment from "../environments/environment";

export default{
    // for forgot password
    forgetPassword(data){
        return new Promise((res,rej)=>{
            axios.post(`${environment.baseUrl}/forgot-password`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        }, false)
    },

    // verify-otp
    verifyOtp(data){
        return new Promise((res,rej)=>{
            axios.post(`${environment.baseUrl}/verify-otp`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        },false)
    },

    // update password
    updatePassword(data){
        return new Promise((res,rej)=>{
            axios.put(`${environment.baseUrl}/update-password`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        })
    },

    // otp resend
    resendotp(data){
        return new Promise((res,rej)=>{
            axios.post(`${environment.baseUrl}/resend-otp`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        },false)

    }
}