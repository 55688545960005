import axios from "axios";
import environment from "../environments/environment";

const token = localStorage.getItem("jwt_token");

export default {
  async loginapi(data) {
    try {
      const response = await axios.post(
        `${environment.baseUrl}/login-user`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error in login:", error);
    }
  },
  async getCurrentUserDetails(data) {
    try {
      const response = await axios.post(
        `${environment.baseUrl}/login-user`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error in login:", error);
    }
  },

  async resetPassword(data) {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/reset-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error resetting password", error);
      throw error; // Re-throwing error if you want to handle it outside
    }
  },
};
