import apiRequest from "./apiRequest";

const loginUser = (data) => {
  return apiRequest("loginUser", data);
};
const signUpUser = (data) => {
  return apiRequest("signUpUser", data);
};
const getTourCategories = (data) => {
  return apiRequest("getTourCategories", data);
};
const resetUserPassword = (data) => {
  return apiRequest("resetUserPassword", data);
};
const updateUserProfile = (data) => {
  return apiRequest("updateUserProfile", data);
};
const getAllTrips = (queryParams) => {
  return apiRequest("getAllTrips", null, null, queryParams);
};
const getUpcomingTrips = () => {
  return getAllTrips({ upcoming: "upcoming" });
};
const getCompletedTrips = () => {
  return getAllTrips({ expired: "expired" });
};
const getUserDetails = (data) => {
  return apiRequest("getUserDetails", data);
};
const getBannerById = (id) => {
  return apiRequest("getBannerById", null, id);
};
const postForgotPassword = (data) => {
  return apiRequest("postForgotPassword", data);
};
const postVerifyOTP = (data) => {
  return apiRequest("postVerifyOTP", data);
};
const updatePassword = (data) => {
  return apiRequest("updatePassword", data);
};
const getPublishedTours = (data) => {
  return apiRequest("getPublishedTours", data);
};
const getPublishedTourById = (id) => {
  return apiRequest("getPublishedTourById", null, id);
};
const getBankDetailsByTourId = (tour_id) => {
  return apiRequest("getBankDetailsByTourId", null, tour_id);
};
const getTravelReportDetailsSummaryById = (id) => {
  return apiRequest("getTravelReportDetailsSummaryById", null, id);
};
const getTravelReportDetailsById = (id) => {
  return apiRequest("getTravelReportDetailsById", null, id);
};
const getBestDestination = (data) => {
  return apiRequest("getBestDestination", data);
};

const getAllMemories = (data) => {
  return apiRequest("getAllMemories", data);
};
const getMemoryDetailsById = (id, data) => {
  return apiRequest("getMemoryDetailsById", data, id);
};
const AddCommentsById = (id, data) => {
  return apiRequest("AddCommentsById", data, id);
};
const EditCommentsById = (id, data) => {
  return apiRequest("EditCommentsById", data, id);
};

const DeletecommentById = (id, data) => {
  return apiRequest("DeletecommentById", data, id);
};
const PostLike = (data) => {
  return apiRequest("PostLike", data);
};

const disLike = (id) => {
  return apiRequest("disLike", null, id);
};

const replyComment = (id, data) => {
  return apiRequest("replyComment", data, id);
};

const getChatMessages = (userId, data) => {
  return apiRequest("getChatMessages", data, userId);
};

const sendMessage = (userId, data) => {
  return apiRequest("sendMessage", data, userId);
};
const getCreationDetails = (userId) => {
  return apiRequest("getCreationDetails", userId);
};
const uploadImages = (data) => {
  return apiRequest("uploadImages", data);
};

const getDownlinesById = (id) => {
  return apiRequest("getDownlinesById", null, id);
};

const bookingUser = (data) => {
  return apiRequest("bookingUser", data);
};

const uploadImage = (formData) => {
  return apiRequest("uploadImage", formData);
};
// get tour details
const getAllTours = (data) => {
  return apiRequest("getAllTours", data);
};

const getItineraryTours = (id, data) => {
  return apiRequest("getItineraryTours", data, id);
};

const getApprovedTours = (id, data) => {
  return apiRequest("getApprovedTours", data, id);
};

const getOngoingTours = (id, data) => {
  return apiRequest("getOngoingTours", data, id);
};

const getRejectedTours = (id, data) => {
  return apiRequest("getRejectedTours", data, id);
};

// const getRejectedTours = () => {
//   return getAllTours({ rejected: "rejected" });
// };

const getWalletTable=(data) =>{
return apiRequest("getWalletTable",data)
}

const getWalletStatus=(data)=>{
  return apiRequest("getWalletStatus", data)
}

const updateImageById =(id,data) => {return apiRequest("updateImageById",data,id)}

const postWithdraw = (data)=>{
  return apiRequest("postWithdraw",data)
}

const createUserDeposit = (data) => apiRequest("createUserDeposit", data);
const getAllDepositBankings = (data) =>
  apiRequest("getAllDepositBankings", data);

const deleteUserDepositDetailsByID = (id, data) => {
  return apiRequest("deleteUserDepositDetailsByID", data, id);
};

const deleteUserWithdrawDetailsByID = (id, data) => {
  return apiRequest("deleteUserWithdrawDetailsByID", data, id);
};

const getUserDepositDetailsByID = (id) => {
  return apiRequest("getUserDepositDetailsByID", null, id);
};

const getUserWithdrawDetailsByID = (id) => {
  return apiRequest("getUserWithdrawDetailsByID", null, id);
};
const updateUserDepositDetailsByID = (id, data) => {
  return apiRequest("updateUserDepositDetailsByID", data, id);
};

const updateUserWithdrawDetailsByID = (id, data) => {
  return apiRequest("updateUserWithdrawDetailsByID", data, id);
};

const getComingTours = (data) =>{
return apiRequest("getComingTours",data)
};

const getCompletedToursByUserId = (id, data) => {
  return apiRequest("getCompletedToursByUserId", data, id);
};

const getTravelReports = (data) => {
  return apiRequest("getTravelReports", data);
};
// const updateImageById=(id,data)=>{return apiRequest("updateImageById",data,id)}
export {
  loginUser,
  signUpUser,
  getTourCategories,
  resetUserPassword,
  updateUserProfile,
  getAllTrips,
  getUpcomingTrips,
  getCompletedTrips,
  getUserDetails,
  getBannerById,
  postForgotPassword,
  postVerifyOTP,
  updatePassword,
  getPublishedTours,
  getPublishedTourById,
  getBankDetailsByTourId,
  getTravelReportDetailsSummaryById,
  getTravelReportDetailsById,
  getBestDestination,
  getAllMemories,
  getMemoryDetailsById,
  AddCommentsById,
  EditCommentsById,
  DeletecommentById,
  PostLike,
  disLike,
  replyComment,
  getChatMessages,
  sendMessage,
  getCreationDetails,
  uploadImages,
  getDownlinesById,
  bookingUser,
  uploadImage,
  getAllTours,
  getItineraryTours,
  getApprovedTours,
  getOngoingTours,
  getRejectedTours,
  getWalletTable,
  getWalletStatus,
  postWithdraw,
  createUserDeposit,
  getAllDepositBankings,
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
  getUserDepositDetailsByID,
  getUserWithdrawDetailsByID,
  updateUserDepositDetailsByID,
  updateUserWithdrawDetailsByID,
  getComingTours,
  updateImageById,
  getCompletedToursByUserId,
  getTravelReports
};
