import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

function ConfirmModal({
  confirmModal,
  setConfirmModal,
  setShowCancel,
  setShowExpenceModal,
}) {
  const handleCancel = () => {
    setConfirmModal(false);
    setShowCancel(false);
    setShowExpenceModal(false);
  };
  return (
    <Modal show={confirmModal} centered>
      <Modal.Body className="px-3">
        <div className="d-flex justify-content-end pointer">
          <RxCross2 className="icon-font" onClick={handleCancel} />
        </div>{" "}
        <div className="d-flex justify-content-center w-100 mt-3">
          <IoCheckmarkCircleSharp
            className="clr-green"
            style={{ fontSize: "100px" }}
          />
        </div>
        <h5 className="flex-center password-clr fw-bold mt-3">
          You Successfully Cancel The Trip
        </h5>
        <div
          className="w-100 flex-center btn-1 p-2 rounded-pill mt-3 medium-font fw-600"
          onClick={handleCancel}
        >
          Go Back To Homepage
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;
