import React, { useState } from "react";
import "./styles.css";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Images } from "../../images";
import { useNavigate } from "react-router";
import { api } from "../../api";
import { useDispatch } from "react-redux";
import { setDownLineData } from "../../redux/action";

function LoginComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [formData, setFormData] = useState({
    user_name: username,
    password: password,
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = () => {
    if (!formData.user_name || !formData.password) {
      setErrorMessage("Username and password are required");
      return;
    }
    api
      .loginapi(formData)
      .then((response) => {
        if (response.status === true) {
          console.log(response);
          const Data = response.data;

          localStorage.setItem(
            "parent_details",
            JSON.stringify(response.data.user)
          );

          // Dispatching plain object (downlineData)
          dispatch(setDownLineData(response));
          const roleCode = response.data.user.role_id.code;
          localStorage.setItem("user_name", response.data.user?.user_name);
          localStorage.setItem("jwt_token", response.data.token);
          localStorage.setItem("myid", response.data.user.id);
          localStorage.setItem("role", response.data.user.role_id.code);
          localStorage.setItem("parent_id", response.data.user.parent_id._id);
          if (
            roleCode === "director" ||
            roleCode === "manager" ||
            roleCode === "agent"
          ) {
            navigate("/");
          } else {
            console.log("You are not authorized to login in this website");
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error("Error in login:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
 

  return (
    <div className="row">
      <div className="col-6 h-100vh pos-relative">
        <div className="h-100vh d-flex flex-column w-100 justify-content-between">
          <div className="d-flex d-flex justify-content-center align-items-center flex-column login-form-location">
            <center className="text-start w-50 mt-5">
              <img src={Images.t2tlogin} alt="logo" className="my-2 t2t-logo" />
              <div className="d-flex flex-column w-100">
                <div className="fw-600 my-1 my-2" style={{ fontSize: "24px" }}>
                  Login
                </div>
                <div className="font-10 fw-600 grey-text1 my-2">
                  Secure Access to your Agent Account
                </div>
              </div>

              <form
  onSubmit={(e) => {
    e.preventDefault();  
    handleLogin();
  }}
>
              <div className="w-100 mt-4">
                <div className="text-start">
                  <label
                    className="my-2 password-clr fw-600"
                    htmlFor="username"
                  >
                    Username <span className="clr-red">*</span>
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    placeholder="Enter username"
                    className="rounded my-1 grey-bg grey-text bg-none px-2 py-2 w-100"
                  />
                </div>
                <div className="text-start">
                  <label
                    className="my-2 password-clr fw-600"
                    htmlFor="password"
                  >
                    Password <span className="clr-red">*</span>
                  </label>

                  <div className="d-flex w-100 align-items-center grey-bg rounded px-2 py-2">
                    <input
                      // className="all-none w-75"
                      className="grey-bg grey-text bg-none w-100"
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleLogin();
                        }
                      }}
                      placeholder="Enter password"
                    />
                    <div
                      className="cursor-pointer  pe-2"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? (
                        <BsEyeFill className="medium-font" />
                      ) : (
                        <BsEyeSlashFill className="medium-font" />
                      )}
                    </div>
                  </div>
                </div>
                <p
                  className="forgot-password font-10 my-2 fw-600 w-100 grey-text text-start cursor-pointer"
                  onClick={() => navigate("/otp")}
                >
                  Forgot Password?
                </p>
                {errorMessage && (
                  <div className="small-font fw-600 text-danger my-2">
                    {errorMessage}
                  </div>
                )}
                {/* <div
                  className="w-100 pink-bg clr-white fw-600 br-20 py-2 text-center cursor-pointer"
                  onClick={() => handleLogin()}
                >
                  Login
                </div> */}
                <button
                  type="submit"
                  className="w-100 pink-bg clr-white fw-600 br-20 py-2 text-center cursor-pointer"
                >
                  Login
                </button>
              </div>
              </form>
            </center>
          </div>
          <div className="w-100">
            <img src={Images.Loginbg} className="w-100 photo-location" alt="" />
          </div>
        </div>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <img src={Images.Rectangle} className="h-100vh w-100" alt="side-img" />
      </div>
    </div>
  );
}

export default LoginComponent;
