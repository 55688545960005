import axios from "axios";
import environment from "../environments/environment";

export default {
  async fetchBannerbyId(roleId) {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/banner/${roleId}`
      );
      return response;
    } catch (error) {
      console.error(error.response.message || "An error occured");
    }
  },
};
