import { RxCross2 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { postWithdraw, uploadImage } from "../../api-folder/apiMethods";
import { useSelector } from "react-redux";
// import { upload_img_03 } from "../../images/index";
import SubmitPopup from "../popups/Successpopup";

const Withdraw = ({ withdraw, setWithdraw, balanceAmt }) => {
  const userDetails = useSelector((state) => state.userDetails);
  console.log(userDetails, "userDetails");
  console.log(balanceAmt, "balanceAmt");
  const totalBalance = balanceAmt?.filter((item) => item?.name === "Balance");
  console.log(totalBalance, "totalBalance");

  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState();

  const handleQrCodeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await ([file], setUploadProgress);
      handleQrcodeSubmit(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handleQrcodeSubmit = (url) => {
    uploadImage({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const paymentTypeIds = response.data.map((item) => item.id);
          setPaymentTypeId(paymentTypeIds);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  const [amount, setAmount] = useState("");

  const [withdrawBalance, setWithdrawBalance] = useState(
    totalBalance?.[0]?.totalEarningsAgent || 0
  );

  console.log(withdrawBalance, "withdrawBalance")

  const [error, setError] = useState("");
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(false);
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState("Phone Pe");
  const [bankDetails, setBankDetails] = useState("");

  useEffect(() => {
    setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
  }, [totalBalance?.[0]?.totalEarningsAgent]);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setAmount("");
      setError("");
      setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
      setIsWithdrawDisabled(false);
      return;
    }

    const enteredAmount = parseFloat(value);

    if (enteredAmount < 1000) {
      setError("The amount must be at least 1000.");
      setIsWithdrawDisabled(false);
      setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
    } else if (enteredAmount > (totalBalance?.[0]?.totalEarningsAgent || 0)) {
      setError("Recharge your wallet.");
      setIsWithdrawDisabled(true);
    } else {
      setError("");
      setIsWithdrawDisabled(false);
      const updatedWithdrawBalance =
        (totalBalance?.[0]?.totalEarningsAgent || 0) - enteredAmount;
      setWithdrawBalance(updatedWithdrawBalance);
    }

    setAmount(value);
  };

  const handlePaymentGatewayChange = (e) => {
    setSelectedPaymentGateway(e.target.value);
  };

  const handleBankDetailsChange = (e) => {
    setBankDetails(e.target.value);
  };

  const userWithdraw = () => {
    if (!amount || parseFloat(amount) < 1000 || error) {
      console.log("Please fix the errors before submitting.");
      return;
    }

    let payload = {
      withdrawAmount: parseFloat(amount),
      balance_amount: totalBalance?.[0]?.totalEarningsAgent,
      user_id: userDetails.id,
      payment_mode: selectedPaymentGateway.toLowerCase(),
    };

    if (selectedPaymentGateway === "QR Code") {
      payload.upload_id = Number(paymentTypeId);
    } else {
      payload.bank_details = bankDetails;
    }

    postWithdraw(payload)
      .then((response) => {
        if (response.status === true) {
          setWithdraw(false);
          setTimeout(() => {
            setShowSuccessPopup(true);
          }, []);
        } else {
          console.log("Error Is Happening");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    userWithdraw();
  }, []);

  // const handleSubmit = () => {
  //   postWithdraw({ ...formData })
  //     .then((response) => {
  //       if (response.data) {
  //         console.log("Withdraw Successfull");
  //         setWithdraw(false);
  //       } else {
  //         console.log("error");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  return (
    <>
      <Modal size="md" show={withdraw} centered>
        <Modal.Body>
          <div className="px-2">
            <div className="flex-between">
              <h5 className="text-black fw-bold">Withdraw</h5>
              <RxCross2
                className="pointer font-2rem"
                onClick={() => setWithdraw(false)}
              />
            </div>
            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Role</label>
                <input
                  type="text"
                  //  placeholder="Agent"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={userDetails?.role?.code}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Name</label>
                <input
                  type="text"
                  // placeholder="Srinivas"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={userDetails?.name}
                  readOnly
                />
              </div>
            </div>
            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Wallet Amount</label>
                <input
                  type="text"
                  placeholder="Amount"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={totalBalance?.[0]?.totalEarningsAgent || 0}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Balance Amount</label>
                <input
                  type="text"
                  placeholder="Balance"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={withdrawBalance}
                  readOnly={isWithdrawDisabled}
                />
              </div>
            </div>
            <div className="row mt-2 medium-font fw-600">
              <div className="col-6">
                <label className="mb-1">Withdraw Amount</label>
                <input
                  type="number"
                  placeholder="Amount"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={amount}
                  onChange={handleAmountChange}
                  min={1000}
                />
                {error && <p className="small -font error-text">*{error}</p>}
              </div>

              <div className="col-6">
                <label className="mb-1">Payment Mode</label>
                <select
                  className="input-box1 br-10 p-2 w-fill fw-500 pointer"
                  value={selectedPaymentGateway}
                  onChange={handlePaymentGatewayChange}
                >
                  <option value="Phone Pe">Phone Pay</option>
                  <option value="Google Pay">Google Pay</option>
                  <option value="Paytm">Paytm</option>
                  <option value="NEFT">NEFT</option>
                  <option value="QR Code">QR Code</option>
                </select>
              </div>
            </div>
            {selectedPaymentGateway === "QR Code" && (
              <div className="flex-column w-100 mt-2 medium-font fw-600">
                <label className="mb-1">Upload QR Code</label>
                <input
                  type="file"
                  id="fileUpload"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  onChange={handleQrCodeUpload}
                />
                <label
                  htmlFor="fileUpload"
                  className="custom-file-upload input-bg px-2 py-1 small-font"
                >
                  <span className="small-font">Upload File</span>
                  <span className="upload-icon">📤</span>
                </label>
                {selectedFile && (
                  <div className="mt-2">
                    <p>Selected File: {selectedFile.name}</p>
                  </div>
                )}
                {error && (
                  <div className="text-danger mt-2">
                    <p>{error}</p>
                  </div>
                )}
              </div>
            )}
            {selectedPaymentGateway !== "QR Code" && (
              <div className="flex-column w-100 mt-2 medium-font fw-600">
                <label className="mb-1">UPI/Bank Details</label>
                <textarea
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  value={bankDetails}
                  onChange={handleBankDetailsChange}
                  placeholder="Enter your UPI or Bank details here"
                />
              </div>
            )}
            {qrCodeImage && selectedPaymentGateway === "QR Code" && (
              <div className="flex-center mt-2">
                <img src={qrCodeImage} alt="QR Code" className="qr-code-img" />
              </div>
            )}
            <div className="w-100 mt-3 medium-font fw-600">
              <div
                className="pink-bg clr-white p-2 rounded-pill text-center pointer"
                onClick={() => userWithdraw()}
              >
                Submit
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SubmitPopup
        successPopup={showSuccessPopup}
        setSuccessPopup={setShowSuccessPopup}
        title="Scuccess!!!"
        description="Submitted Deposit Request Successfully"
      />
    </>
  );
};
export default Withdraw;

// import { RxCross2 } from "react-icons/rx";
// import Modal from "react-bootstrap/Modal";
// import { useEffect, useState } from "react";
// import { postWithdraw } from "../../api-folder/apiMethods";

// const Withdraw = ({ withdraw, setWithdraw }) => {

//   const getLoginUserId = localStorage.getItem("myid");
//   const loginUserId = Number(getLoginUserId);

//   const [formData, setFormData] = useState({
//     withdrawAmount: "",
//     balance_amount: "",
//     user_id: loginUserId,
//     payment_mode: "",
//     bank_details: "",
//     upload_id: null,
//   });
//   const [qrCodeImage, setQrCodeImage] = useState(null);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleQrCodeUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setQrCodeImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSubmit = () => {
//     postWithdraw({ ...formData })
//       .then((response) => {
//         if (response.data) {
//           console.log("Withdraw Successfull");
//           setWithdraw(false);
//         } else {
//           console.log("error");
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };
//   useEffect(() => {
//     handleSubmit();
//   }, []);

//   return (
//     <Modal size="md" show={withdraw} centered>
//       <Modal.Body>
//         <div className="px-2">
//           <div className="flex-between">
//             <h5 className="text-black fw-bold">Withdraw</h5>
//             <RxCross2
//               className="pointer font-2rem"
//               onClick={() => setWithdraw(false)}
//             />
//           </div>
//           <div className="row mt-2 medium-font fw-600">
//             <div className="col-6">
//               <label className="mb-1">Role</label>
//               <input
//                 type="text"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 //value={}
//                 readOnly
//               />
//             </div>
//             <div className="col-6">
//               <label className="mb-1">Name</label>
//               <input
//                 type="text"
//                 placeholder="Srinivas"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 name="name"
//                 // value={name}
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//           <div className="row mt-2 medium-font fw-600">
//             <div className="col-6">
//               <label className="mb-1">Wallet Amount</label>
//               <input
//                 type="text"
//                 placeholder="Amount"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 name="balance_amount"
//                 value={formData.balance_amount}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="col-6">
//               <label className="mb-1">Balance Amount</label>
//               <input
//                 type="text"
//                 placeholder="Balance"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 name="netBalance"
//                 value={formData.netBalance}
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//           <div className="row mt-2 medium-font fw-600">
//             <div className="col-6">
//               <label className="mb-1">Withdraw Amount</label>
//               <input
//                 type="number"
//                 placeholder="Withdraw"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 name="withdrawAmount"
//                 value={formData.withdrawAmount}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="col-6">
//               <label className="mb-1">Payment Mode</label>
//               <select
//                 className="input-box1 br-10 p-2 w-fill fw-500 pointer"
//                 name="payment_mode"
//                 value={formData.payment_mode}
//                 onChange={handleInputChange}
//               >
//                 <option value="Phone Pe">Phone Pay</option>
//                 <option value="Google Pay">Google Pay</option>
//                 <option value="Paytm">Paytm</option>
//                 <option value="NEFT">NEFT</option>
//                 <option value="QR Code">QR Code</option>
//               </select>
//             </div>
//           </div>
//           {formData.payment_mode === "QR Code" && (
//             <div className="flex-column w-100 mt-2 medium-font fw-600">
//               <label className="mb-1">Upload QR Code</label>
//               <input
//                 type="file"
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 onChange={handleQrCodeUpload}
//               />
//             </div>
//           )}
//           {formData.payment_mode !== "QR Code" && (
//             <div className="flex-column w-100 mt-2 medium-font fw-600">
//               <label className="mb-1">UPI/Bank Details</label>
//               <textarea
//                 className="input-box1 br-10 p-2 w-fill fw-500"
//                 name="bank_details"
//                 value={formData.bank_details}
//                 onChange={handleInputChange}
//               />
//             </div>
//           )}
//           {qrCodeImage && formData.payment_mode === "QR Code" && (
//             <div className="flex-center mt-2">
//               <img src={qrCodeImage} alt="QR Code" className="qr-code-img" />
//             </div>
//           )}
//           <div className="w-100 mt-3 medium-font fw-600">
//             <div
//               className="pink-bg clr-white p-2 rounded-pill text-center pointer"
//               onClick={handleSubmit}
//             >
//               Submit
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default Withdraw;
